import React, { useState } from "react";

import {
  Designation,
  Education,
  Locationnew,
  Skills,
  Specilization,
} from "../../dashboard/components/Options";
import { useForm } from "react-hook-form";
import {
  addLocation,
  addDesigantion,
  addSpecilization,
  addSkills,
  addEducation,
} from "../components/Adddata";
import Loading from '../../Utils/Loading'
import Tablemaster from "../components/Tablemaster";
import Mastertable from "../components/Mastertable";

function Master() {
  const [show, setshow] = useState("spec");
  const [add, setadd] = useState("");
  const { register, handleSubmit } = useForm();

  const { options: data = [] } = Locationnew();
  const { options2: data2 = [] ,isLoading2} = Specilization();
  const { options3: data3 = [],isLoading3 } = Designation();
  const { options5: data4 = [] } = Skills();
  const { options4: data5 = [] } = Education();

if(isLoading2) return <Loading/>
  const onSubmit = async (formData) => {
    try {
      console.log(formData)
      const response = await addLocation(formData, data);
      alert('data added successfully',response.msg);
      window.location.reload();
    } catch (error) {
      alert('data failed to  add');
    }
  };
  const adddesignation = async (formData) => {
    try {
      const type = "Designation";
      const response = await addDesigantion(formData, type);
      alert('data added successfully',response.msg);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };
  const addspec = async (formData) => {
    try {
      const type = "Specilization";
      const response = await addSpecilization(formData, type);
      alert('data added successfully',response.msg);
      window.location.reload();
      setshow("spec")
    } catch (error) {
      console.error(error);
    }
  };
  const addskill = async (formData) => {
    try {
      const type = "Skills";
      const response = await addSkills(formData, type);
      alert('data added successfully',response.msg);
    } catch (error) {
      console.error(error);
       alert('data failed to  add');
    }
  };
  const addeducation = async (formData) => {
    try {
      const type = "Education";
      const response = await addEducation(formData, type);
      alert('data added successfully',response.msg);
    } catch (error) {
      console.error(error);
    }
  };
  const uniqueStates = [...new Set(data.map((state) => state.state).sort())];
  if(!data || !data2 || !data3 || !data4 || !data5) return <Loading/>
  return (
    <div className="px-4 ">
      <div className="flex gap-4 items-center mt-10">
        
        <button
          onClick={() => setshow("spec")}
          class="font-medium text-white bg-[#0f3057] mb-4 px-3 py-1 rounded-lg "
        >
          Specilization
        </button>
        <button
          onClick={() => setshow("skill")}
          class="font-medium text-white bg-[#0f3057] mb-4 px-3 py-1 rounded-lg "
        >
          Skill Master
        </button>
        <button
          onClick={() => setshow("edu")}
          class="font-medium text-white bg-[#0f3057] mb-4 px-3 py-1 rounded-lg "
        >
          Education
        </button>
        <button
          onClick={() => setshow("desi")}
          class="font-medium text-white bg-[#0f3057] mb-4 px-3 py-1 rounded-lg "
        >
          Designation
        </button>
        <button
          onClick={() => setshow("location")}
          class="font-medium text-white bg-[#0f3057] mb-4 px-3 py-1 rounded-lg "
        >
          Location
        </button>
      </div>

      {show === "location" && (
        <div>
          <h4 className="font-bold text-xl mb-4  text-center text-[#11a9b5]">
            {" "}
            Locations{" "}
          </h4>
          <div className="flex items-center justify-between">
            <button
              onClick={() => setadd("newloca")}
              class="font-medium text-white bg-[#11a9b5] mb-4 px-3 py-1 rounded-lg "
            >
              Add a new Location
            </button>
           
          </div>

          {add === "newloca" && (
            <div className="px-4 py-2 mb-4 ">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex   gap-12">
                  <div className="w-full">
                    <label
                      for="first_name"
                      class="block text-sm mb-2 font-medium text-gray-900 "
                    >
                      Select State
                    </label>

                    <select
                      {...register("state")}
                      className="w-full rounded-lg  py-2"
                    >
                      {uniqueStates.map((state) => (
                        <option value={state}>{state}</option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full">
                    <label
                      for="first_name"
                      class="block text-sm mb-2 font-medium text-gray-900 "
                    >
                      Add city name
                    </label>
                    <input
                      type="text"
                      {...register("city")}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      placeholder="....."
                      required
                    />
                  </div>
                </div>
                <button
                  type="sumbit"
                  class="font-medium text-white bg-[#11a9b5] mb-4 px-3 py-1 mt-4 rounded-lg "
                >
                  Add
                </button>
              </form>
            </div>
          )}
         
          <Tablemaster data={data} />
        </div>
      )}
      {show === "desi" && (
        <div>
          
          <h4 className="font-bold text-xl mb-4  text-center text-[#11a9b5]">
            {" "}
            Designation{" "}
          </h4>

          <div className="flex items-center justify-between">
            <button
              onClick={() => setadd("newdesi")}
              class="font-medium text-white bg-[#11a9b5] mb-4 px-3 py-1 rounded-lg "
            >
              Add a new Designation
            </button>

           
          </div>

          {add === "newdesi" && (
            <div className="px-4 py-2 mb-4">
              <form onSubmit={handleSubmit(adddesignation)}>
                <div>
                  <label
                    for="first_name"
                    class="block mb-2 text-sm font-medium text-gray-900 :text-white"
                  >
                    Add Designation
                  </label>
                  <input
                    type="text"
                    {...register("Designation")}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
                    placeholder="....."
                    required
                  />
                </div>
                <button
                  type="sumbit"
                  class="font-medium text-white bg-[#11a9b5] mb-4 px-3 py-1 mt-4 rounded-lg "
                >
                  Add
                </button>
              </form>
            </div>
          )}
          {isLoading3 ? (
        <div className="flex justify-center items-center h-64">
          <Loading /> {/* Replace this with your actual loading spinner */}
        </div>
      ) : (
        <Mastertable data={data2} />
      )}
        </div>
      )}
      {show === "spec" && (
        <div>
          <h4 className="font-bold text-xl mb-4  text-center text-[#11a9b5]">
            Speciliaztions{" "}
          </h4>

          <div className="flex w-fit ">
            <button
              onClick={() => setadd("newspec")}
              class="font-medium text-white bg-[#11a9b5] mb-4 px-3 py-1 rounded-lg "
            >
              Add a new Speciliaztion
            </button>
          </div>

          {add === "newspec" && (
            <div className="px-4 py-2 mb-4">
              <form onSubmit={handleSubmit(addspec)}>
                <div>
                  <label
                    for="first_name"
                    class="block mb-2 text-sm font-medium text-gray-900 :text-white"
                  >
                    Add Speciliaztion
                  </label>
                  <input
                    type="text"
                    {...register("Specilization")}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
                    placeholder="....."
                    required
                  />
                </div>
                <button
                  type="sumbit"
                  class="font-medium text-white bg-[#11a9b5] mb-4 px-3 py-1 mt-4 rounded-lg "
                >
                  Add
                </button>
              </form>
            </div>
          )}
           {isLoading2 && (<Loading/>)}
          <Mastertable data={data3} />
        </div>
      )}
      {show === "skill" && (
        <div>
          <h4 className="font-bold text-xl mb-4  text-center text-[#11a9b5]">
            Skills{" "}
          </h4>

          <div className="flex items-center justify-between">
            <button
              onClick={() => setadd("newskill")}
              class="font-medium text-white bg-[#11a9b5] mb-4 px-3 py-1 rounded-lg "
            >
              Add a new Skills
            </button>
          </div>

          {add === "newskill" && (
            <div className="px-4 py-2 mb-4">
              <form onSubmit={handleSubmit(addskill)}>
                <div>
                  <label
                    for="first_name"
                    class="block mb-2 text-sm font-medium text-gray-900 :text-white"
                  >
                    Add Skills
                  </label>
                  <input
                    type="text"
                    {...register("Skills")}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
                    placeholder="....."
                    required
                  />
                </div>
                <button
                  type="sumbit"
                  class="font-medium text-white bg-[#11a9b5] mb-4 px-3 py-1 mt-4 rounded-lg "
                >
                  Add
                </button>
              </form>
            </div>
          )}
          <Mastertable data={data4} />
        </div>
      )}
      {show === "edu" && (
        <div>
          <h4 className="font-bold text-xl mb-4  text-center text-[#11a9b5]">
            Education{" "}
          </h4>

          <div className="flex items-center justify-between">
            <button
              onClick={() => setadd("newedu")}
              class="font-medium text-white bg-[#11a9b5] mb-4 px-3 py-1 rounded-lg "
            >
              Add a new Education
            </button>
          </div>

          {add === "newedu" && (
            <div className="px-4 py-2 mb-4">
              <form onSubmit={handleSubmit(addeducation)}>
                <div>
                  <label
                    for="first_name"
                    class="block mb-2 text-sm font-medium text-gray-900 :text-white"
                  >
                    Add Education
                  </label>
                  <input
                    type="text"
                    {...register("Education")}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
                    placeholder="....."
                    required
                  />
                </div>
                <button
                  type="sumbit"
                  class="font-medium text-white bg-[#11a9b5] mb-4 px-3 py-1 mt-4 rounded-lg "
                >
                  Add
                </button>
              </form>
            </div>
          )}
          <Mastertable data={data5} />
        </div>
      )}
    </div>
  );
}

export default Master;
