import React, { useState, useEffect } from "react";
import Jobcard from "../components/Jobcard";
import Pageination from "../components/Pageination";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Tabs from "../components/Tabs";
import SkeletonCard from "../components/Skeleton";
import FilterCandidate from "../components/FilterCandidate";
import { fetchCandidates } from "../components/Function";

function Findcandiate() {
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const jobsPerPage = 10;
  const queryClient = useQueryClient();

  // Initialize filters from local storage or with default values
  const [filters, setFilters] = useState(() => {
    const savedFilters = JSON.parse(localStorage.getItem("filters")) || {};
    return {
      designation: savedFilters.designation || null,
      gender: savedFilters.gender || null,
      location: savedFilters.location || null,
      state_name: savedFilters.state_name || null,
      status: savedFilters.status || null,
    };
  });

  // Save filters to local storage every time they change
  useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(filters));
  }, [filters]);

  // Fetch candidates with current page and filter data
  // const { data: candidate = [], isLoading, isError, error  } = useQuery({
  //   queryKey: ['candidates', { currentPage, filters }],
  //   queryFn: () => fetchCandidates(currentPage, filters),
  //   staleTime: 5 * 60 * 1000, // Keep data fresh for 5 minutes
  //   keepPreviousData: true,
  // });
  const {
    data: candidate = [],
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["candidates", { currentPage, filters }],
    queryFn: () => fetchCandidates(currentPage, filters),
    staleTime: 5 * 60 * 1000, // Keep data fresh for 5 minutes
    keepPreviousData: true,
    onSuccess: () => {
      // Prefetch the next page after the current page data is fetched
      const nextPage = currentPage + 1;
      queryClient.prefetchQuery(
        ["candidates", { currentPage: nextPage, filters }],
        () => fetchCandidates(nextPage, filters),
        {
          staleTime: 5 * 60 * 1000, // Same staleTime for next page
        }
      );
    },
  });

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1); // Reset to the first page when filters are applied
  };

  if (isLoading) return <SkeletonCard />;
  if (!candidate.data)
    return (
      <div className="text-center text-2xl text-red-500">
        Unable to load data, please try again later.
      </div>
    );
  if (isError)
    return (
      <div className="text-center text-2xl text-red-500">
        Error: {error.message}
      </div>
    );
  return (
    <>
      <div class="mb-4  justify-center  sm:bg-slate-50 flex flex-wrap border-gray-600 ">
        <Tabs onChange={handleFilterChange} />

        <div className="grid sm:grid-cols-3 gap-12  mb-4">
          <div className="col-span-1 sm:static fixed mt-[40px] ml-[200px] sm:ml-0 sm:mt-0 sm:z-0 z-30">
            <FilterCandidate onChange={handleFilterChange} />
            <div className="my-4 hidden sm:block">
              <h3 className="text-lg text-[#11a9b5]  font-bold">
                Selected Filters:
              </h3>
              <div>
                {filters.designation && (
                  <div class="lg:text-lg text-md  text-[#0f3057] ">
                    Designation: {filters.designation.label}
                  </div>
                )}
                {filters.location && (
                  <div class="lg:text-lg text-md  text-[#0f3057] ">
                    Location: {filters.location.label}
                  </div>
                )}
                {filters.gender && (
                  <div class="lg:text-lg text-md  text-[#0f3057] ">
                    gender: {filters.gender.value === 1 ? "Female" : "Male"}
                  </div>
                )}
              </div>
            </div>
          </div>
          {candidate.data.length > 0 ? (
            <div className="space-y-4  sm:mt-0 mt-28 col-span-2">
              {candidate.data?.map((job) => (
                <Jobcard
                  id={job.id}
                  notice_period={job.notice_period}
                  email={job.email}
                  mobile={job.mobile}
                  total_exp_mn={job.total_exp_mn}
                  total_exp_yr={job.total_exp_yr}
                  state_name={job.state_name}
                  name={job.name}
                  gender={job.gender}
                  education={job.education}
                  city_name={job.city_name}
                  salarymax={job.salarymax}
                />
              ))}
              <Pageination
                data={candidate.data}
                firstpage={candidate.first_page_url}
                totalpage={candidate.total}
                lastpage={candidate.last_page_url}
                Links={candidate.links}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                jobsPerPage={jobsPerPage}
              />
            </div>
          ) : (
            <p class="lg:text-lg text-center text-red-500  font-semibold">
              No Candidates found, Apply different filter
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default Findcandiate;
