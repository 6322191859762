import React, { useRef, useState } from "react";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import styles for the video player

const CourseData = {
  title: "Complete Web Development Course",
  progress: 35,
  modules: [
    {
      id: 1,
      title: "Introduction to Web Development",
      completed: true,
      lessons: [
        {
          id: 1,
          title: "Welcome to the Course",
          duration: "5:30",
          videoUrl:
            "https://media.w3.org/2010/05/sintel/trailer_hd.mp4", // Sample video URL
          completed: true,
          description:"lo sdfhjh sdfjalk ljalkdjt hjdf hjsfd ssdf jsdf slfsdfjlkjjkjhjdf dfjskjfdsakl jheliul sphela hyat meran kala aljal kale de ya room auna cahta  awr jjm lmikli"
        },
        {
          id: 2,
          title: "Setting Up Your Development Environment",
          duration: "10:15",
          videoUrl:
            "https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4", // Another sample video
          completed: true,
        },
      ],
    },
    {
      id: 2,
      title: "HTML Fundamentals and overview",
      completed: false,
      lessons: [
        {
          id: 3,
          title: "HTML Structure and Tags 1",
          duration: "15:20",
          videoUrl:
            "https://storage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
          completed: false,
        },
      ],
    },
    {
      id: 3,
      title: "Css Fundamentals and basic design ui",
      completed: false,
      lessons: [
        {
          id: 4,
          title: "HTML Structure and Tags 2 ",
          duration: "15:20",
          videoUrl:
            "https://storage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
          completed: false,
        },
      ],
    },
    {
      id: 4,
      title: "Advanced Css Fundamentals and pratice questions",
      completed: false,
      lessons: [
        {
          id: 5,
          title: "HTML Structure and Tags 3",
          duration: "15:20",
          videoUrl:
            "https://storage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
          completed: false,
        },
      ],
    },
    {
      id: 5,
      title: "HTML css and javascript Fundamentals ",
      completed: false,
      lessons: [
        {
          id: 7,
          title: "HTML Structure and Tags 4",
          duration: "15:20",
          videoUrl:
            "https://storage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
          completed: false,
        },
        {
          id: 8,
          title: "HTML Structure and Tags 45",
          duration: "15:20",
          videoUrl:
            "https://storage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
          completed: false,
          description:"lo sdfhjh sdfjalk ljalkdjt hjdf hjsfd ssdf jsdf slfsdfjlkjjkjhjdf dfjskjfdsakl jheliul sphela hyat meran kala aljal kale de ya room auna cahta  awr jjm lmikli"

        },
      ],
    },
    {
      id: 6,
      title: "overview of our new project",
      completed: false,
      lessons: [
        {
          id: 9,
          title: "HTML Structure and Tags 5",
          duration: "15:20",
          videoUrl:
            "https://storage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
          completed: false,
        },
      ],
    },
  ],
};
const Coursewatch = () => {
  const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
  const [currenttitleindex, setcurrenttitleindex] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Sidebar state
  const [openModule, setOpenModule] = useState(null); // Track open module
  const contentRef = useRef({}); // References for module heights
  const mobcontentRef = useRef({}); // References for module heights

  const allLessons = CourseData.modules.flatMap((module) => module.lessons);
  const allmodules = CourseData.modules.map((module) => module.lessons);
  const alltitle = CourseData.modules.flatMap((module) => module.title);
  const currenttitle = alltitle[currenttitleindex];
  const currentLesson = allLessons[currentLessonIndex];

  const handleNextLesson = () => {
    if (currentLessonIndex < allLessons.length - 1) {
      setCurrentLessonIndex(currentLessonIndex + 1);
      if(allmodules[currentLessonIndex].length===1){

        setcurrenttitleindex(currenttitleindex + 1);
      }
    
    }
  };
  console.log(allmodules)
  console.log(currenttitleindex)

  const handlePrevLesson = () => {
    if (currentLessonIndex > 0) {
      setCurrentLessonIndex(currentLessonIndex - 1);
    }
  };

  const handleLessonChange = (lesson) => {
    const lessonIndex = allLessons.findIndex((l) => l.id === lesson.id);
    setCurrentLessonIndex(lessonIndex);
  };

  const toggleModule = (moduleId) => {
    setOpenModule((prev) => (prev === moduleId ? null : moduleId));
  };

  return (
    <div className="flex h-[900px]">
       {/* <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className=" bg-gray-300 hidden sm:block relative text-white px-1 py-1 rounded-md mb-4"
          >
          {isSidebarOpen ? <span className="text-black font-bold text-xl px-1"> X</span> :     <svg className="block h-6 w-6 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>}
        </button> */}
      {/* Sidebar */}
      <div
        className={`${
          isSidebarOpen ? "w-[340px] border-2" : "w-[0px] opacity-0"
        } bg-white sm:block hidden shadow-md transition-all duration-200 overflow-auto`}
      >
        <div className="p-4">
          <div className="flex">

          <h2 className="font-bold text-lg text-[#11a9b5] mb-4">
            {CourseData.title}
          </h2>
         
          </div>
          <h2 className="font-bold text-sm mb-2">
            Progress: {CourseData.progress}%
          </h2>
          <div className="w-full bg-gray-200 rounded-lg">
            <div
              className="top-0 left-0 h-1 mb-2 rounded-lg bg-blue-500 transition-all duration-200"
              style={{ width: `${CourseData.progress}%` }}
            ></div>
          </div>
          <h2 className="font-semibold text-md mb-4">Course Modules</h2>
          {CourseData.modules.map((module) => (
            <div key={module.id} className="mb-4">
              {/* Module Header */}
              <div
                onClick={() => toggleModule(module.id)}
                className="cursor-pointer flex justify-between items-center bg-gray-100 p-4 rounded-md shadow-md"
              >
                <h3 className="font-medium text-gray-700">{module.title}</h3>
                <span className="text-gray-500">
                  {openModule === module.id ? "-" : "+"}
                </span>
              </div>

              {/* Lessons (with smooth transition) */}
              <div
                ref={(el) => (contentRef.current[module.id] = el)} // Save reference
                className={`overflow-hidden transition-all duration-300 ease-in-out`}
                style={{
                  height:
                    openModule === module.id
                      ? `${contentRef.current[module.id]?.scrollHeight}px`
                      : "0px",
                }}
              >
                <ul className="space-y-2 mt-2 bg-white p-4 shadow-md rounded-md">
                  {module.lessons.map((lesson) => (
                    <li key={lesson.id}>
                      <button
                        onClick={() => handleLessonChange(lesson)}
                        className={`w-full text-sm text-left px-3 py-2 rounded ${
                          currentLesson?.id === lesson.id
                            ? "bg-blue-50 text-blue-600 font-bold"
                            : "hover:bg-gray-50 text-gray-700"
                        }`}
                      >
                        <div className="flex items-center justify-between">
                          {module.completed ? (
                            <div className="w-5 h-5 text-green-500 mr-2">✔</div>
                          ) : (
                            <div className="w-5 h-5 rounded-full border-2 border-gray-300 mr-2" />
                          )}
                          <p className="text-xs">{lesson.title}</p>
                          <p className="text-xs text-gray-500">
                            {lesson.duration}
                          </p>
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div
        className={`flex-col ${
          isSidebarOpen ? "lg:w-[calc(100%-340px)]" : "lg:w-full"
        } bg-white p-6 flex flex-col transition-all duration-200`}
      >
        <div className="flex justify-between">

        <h1 className="text-xl font-semibold text-gray-800 mb-4">
          {currenttitle}
        </h1>
       
        <h1 className="text-xl font-semibold text-gray-800 mb-4">
          Module {currenttitleindex +1} of {alltitle?.length}
        </h1>
        </div>
        <h1 className="text-xl font-semibold text-gray-800 mb-4">
          {currentLesson?.title || "No Lesson Selected"}
        </h1>
        <div className="flex">
          {currentLesson?.videoUrl ? (
            <Player
              playsInline
              autoPlay
              src={currentLesson.videoUrl}
              className="w-full h-[400px]"
              onEnded={handleNextLesson}
            />
          ) : (
            <p>No video available for this lesson.</p>
          )}
        </div>

        {/* Next Lesson Button */}
        <div className="flex justify-between items-center py-2 px-4 sm:px-20">
          <button
            onClick={handlePrevLesson}
            disabled={currentLessonIndex === 0}
            className={`sm:px-4 sm:py-2 bg-blue-600 text-white rounded ${
              currentLessonIndex === 0
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-blue-700"
            }`}
          >
            Prev Lesson
          </button>
          <button
            onClick={handleNextLesson}
            disabled={currentLessonIndex >= allLessons.length - 1}
            className={`sm:px-4 sm:py-2 bg-blue-600 text-white rounded ${
              currentLessonIndex >= allLessons.length - 1
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-blue-700"
            }`}
          >
            Next Lesson
          </button>
        </div>
        {/* <div className='flex justify-between items-center py-2 px-4 sm:px-20'>
        <button class="text-blue-900 border-2 border-blue-900 hover:bg-blue-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center " onClick={prevlesson}> Previous lesson</button>
        <button class="text-blue-900 border-2 border-blue-900 hover:bg-blue-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center " onClick={nextlesson} disabled={index >= chaptersarray.length}> Next lesson</button>
</div> */}
        <div className="p-6 sm:px-20 flex-1 ">
          {/* <h1 className="text-2xl font-bold text-gray-800 mb-2">
            {currentLesson.title}
          </h1> */}
          <p className="text-gray-600">{currentLesson.description}</p>
        </div>

        {/* Lesson Details */}
        <div
        className={`w-full bg-white block sm:hidden shadow-md transition-all duration-100 overflow-auto`}
      >
        <div className="p-4">
          <h2 className="font-bold text-lg mb-4">Course Modules</h2>
          {CourseData.modules.map((module) => (
        <div key={module.id} className="mb-4">
          <div
            onClick={() => toggleModule(module.id)}
            className="cursor-pointer flex justify-between items-center bg-gray-100 p-4 rounded-md shadow-md"
          >
            <h3 className="font-medium text-gray-700">{module.title}</h3>
            <span className="text-gray-500">
              {openModule === module.id ? "-" : "+"}
            </span>
          </div>

          <div
            ref={(el) => (mobcontentRef.current[module.id] = el)} // Save reference
            className={`overflow-hidden transition-all duration-300 ease-in-out`}
            style={{
              height: openModule === module.id ? `${mobcontentRef.current[module.id]?.scrollHeight}px` : "0px",
            }}
          >
            <ul className="space-y-2 mt-2 bg-white p-4 shadow-md rounded-md">
              {module.lessons.map((lesson) => (
                <li key={lesson.id}>
                  <button
                    onClick={() => handleLessonChange(lesson, module.title)}
                    className={`w-full text-sm text-left px-3 py-2 rounded ${
                      currentLesson.id === lesson.id
                        ? "bg-blue-50 text-blue-600 font-bold"
                        : "hover:bg-gray-50 text-gray-700"
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      {module.completed ? (
                        <div className="w-5 h-5 text-green-500 mr-2">✔</div>
                      ) : (
                        <div className="w-5 h-5 rounded-full border-2 border-gray-300 mr-2" />
                      )}
                      <p className="text-xs">{lesson.title}</p>
                      <p className="text-xs text-gray-500">{lesson.duration}</p>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
        </div>
      </div>
      </div>

      </div>
    
  );
};

export default Coursewatch;
