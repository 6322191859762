import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import VirtualizedMenuList from "../../Utils/VirtualizedMenuList";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paginationa from "../components/Paginationa";
import { useAuth } from "../../Utils/Context";
import Loading from "../../Utils/Loading";
import { useFetchData } from "../../Utils/Fetchdata";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { set } from "react-hook-form";
import DateRangePicker from "../components/Daterange";
import JobPopupModal from "../components/JobviewModal";
function Candidatea() {
  const [page, setPage] = useState(1);
  const { api } = useAuth();
  // const [searchParams, setSearchParams] = useSearchParams();
  const [selectedState, setSelectedState] = useState("");
  const [filter, setfilter] = useState({
    gender: "",
    fromdate: "",
    todate: "",
  });
  const [selectedgender, setSelectedgender] = useState("");
  const [fromdate, setFromdate] = useState("");
  const [todate, settodate] = useState("");
  const [tempState, setTempState] = useState(""); // Temporary filter state
  const [loading, setLoading] = useState(false);
  const { data: state = [], isLoading4 } = useFetchData(
    "https://info.myschoolnaukri.com/api/master/state",
    ["State"]
  );
  // const token = localStorage.getItem("token");
  // console.log(token)
  const fetchCandidates = async (page,selectedState,callType,filter) => {
    const token = localStorage.getItem("token");
    // callType = callType || 0;
    try {
      const response = await api.get(
        `/api/admin/candidate?state_name=${selectedState}&page=${page}&call_type=${callType}&gender=${filter.gender}&from_date=${filter.fromdate}&to_date=${filter.todate}`, //call_type=1 for export, call_type=0 for normal
        {}, // Post body, if required
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data; // Return the data property from Axios response
    } catch (error) {
      console.error("Failed to fetch candidates:", error);
      throw new Error("Failed to fetch candidates");
    }
  };

  // Fetch data with React Query and pass page as a dependency
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["candidates", page,selectedState,filter],
    queryFn: () => fetchCandidates(page,selectedState,0,filter),
    keepPreviousData: true, // Retain previous page data while loading new page
  });
  // Fetch data with React Query and pass page as a dependency
  const { data: data2, isLoading: isLoading2, isError: isError2, error: error2 } = useQuery({
    queryKey: ["candidates", page,selectedState,1,filter],
    queryFn: () => fetchCandidates(page,selectedState,1,filter),
    keepPreviousData: true, // Retain previous page data while loading new page
  });

  // Handle loading and error states
  if (isLoading) return <Loading />;
  if (isError)
    return (
      <div className="text-center text-2xl text-red-500">
        Error: {error.message}
      </div>
    );

  const candidates = data?.data || []; // Access the candidates array
  const totalCandidates = data?.total || 0; // Total number of candidates
  const totalPages = Math.ceil(totalCandidates / 10); // Assuming 10 items per pag
  const stateOptions = state.map((state) => ({
    value: state.state_name,
    label: state.state_name.charAt(0).toUpperCase() + state.state_name.slice(1), // Capitalize for display
  }));

  const handleSelectChange = (option) => {
    setTempState(option?.value || null);
  };
  // Apply filter button handler
  const applyFilter = () => {
    setSelectedState(tempState); // Update selectedState for query
    setfilter({
      gender: selectedgender,
      fromdate: fromdate,
      todate: todate,
    });
    setPage(1); // Reset to first page on new filter
  };
  const removeFilter = () => {
    setSelectedState(""); // Update selectedState for query
    setTempState("")
    setfilter({
      gender: '',
      fromdate: '',
      todate: '',
    });
    setSelectedgender("")
    setFromdate("")
    settodate("")
   
    setPage(1) // Reset to first page on new filter
  };
  const exportToExcel = async () => {
    setLoading(true); // Set loading state to true
    try {
        // Delay to simulate UI updates (for debugging purposes)
        await new Promise((resolve) => setTimeout(resolve, 0));

        // Convert data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(data2);

        // Create a workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "TableData");

        // Write the workbook to a binary format
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });

        // Use FileSaver to save the file
        const dataBlob = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const filename = `candidates_${tempState ? tempState : "list"}.xlsx`;
        saveAs(dataBlob, filename);

        console.log("Export successful:", filename);
    } catch (error) {
        console.error("Error exporting data to Excel:", error);
    } finally {
        setLoading(false); // Set loading state to false
    }
};


  return (
    <div class="relative py-4 overflow-x-auto shadow-md sm:rounded-xl">
      <div className="flex justify-between mb-4 items-center">

      <h2 className="mb-4 font-bold text-xl px-2">Candidates List</h2>
      <div>
          {selectedState && (<i className="text-lg"> Showing results for  <span className="text-[#0f3057] "> {selectedState}</span></i>)}
        </div>
      <div>
            <button
                onClick={exportToExcel}
                disabled={loading}
                style={{
                   
                    cursor: loading ? "not-allowed" : "pointer",
                }}
                className="font-medium text-white bg-[#0f3057]  px-2 py-2 rounded-lg "
            >
                {loading ? "Exporting data, please wait..." : "Export to Excel"}
            </button>
          
        </div>
      </div>
      <div class="pb-4 flex  gap-2 justify-between items-center w-full py-2 px-6 bg-white ">
  
        
      <div class="pb-4 flex items-center bg-white ">
  <DateRangePicker setSelectedgender={setSelectedgender} setFromdate={setFromdate} setTodate={settodate}/>


        <Select
          className="max-w-xl "
          options={stateOptions}
          isLoading={isLoading4}
          value={tempState.label}
          onChange={handleSelectChange}
          placeholder="Search and select State"
          components={{ MenuList: VirtualizedMenuList }}
          />
          </div>
          <div className=" pb-4 flex gap-2 items-center">

        <button onClick={applyFilter} className="font-medium text-white bg-[#0f3057]  px-2 py-2 rounded-lg ">
          Apply filter
        </button>
        <button onClick={removeFilter} className="font-medium text-white bg-[#0f3057]  px-2 py-2 rounded-lg ">
          Remove filter
        </button>
          </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-200">
            <tr>
              <th className="p-4">S.No.</th>
              <th className="px-6 py-3">Name</th>
              <th className="px-6 py-3">mobile</th>
              <th className="px-6 py-3">Email</th>
              <th className="px-6 py-3">Gender</th>
              <th className="px-6 py-3">Email Verfified</th>
              <th className="px-6 py-3">Total jobs Applied</th>
              <th className="px-6 py-3">Education</th>
              <th className="px-6 py-3">Location</th>
              <th className="px-6 py-3">created date</th>
            </tr>
          </thead>
          <tbody>
            {candidates.map((candidate, index) => (
              <tr
                key={candidate.id}
                className="bg-white border-b hover:bg-gray-200"
              >
                <td className="px-4 py-1">{(page - 1) * 10 + index + 1}</td>
                <td className="px-1 border border-gray-300 py-4 font-medium text-gray-900">
                  {candidate.name}
                </td>
                <td className="px-1 py-1  border border-gray-300 ">{candidate.mobile}</td>
                <td className="px-1 py-1 border border-gray-300 ">{candidate.email}</td>
                <td className="px-6 py-1 border border-gray-300 ">{candidate.gender?"Female":"Male"}</td>
                <td className="px-6 py-2 font-medium text-gray-900 border border-gray-300 ">{candidate.verified_status?"yes":"No"}</td>
                <td className="px-6 py-4 border border-gray-300  flex items-center ">{candidate.applied_jobs?"Yes":"12"} <JobPopupModal jobData={candidate}/></td>
                <td className="px-6 py-1 border border-gray-300 ">{candidate.education || "--"}</td>
                <td className="px-6 py-1 border border-gray-300 ">
                  {candidate.state_name?candidate.state_name +" ,": "--" } { candidate.city_name}  
                </td>
                <td className="px-2 py-1">
                  {candidate?.created_at.slice(0,10)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Paginationa
          page={page}
          totalCandidates={totalCandidates}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
}

export default Candidatea;
