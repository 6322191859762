import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS
import { useAuth } from '../../Utils/Context';
export const SaveJobComponent = ({ JobId ,setShowTooltip2,id,showTooltip2}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Initialize Toastify
  const{api} = useAuth()
  const handleSave = async (JobId) => {
  setLoading(true);
  setError(null);

  const token = localStorage.getItem('token');
  try {
    const response = await api.post('/api/candidate/save_job',  {
      JobId:JobId, // Sending jobId in the request body
    },
    {
      headers: {
        Authorization: `Bearer ${token}`, // Include the Bearer token
      },
    },);

    if (response.data.success) {
      // Show success message
      toast.success(response.data.message || 'Job saved successfully!');
    }else{
      toast.error(response.data.message)
    }

  } catch (err) {
    console.error('Error saving job:', err);
    setError('Failed to save the job.');

    // Show error message using Toastify
    toast.error('Error: Unable to save the job.');
  } finally {
    setLoading(false);
  }
};

  return (
    <p
    class="px-4 sm:px-4 sm:py-2 py-1 lg:text-sm text-[14px] sm:font-bold font-semibold mb-2 text-gray-100 transition-colors duration-300 transform bg-[#0f3057] rounded cursor-pointer hover:bg-gray-500"
    tabindex="0"
    role="button"
    onMouseEnter={() => setShowTooltip2(true)}
    onMouseLeave={() => setShowTooltip2(false)}
    onClick={() => handleSave(JobId)}
  >{loading ? 'Saving...' :
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="w-6 h-5  text-gray-100 transition-colors duration-300  hover:text-gray-100 "> < path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"fill="#ffffff" /></svg>
   } {showTooltip2 && (
<div className="absolute bottom-full right-0 -left-3 mb-2 w-max bg-gray-700 text-white text-xs rounded py-1 px-4">
Save job

</div>
)}
  </p>
  );
};

export const fetchCandidates = async (page, filters) => {
  

  // Start with the base URL
  // let url = `https://info.myschoolnaukri.com/api/candidate/job_list?page=${page}`;

  // Build the query parameters string based on the filter values
  const { designation, location, experience,education,workMode,specilization } = filters;

  // Create a URLSearchParams object to append query parameters
  const queryParams = new URLSearchParams();

  // Append filters as query parameters (if they exist)
  if (filters.designation) queryParams.append("designation", filters.designation);
  if (filters.location) queryParams.append("location", filters.location);
  if (filters.experience) queryParams.append("experience", filters.experience);
  if (filters.education) queryParams.append("education", filters.education);
  if (filters.workMode) queryParams.append("workMode", filters.workMode);
  if (filters.specilization) queryParams.append("specilization", filters.specilization);

  // Optionally, you can also append experience filters to the query string


  // Add pagination to query string
  queryParams.append('page', page);

  // Prepare the body (if needed by the API)
  const body = {
    location: location || null,
    designation: designation || null,
    experience: experience || null,
    education: education || null,
    workMode: workMode || null,
    specilization: specilization || null,
    
    page,
  };
  

  // If there are any filter parameters, append them to the URL
  // if (params.toString()) {
  //   url += `&${params.toString()}`;
  // }
   //   Object.entries(filters).forEach(([key, value]) => {
  //     if (value) {
  //       url += `&${key}=${value}`;
  //     }
  //   });
  const token = localStorage.getItem('token');
  const response = await fetch(`https://info.myschoolnaukri.com/api/candidate/job_list?${queryParams.toString()}`, {
    method: 'POST',

    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json', // Include the Bearer token
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch candidates");
  }

  return response.json();
};


// export const Fetchdata = async ()=>{
// const {api} = useAuth()

// const token = localStorage.getItem("token"); 
// console.log(token)// Replace with your actual Bearer token
//       try {
//         const response = await api.get(
//           '/api/candidate/get_education_details',
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         console.log(response.data[0])
//        return response?.data[0]
//       } catch (error) {
//         console.error("Error fetching profile data:", error);
//       }
    
// }
