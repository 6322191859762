import React, { useState } from 'react'
import logo1 from '../assets/flogo1.jpeg'
import { Link } from 'react-router-dom';

  

function Delete_account() {
    const[show, setshow] = useState(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const sendemail = (e)=>{
      e.preventDefault();
      if (!emailPattern.test(email)) {
        setError("Please enter a valid email address");
      } else {
        setError("");
        // Handle form submission (e.g., send data to API)

        setshow(true)
      }
    }
  return (
    <section class="bg-white :bg-gray-900">
    <div class="container flex flex-col items-center justify-center min-h-screen px-6 mx-auto">
    <div className="flex justify-center mx-auto">
          <img className="h-6 sm:h-[50px]" src={logo1} alt="Logo" />
        </div>

        <h3 className="mt-3 text-xl font-medium text-center text-gray-600">
          Welcome to MySchoolNaukri
        </h3>

       

        <div class="w-full max-w-md mx-auto mt-6">
            <form onSubmit={sendemail} >
                <div>
                    <label class="block mb-2 text-sm text-gray-600 :text-gray-200">Enter your registered Email</label>
                    <input
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border rounded-lg focus:border-[#11a9b5] focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-[#11a9b5]"
              type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
           
              placeholder="Email Address"
              aria-label="Email Address"
            />
                </div>
             {show && (
              <div className='flex justify-between'>

             <p  class="inline-block mt-4 font-semibold text-green-500 capitalize ">
             Email sent 
         </p>
             <button   class="inline-block mt-4 pointer-cursor  font-semibold text-blue-900 capitalize ">
             Resend email? 
         </button>
              </div>

             )}
                
                {error && <p style={{ color: "red" }}>{error}</p>}
                <button type='sumbit' class="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#11a9b5] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                    Delete account
                </button>

                <div class="mt-6 text-center">
                    <a href="Signup" class="text-sm text-blue-900 hover:underline :text-blue-400">
                        Don’t have an account yet? Sign up 
                    </a>
                </div>

               
            </form>
        </div>
    </div>
</section>
  )
  
}

export default Delete_account