import React from "react";


import { Link,Outlet } from "react-router-dom";

import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Footer from "../components/Footer";
import { ToastContainer } from "react-toastify";

function LayoutC() {
 
 
  
  return (
    <>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <Navbar />

        <Sidebar  />

        {/* Main content */}
        <div className="sm:p-4  py-10 lg:ml-64">
          <div className="sm:p-4 px-1 py-2 border-2 border-gray-200 border-dashed rounded-lg  mt-14">
            <Outlet />
            <div className="sm:block hidden">
            <Footer/>

            </div>
           <ToastContainer/>
          </div>
        </div>

        {/* Bottom navbar for mobile */}
        <nav className="fixed bottom-0 left-0 z-50 w-full h-18 py-4 bg-white border-t border-gray-200 :bg-gray-700 :border-gray-600 sm:hidden">
          <div className="flex items-center justify-center  bg-gray-100">

          {/* <button className=" flex items-center justify-center px-6   "> <Jobpostmodal/></button> */}
          </div>
          <div className="grid h-fit mt-1 max-w-xl grid-cols-4 mx-auto">
            <Link to="main">
            <button
              type="button"
              className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 "
            >
              <svg
                className="w-5 h-5 mb-1 text-gray-500  group-hover:text-blue-600 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
              <span className="text-sm text-gray-500 group-hover:text-blue-600 ">
                Home
              </span>
            </button>
            </Link>
            <Link to='jobs'>
           
            <button
              type="button"
              className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 "
            >
              <svg className="w-5 h-5 mb-1 text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
                    </svg>
              <span className="text-sm text-gray-500  group-hover:text-blue-600 ">
               Jobs
              </span>
            </button>
            </Link>
            <Link to="main">
           
            <button
              type="button"
              className="inline-flex flex-col items-center justify-center px-6 hover:bg-gray-50 "
            >
             <svg className="w-5 h-5 mb-1 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 576 512"><path d="M249.6 471.5c10.8 3.8 22.4-4.1 22.4-15.5l0-377.4c0-4.2-1.6-8.4-5-11C247.4 52 202.4 32 144 32C93.5 32 46.3 45.3 18.1 56.1C6.8 60.5 0 71.7 0 83.8L0 454.1c0 11.9 12.8 20.2 24.1 16.5C55.6 460.1 105.5 448 144 448c33.9 0 79 14 105.6 23.5zm76.8 0C353 462 398.1 448 432 448c38.5 0 88.4 12.1 119.9 22.6c11.3 3.8 24.1-4.6 24.1-16.5l0-370.3c0-12.1-6.8-23.3-18.1-27.6C529.7 45.3 482.5 32 432 32c-58.4 0-103.4 20-123 35.6c-3.3 2.6-5 6.8-5 11L304 456c0 11.4 11.7 19.3 22.4 15.5z"/></svg>
              <span className="text-sm text-gray-500 :text-gray-400 group-hover:text-blue-600 :group-hover:text-blue-500">
                Applied
              </span>
            </button>
            </Link>
            <Link to='profile'>
        
            <button
              type="button"
              className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 :hover:bg-gray-800 group"
            >
              <svg
                className="w-5 h-5 mb-1 text-gray-500 :text-gray-400 group-hover:text-blue-600 :group-hover:text-blue-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
              </svg>
              <span className="text-sm text-gray-500 :text-gray-400 group-hover:text-blue-600 :group-hover:text-blue-500">
                Profile
              </span>
            </button>
            </Link>
         
          </div>
        </nav>
      </div>
    </>
  );
}

export default LayoutC;
