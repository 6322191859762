import React from 'react';
import { Navigate } from 'react-router-dom';
import {useAuth} from './Context'  // Import the authentication check function

// ProtectedRoute component
const Protectedroute = ({ children ,allow }) => {
    const { isAuthenticated } = useAuth()
    const userType = localStorage.getItem('userType');
    console.log(userType)
  if (!isAuthenticated()) {
    // If the user is not authenticated, redirect to the login page
    return <Navigate to="/" replace />;
  }
  if (!allow.includes(userType)) {
    return <Navigate to="/unauthorized" />; // Redirect to unauthorized page if role is invalid
  }
  // Otherwise, render the protected component
  return children;
};

export default Protectedroute;