import React from 'react'
import { faSchool, faUserTie, faChalkboardTeacher, faUserGraduate, faBookOpen, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Coursesbanner2() {
  return (
<div className="mb-10  text-blue-900 p-8 ">
      <h2 className="text-3xl font-bold mb-12 text-center ">Whom are these E-learning Courses for?</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4 text-center">
        
        {/* School Leadership Team */}
        <div className="flex flex-col items-center">
          <FontAwesomeIcon icon={faUsers} className="text-3xl mb-2" />
          <p className="font-semibold">School Leadership Team</p>
        </div>
        
        {/* Principals */}
        <div className="flex flex-col items-center">
          <FontAwesomeIcon icon={faUserTie} className="text-3xl mb-2" />
          <p className="font-semibold">Principals</p>
        </div>
        
        {/* HODs */}
        <div className="flex flex-col items-center">
          <FontAwesomeIcon icon={faChalkboardTeacher} className="text-3xl mb-2" />
          <p className="font-semibold">HODs</p>
        </div>
        
        {/* Coordinators */}
        <div className="flex flex-col items-center">
          <FontAwesomeIcon icon={faUserGraduate} className="text-3xl mb-2" />
          <p className="font-semibold">Coordinators</p>
        </div>
        
        {/* Teachers */}
        <div className="flex flex-col items-center">
          <FontAwesomeIcon icon={faBookOpen} className="text-3xl mb-2" />
          <p className="font-semibold">Teachers</p>
        </div>
        
        {/* Educators */}
        <div className="flex flex-col items-center">
          <FontAwesomeIcon icon={faSchool} className="text-3xl mb-2" />
          <p className="font-semibold">Educators</p>
        </div>
      </div>
    </div>
  )
}

export default Coursesbanner2