import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/header.jpeg";
import Loading from "../../Utils/Loading";
import Applymodal from "../components/Applymodal";
import Jobcardc from "../components/Jobcardc";
import { useAuth } from "../../Utils/Context";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SaveJobComponent } from "../components/Function";
const Applypage = () => {
  const { id } = useParams(); // Get job ID from the URL
  const [jobDetails, setJobDetails] = useState(null); // To store the job details
  const [jobs, setJobs] = useState(null); // To store the job details
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [related, setrelated] = useState([]);
  const navigate = useNavigate();
  const [jobData, setJobData] = useState(null);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const { api } = useAuth();
 const [isapplied, setisapplied] = useState(false)
 
  const handleSave = async () => {
    setIsLoading(true);

    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`/api/candidate/view_job?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include the Bearer token
        },
      });
      setJobData(response.data);
      if (response.data.success) {
        // Show success message
        toast.success(response.data.message || "Job saved successfully!");
        setIsLoading(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error("Error saving job:", err);

      // Show error message using Toastify
      toast.error("Error: Unable to save the job.");
    } finally {
      setIsLoading(false);
    }
  };
 
  

  const handleCardClick = (jobId) => {
    navigate(`/candidatedashboard/jobs/apply/${jobId}`);
  };

  const relatedjobs = () => {
    const jobsrelated = jobs.filter(
      (job) =>
        job.id !== id &&
        (job.name === jobDetails.name ||
          job.state_name === jobDetails.state_name)
    );
    setrelated(jobsrelated);
  };

  useEffect(() => {
    handleSave();
  }, [id]);

  useEffect(() => {
    if (jobDetails) {
      relatedjobs();
    }
  }, [jobDetails]);

  if (isLoading) return <Loading />;

  // if (!jobDetails) return <p>No job details found</p>;
  // if (!related) return <p>No job details found</p>;


  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {jobData && (
        <div
          className="w-full  bg-cover sm:bg-contain h-[12rem] sm:h-[15rem]"
          style={{
            backgroundImage: `url(${backgroundImage})`, // Use the imported image
          }}
        >
          <div className="flex items-center justify-center px-0 sm:px-10 w-full h-full bg-gray-900/60">
            <div className="flex flex-wrap w-full mb-4 sm:mb-10 gap-10 flex-row items-center justify-center sm:px-20 sm:justify-between">
              <div className="flex flex-col  sm:items-start sm:justify-normal justify-between">
                <h1 className="sm:text-3xl text-xl font-bold title-font mb-0 sm:mb-4 text-white">
                  {jobData?.designation}
                </h1>
                <div className="flex items-center w-fit rounded-lg px-3 py-1 text-white mt-1 bg-[#11a9b5]">
                  <svg
                    aria-label="suitcase icon"
                    className="sm:w-6 sm:h-6 h-3 w-3 fill-current"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14 11H10V13H14V11Z" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7 5V4C7 2.89545 7.89539 2 9 2H15C16.1046 2 17 2.89545 17 4V5H20C21.6569 5 23 6.34314 23 8V18C23 19.6569 21.6569 21 20 21H4C2.34314 21 1 19.6569 1 18V8C1 6.34314 2.34314 5 4 5H7ZM9 4H15V5H9V4ZM4 7C3.44775 7 3 7.44769 3 8V14H21V8C21 7.44769 20.5522 7 20 7H4ZM3 18V16H21V18C21 18.5523 20.5522 19 20 19H4C3.44775 19 3 18.5523 3 18Z"
                    />
                  </svg>
                  <h1 className="sm:px-2 px-1 sm:text-sm font-medium text-[10px] text-white">
                    {jobData.employment_type}
                  </h1>
                </div>
              </div>
              <div className="flex  flex-row justify-between items-center space-x-4 sm:space-x-2 sm:space-y-3 px-2 py-3 mt-4">
                <div>
                  <button
                    onClick={openModal}
                    className="inline-flex items-center border-0 sm:px-6 px-2 py-2 rounded-lg text-white border-transparent text-sm font-medium bg-[#11a9b5] hover:bg-[#0f3057] hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    disabled={isapplied}
                 >
               {isapplied? "Applied" : "Apply Now"} 
               <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-4 h-4 ml-1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </div>
                <div>
                  <SaveJobComponent
                    setShowTooltip2={setShowTooltip2}
                    showTooltip2={showTooltip2}
                    JobId={id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col sm:flex-row w-full p-4 sm:p-0 justify-evenly">
        <div className="lg:w-[800px] md:w-[600px] px-4 shadow-md sm:py-6 sm:mt-12 mt-4 py-8 sm:mb-14 border-2">
          <div className="space-y-2 px-2">
            <h1 className="text-2xl font-medium text-[#11a9b5]">
              {jobData?.designation}
            </h1>
            <h1 className="text-xl font-medium mb-4 text-[#0f3057]">
              {jobData?.school_name || "Msn School"}
            </h1>

            <p>
              <strong>Job description:</strong>
            </p>
            <p className="mt-2 text-gray-600 text-[14px] sm:text-sm px-1">
              {jobData?.job_description ||
                "Job description is not provided here"}
            </p>
            <div className="flex w-full items-center justify-start space-x-2 px-2 py-4 mt-8">
              <div>

              <button
                onClick={openModal}
                className="inline-flex items-center border-0 sm:px-6 px-2 py-2 rounded-lg text-white border-transparent text-sm font-medium bg-[#11a9b5] hover:bg-[#0f3057] hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                disabled={isapplied}
                >
               {isapplied? "Applied" : "Apply Now"} 
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4 h-4 ml-1"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </button>
                  </div>
              {/* <div className="py-1">
                <SaveJobComponent
                  setShowTooltip2={setShowTooltip2}
                  showTooltip2={showTooltip2}
                  JobId={id}
                />
              </div> */}
            </div>
          </div>
        </div>
        <div className="lg:w-[350px]  p-3 sm:p-0 sm:py-6 mt-4 py-8">
          <div className="space-y">
            <div class="flex items-center mt-1 px-2 border-2   bg-slate-100   hover:bg-[#d9f1f3]  text-[#11a9b5] ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
                class="sm:w-[30px] sm:h-[30px] h-[30px] w-[30px] text-[#11a9b5]  fill-current"
              >
                <path
                  fill="#11a9b5"
                  d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192l42.7 0c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0L21.3 320C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7l42.7 0C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3l-213.3 0zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352l117.3 0C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7l-330.7 0c-14.7 0-26.7-11.9-26.7-26.7z"
                />
              </svg>
              <div className="flex px-3 flex-col">
                <h1 class="sm:px-2 px-1 mt-2 sm:text-lg text-[14px] font-semibold text-gray-600">
                  No. of openings{" "}
                </h1>
                <h1 class="sm:px-2 px-1 mb-2 sm:text-sm text-[14px]  text-gray-600">
                  {jobData?.openings}{" "}
                </h1>
              </div>
            </div>
            <div class="flex items-center mt-1 px-2  border-2   bg-slate-100  hover:bg-[#d9f1f3] text-[#11a9b5] ">
              <svg
                aria-label="location pin icon"
                class="w-6 mb-4 h-6 3  fill-current"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.2721 10.2721C16.2721 12.4813 14.4813 14.2721 12.2721 14.2721C10.063 14.2721 8.27214 12.4813 8.27214 10.2721C8.27214 8.063 10.063 6.27214 12.2721 6.27214C14.4813 6.27214 16.2721 8.063 16.2721 10.2721ZM14.2721 10.2721C14.2721 11.3767 13.3767 12.2721 12.2721 12.2721C11.1676 12.2721 10.2721 11.3767 10.2721 10.2721C10.2721 9.16757 11.1676 8.27214 12.2721 8.27214C13.3767 8.27214 14.2721 9.16757 14.2721 10.2721Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.79417 16.5183C2.19424 13.0909 2.05438 7.3941 5.48178 3.79418C8.90918 0.194258 14.6059 0.0543983 18.2059 3.48179C21.8058 6.90919 21.9457 12.606 18.5183 16.2059L12.3124 22.7241L5.79417 16.5183ZM17.0698 14.8268L12.243 19.8965L7.17324 15.0698C4.3733 12.404 4.26452 7.9732 6.93028 5.17326C9.59603 2.37332 14.0268 2.26454 16.8268 4.93029C19.6267 7.59604 19.7355 12.0269 17.0698 14.8268Z"
                />
              </svg>
              <div className="flex px-3  flex-col">
                <h1 class="sm:px-2 px-1  mt-1  sm:text-lg text-[14px] font-semibold text-gray-600">
                  Job Location{" "}
                </h1>
                <h1 class="sm:px-2 px-1 mb-2 sm:text-sm text-[14px]  text-gray-600">
                  {jobData?.state_name} , {jobData?.location}{" "}
                </h1>
              </div>
            </div>
            <div class="flex items-center mt-1 px-2 border-2    bg-slate-100 hover:bg-[#d9f1f3] text-[#11a9b5] ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="w-6 mb-4 h-6    fill-current"
              >
                {" "}
                <path
                  fill="#11a9b5"
                  d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-224c0-35.3-28.7-64-64-64L80 128c-8.8 0-16-7.2-16-16s7.2-16 16-16l368 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L64 32zM416 272a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
                />
              </svg>
              <div className="flex px-3  flex-col">
                <h1 class="sm:px-2 px-1  mt-2 sm:text-lg text-[14px] font-semibold text-gray-600">
                  Salary{" "}
                </h1>
                <h1 class="sm:px-2 px-1 mb-2 sm:text-sm text-[14px]  text-gray-600">
                  ₹ {jobData?.salarymin} - {parseInt(jobData?.salarymax)} (P.A){" "}
                </h1>
              </div>
            </div>
            <div class="flex items-center mt-1 px-2 border-2  mb-4 bg-slate-100 hover:bg-[#d9f1f3] text-[#11a9b5] ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                class="w-6 mb-4 h-6    fill-current"
              >
                <path
                  fill="#11a9b5"
                  d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"
                />
              </svg>
              <div className="flex  px-3  flex-col">
                <h1 class="sm:px-2 px-1  mt-2 sm:text-lg text-[14px] font-semibold text-gray-600">
                  Board{" "}
                </h1>
                <h1 class="sm:px-2 px-1 mb-2 sm:text-sm text-[14px]  text-gray-600">
                  {jobData?.board}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && <Applymodal onClose={closeModal} JobId={id} isapplied={setisapplied} />}
      {related.length > 0 && (
        <div class="flex flex-wrap w-full mb-10 flex-col items-center text-center">
          <h1 class="sm:text-3xl text-2xl font-bold title-font mb-2 text-gray-900">
            Related job <span className="text-[#11a9b5]">Postings</span>{" "}
          </h1>
          <p class="lg:w-1/2 w-full leading-relaxed text-gray-500">
            Check out the latest job vacancies.
          </p>
        </div>
      )}
      <div class="grid lg:grid-cols-2 grid-cols-1 lg:gap-6 gap-8 px-4 mb-20 lg:px-8">
        {related.slice(0, 4).map((job) => (
          <Jobcardc
            id={job.id}
            state_name={job.state_name}
            name={job.name}
            school_name={job.school_name}
            city_name={job.city_name}
            job_description={job.job_description}
            salarymax={job.salarymax}
            date={job.max_created_at}
            applicant={job.applicant_count}
          />
        ))}
      </div>
    </>
  );
};

export default Applypage;
