

// utils.js
import axios from 'axios';

export const addLocation = async (formData, data) => {
  try {
    const selectedState = formData.state;
    const state = data.find((state) => state.state === selectedState);
    if (state) {
      const stateId = state.stateid;
      const cityName = formData.city;
      const response = await axios.post('https://info.myschoolnaukri.com/api/master/add_location', {
        StateId: stateId,
        StateName: selectedState,
        CityName: cityName,
      });
      return response.data;
    } else {
      throw new Error('State not found');
    }
  } catch (error) {
    throw error;
  }
};

export const addDesigantion = async(formData, type)=>{
    try {
        const text = formData.Designation;
        const response = await axios.post('https://info.myschoolnaukri.com/api/master/add_master', {
            Name: text,
            Type: type,
          });
          return response.data;
    } catch (error) {
        throw error;
    }
}
export const addSpecilization = async(formData, type)=>{
    try {
        const text = formData.Specilization;
        const response = await axios.post('https://info.myschoolnaukri.com/api/master/add_master', {
            Name: text,
            Type: type,
          });
          return response.data;
    } catch (error) {
        throw error;
    }
}
export const addSkills = async(formData, type)=>{
    try {
        const text = formData.Skills;
        const response = await axios.post('https://info.myschoolnaukri.com/api/master/add_master', {
            Name: text,
            Type: type,
          });
          return response.data;
    } catch (error) {
        throw error;
    }
}
export const addEducation = async(formData, type)=>{
    try {
        const text = formData.Education;
        const response = await axios.post('https://info.myschoolnaukri.com/api/master/add_master', {
            Name: text,
            Type: type,
          });
          return response.data;
    } catch (error) {
        throw error;
    }
}

export const fetchsubsname = async(url)=>{
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`, // Include the Bearer token
      },});
      // Extract id and school_name from the single object
      
      const schoolList = response.data; // Assuming response.data is an array of school objects

// Map through the schoolList to create an array of options
const schoolOptions = schoolList.map(school => ({
  value: school.id,               // Use id as value
  label: school.subscription_plan, // Use subscription_plan as label
}));
console.log(schoolOptions); // Log the array of options
// Check if schoolOptions is empty and return an empty array if so
if (!schoolOptions.length) return [];

// Return the schoolOptions array
return schoolOptions;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
}