import React, { useState, useEffect } from "react";
import { useAuth } from "../../Utils/Context";
import Statsprofile from "../components/Statsprofile";
import UploadCard from "../components/UploadCard";
import PersonalDetails from "../components/PersonalDetails";
import EducationDetails from "../components/EducationDetails";
import Experiencedetails from "../components/Experiencedetails";
const ProfilePage = () => {
  const { api, setusername } = useAuth();
  const [loading, setLoading] = useState(true);
  const [tempData, setTempData] = useState({});
  const [educationdata, seteducationdata] = useState({});
  const [expdata, setexpdata] = useState({});
  const [resumeupdate, setresumeupdate] = useState(false);
  const [videoupdate, setvideoupdate] = useState(false);

  useEffect(() => {
    const fetchAllData = async () => {
      const token = localStorage.getItem("token");
      try {
        const [educationResponse, personalResponse, Experience] =
          await Promise.all([
            api.get("/api/candidate/get_education_details", {
              headers: { Authorization: `Bearer ${token}` },
            }),
            api.get("/api/candidate/get_teacher_personal_details", {
              headers: { Authorization: `Bearer ${token}` },
            }),
            api.get("/api/candidate/get_experience_details", {
              headers: { Authorization: `Bearer ${token}` },
            }),
          ]);
        seteducationdata(educationResponse.data[0]);
        setTempData(personalResponse.data[0]);
        setexpdata(Experience.data[0]);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  setusername(tempData.first_name);
console.log(tempData)
  const handleResumeUpload = (e) => {
    console.log("Resume file:", e.target.files[0]);
    // Implement the upload logic
  };

  const handleVideoUpload = (e) => {
    console.log("Video file:", e.target.files[0]);
    // Implement the upload logic
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}

      {/* <Statsprofile /> */}
      <PersonalDetails tempData={tempData} />

      {/* Main Content */}
      <div className="container mx-auto mt-5">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <UploadCard
            title="Update Resume"
            isUpdateMode={resumeupdate}
            setUpdateMode={setresumeupdate}
            currentFile={tempData.resume}
            fileType="1"
            onFileUpload={handleResumeUpload}
          />
          <UploadCard
            title="Upload a Demo Video"
            isUpdateMode={videoupdate}
            setUpdateMode={setvideoupdate}
            currentFile={tempData.youtube_link}
            fileType="0"
            onFileUpload={handleVideoUpload}
          />
          {/* Left Sidebar */}
          <div className="col-span-1">
            <EducationDetails educationdata={educationdata} />
          </div>
          <div className="col-span-1">
            {/* Experience */}
            <Experiencedetails expdata={expdata} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
