import React from 'react'
import Accordion from './AccordionItem'

function Coursesdetail() {
  return (
    <section class="text-gray-600 body-font overflow-hidden">
  <div class="max-w-8xl px-1 sm:py-14 mx-auto">
    <div class="lg:w-[90%] mx-auto flex flex-wrap gap-6  mb-10">
      <div class="lg:w-[50%] w-full lg:pl-10 p-3 lg:py-6 mt-6 lg:mt-0">
        <h2 class="text-sm  text-white rounded-lg  bg-[#11a9b5] w-fit px-2 ">In collab with STTAR</h2>
        <h1 class="text-gray-900 text-3xl  title-font font-medium mb-1">Developing Critical Thinking Curriculum for Pre-primary
        </h1> 
        <div class="sm:flex  mb-4">
          <span class="flex items-center">
          <span class="text-gray-600 mr-2">4.5</span>

          <svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
    </svg>
    <svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
    </svg>
    <svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
    </svg>
    <svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
    </svg>
    <svg class="w-4 h-4 text-gray-300 me-1 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
    </svg>  
            <span class="text-gray-600 ml-3">4 Reviews</span>
          </span>
          <span class="flex sm:ml-3 sm:pl-3 py-2 sm:border-l-2 border-gray-200 space-x-2s">
            <p class="text-gray-500">
              Created by - naman Mathur
            </p>
            
          </span>
        </div>
        <p class="leading-6 text-justify "> The pre-primary years are extremely important as 85% of a child’s cumulative brain development occurs prior to the age of 6. Foundational learning must therefore focus on stimulating the mind through a critical thinking curriculum to ensure healthy brain development and growth. STTAR’s self-paced learning course on ‘Developing A Critical Thinking Curriculum For The Pre-Primary’ deep-dives into research on brain science and its impact on early childhood years to help teachers develop critical thinking in learners through literacy, language, numeracy, stories, songs, science, art and STEM</p>
       
        <div class="flex mt-6">
          <span class="title-font font-medium text-2xl text-gray-900">₹ 999.00</span>
          <button class="flex ml-auto text-white bg-[#11a9b5] font-medium border-0 py-2 px-6 focus:outline-none hover:bg-blue-900 rounded">Buy Course</button>
         
        </div>
      </div>
      <img alt="ecommerce" class="lg:w-[600px] w-full  object-cover rounded" src="https://online.sttar.in/wp-content/uploads/2023/04/Poonam-Kochitty-Developing-Critical-Thinking.jpg"/>

    </div>
    <div className='w-full lg:w-[90%] mx-auto mb-10  sm:flex flex-wrap gap-6 mt-6 '>
    <div className='flex p-4  sm:w-[60%] flex-wrap bg-gray-50 shadow-lg border'>
    <h1 class="text-gray-900 text-3xl title-font font-medium  mt-2"> What you'll learn
    </h1>   
    <div class="flex flex-wrap sm:mx-auto  ">
      <div class="p-2 sm:w-1/2 w-full">
        <div class=" rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-[#11a9b5] w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Know why critical thinking is important for students and teachers</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class=" rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-[#11a9b5] w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Know why critical thinking is important for students and teachers</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class=" rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-[#11a9b5] w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Coloring Book Ethical Know why critical thinking is important for students and teachers</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class=" rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-[#11a9b5] w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Typewriter Polaroid Know why critical thinking is important for students and teachers</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class=" rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-[#11a9b5] w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">Pack Know why critical thinking is important for students and teachers Truffaut Blue</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class=" rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-[#11a9b5] w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">The Catcher In The Rye</span>
        </div>
      </div>
    </div>
    </div>
    <div className='flex sm:w-1/3 flex-wrap mt-4 sm:mt-0 gap-8 p-3 hover:bg-slate-100 transition-all shadow-lg border'>
    <h1 class="text-gray-900 text-3xl title-font font-medium mb-1"> This course include
    </h1>   
    <div class="flex flex-wrap sm:mx-auto sm:mb-2 -mx-2">
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" className='h-6 w-6 mr-4 text-[#11a9b5]' viewBox="0 0 512 512"><path d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
          
          <div className="flex-col ">

          <span class="title-font font-medium">Course duration</span>

          <p>2.4 hrs</p>
          </div>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" className='h-6 w-6 mr-4 text-[#11a9b5]' viewBox="0 0 576 512"><path d="M0 128C0 92.7 28.7 64 64 64l256 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2l0 256c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1l0-17.1 0-128 0-17.1 14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"/></svg>
          <div className="flex-col ">

          <span class="title-font font-medium">No of videos</span>

<p>23</p>
</div>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" className='h-6 w-6 mr-4 text-[#11a9b5]' viewBox="0 0 448 512"><path d="M96 0C43 0 0 43 0 96L0 416c0 53 43 96 96 96l288 0 32 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l0-64c17.7 0 32-14.3 32-32l0-320c0-17.7-14.3-32-32-32L384 0 96 0zm0 384l256 0 0 64L96 448c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16l192 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-192 0c-8.8 0-16-7.2-16-16zm16 48l192 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-192 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>
          <div className="flex-col ">

<span class="title-font font-medium">No of Assesment</span>

<p>23</p>
</div>        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" className='h-6 w-6 mr-4 text-[#11a9b5]' viewBox="0 0 384 512"><path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z"/></svg>
          <div className="flex-col ">

<span class="title-font font-medium">No of Handouts</span>

<p>23</p>
</div>        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
      
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" className='h-6 w-6 mr-4 text-[#11a9b5]' viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"/></svg>
          <div className="flex-col ">

<span class="title-font font-medium">Access duration</span>

<p>Lifetime Access
</p>
</div>        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
        
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" className='h-6 w-6 mr-4 text-[#11a9b5]'  viewBox="0 0 640 512"><path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192l42.7 0c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0L21.3 320C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7l42.7 0C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3l-213.3 0zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352l117.3 0C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7l-330.7 0c-14.7 0-26.7-11.9-26.7-26.7z"/></svg>
          <div className="flex-col ">

<span class="title-font font-medium">Subscribers</span>

<p>23</p>
</div>        </div>
      </div>
    </div>
    </div>
     </div>
  </div>
<Accordion/>
  <section class="bg-white  ">
    <div class="lg:w-5/6 px-8 py-20 mx-auto">
                <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl ">About  <span className="text-[#11a9b5]">Instructor </span> </h1>
                <div class="mt-2">
                  <span class="inline-block w-40 h-1 bg-[#11a9b5] rounded-full"></span>
                  <span class="inline-block w-3 h-1 ml-1 bg-[#11a9b5] rounded-full"></span>
                  <span class="inline-block w-1 h-1 ml-1 bg-[#11a9b5] rounded-full"></span>
                </div>
        <div class="xl:flex xl:items-center  gap-6 ">
        <div class="lg:w-1/2  ">
                <div>
                    <img class="object-cover rounded-xl w-1/2 aspect-square" src="https://online.sttar.in/wp-content/uploads/2023/03/Poonam-Kochitty-1.jpg" alt=""/>

                    <h1 class="mt-4 text-2xl font-semibold text-gray-700 capitalize :text-white">Poonam Kochitty
                    </h1>

                    <p class="mt-2 text-gray-500 capitalize :text-gray-300">School Principal, Educator, Teacher Trainer</p>
                </div>

            </div>
            <div class="xl:w-[80%] xl:mx-4">
                <div>

               
                <p class="max-w-3xl mt-4 text-gray-500 text-justify">
                Poonam Kochitty is an educator with 35 years of experience in school leadership roles as principal. She is also a teacher trainer for CBSE, trainer in classroom management, gender sensitivity, life skills, curriculum planning and implementation, innovative pedagogy. Besides being an exemplary school leader, she has expertise in conceptualizing and initiating new programmes, enhancing teachers’ capabilities, and leveraging technology for professional growth. Ms Kochitty is the recipient of Best Principal Award 2019 by Institute of Scholars, Bangalore. She won the Outstanding Leadership Principal Of The Year 2020 by Asian Education Awards.
                </p>
                </div>
            </div>

           
            
        </div>
        
    </div>
</section>
</section>
  )
}

export default Coursesdetail