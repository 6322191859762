import React from 'react'
import {Link} from 'react-router-dom'
function Thankyouverify() {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center p-4">
      {/* Card Container */}
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md text-center">
        {/* Icon */}
        <div className="mb-4">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='h-16 mx-auto'><path fill="#06d04a" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
        </div>

        {/* Heading */}
        <h1 className="text-2xl font-bold text-gray-800 mb-2">
          Thank You for Verifying Your Email!
        </h1>

        {/* Description */}
        <p className="text-gray-600 mb-6">
          Your email has been successfully verified. You can now <span  className='text-[#11a9b5] font-bold '>Login  </span> & access all the features of our platform.
        </p>

        {/* Button */}
        <button
          className="bg-[#0f3057] hover:bg-[#11a9b5] text-white font-semibold py-2 px-4 rounded-lg transition-colors duration-300 focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50"
         
        >
          <Link to='/'>
          
          Continue
          </Link>
        </button>
      </div>
    </div>
  )
}

export default Thankyouverify