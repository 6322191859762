import React, { useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import PieChartExample from "../components/Charts";

// Sample data for Pie Chart
const pieData = [
  { name: "Profile Viewed", value: 30 },
  { name: "Resume Downloaded", value: 20 },
  { name: "Remaining Applicants", value: 50 - 30 - 20 },
];

// Colors for Pie Chart slices
const COLORS = ["#0f3057", "#11a9b5", "#FFBB28"];

// Sample data for Bar Chart
const barData = [
  { name: "Total Applicants", value: 50 },
  { name: "Profile Viewed", value: 30 },
  { name: "Resume Downloaded", value: 20 },
];
const ChartsPage = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className="container mx-auto py-8">
      <div className="flex gap-4 mb-8 border-b-2 border-gray-500">
        <div className="flex  gap-4">
          <button
            className={`p-2 ${
              activeTab === "tab1"
                ? "border-[#11a9b5]  font-bold text-lg  text-[#11a9b5]"
                : "font-bold text-lg  text-gray-d00"
            }`}
            onClick={() => handleTabClick("tab1")}
          >
            Analysis
          </button>
          <button
            className={`p-2 ${
              activeTab === "tab2"
                ? " border-[#11a9b5] font-bold  text-lg  text-[#11a9b5]"
                : "font-bold text-lg text-gray-d00"
            }`}
            onClick={() => handleTabClick("tab2")}
          >
            Analysis Report
          </button>
        </div>
      </div>
      {activeTab === "tab1" && (
        <div className="flex flex-col  justify-between">
          {/* Pie Chart on the left */}

          <h2 className="text-center  text-xl font-bold mb-4">
            Candidate Report
          </h2>
          <div className="w-full flex-col sm:flex-row  mb-12 border-2 shadow-lg sm:p-4 flex">
            <div className="w-full   ">
              <h2 className="text-center text-lg font-semibold mb-4">
                Profile Viewed vs Resume Downloaded
              </h2>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    label
                  >
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>

            {/* Bar Chart on the right */}
            <div className="w-full mt-4 ">
              <h2 className="text-center text-lg font-semibold mb-4">
                Applicants Data Overview
              </h2>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={barData}
                  
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value" fill="#0f3057" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="mb-12 flex flex-col">
            <PieChartExample />
          </div>
        </div>
      )}
      {activeTab === "tab2" && (
        <div className="flex flex-wrap items-center w-full gap-8  justify-evenly">

          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <h2 className="border-b bg-gray-50 px-6  py-2 w-full ">RECENT PROFILES VIEWED</h2>
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
              <thead class="text-xs text-[#11a9b5] uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" class="px-6 py-3">
                     Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Job applied 
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-[#d9f1f3] :hover:bg-gray-600">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white"
                  >
                    Magic Keyboard
                  </th>
                  <td class="px-6 py-4">Black</td>
                </tr>
                <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-[#d9f1f3] :hover:bg-gray-600">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white"
                  >
                    Apple TV 4K
                  </th>
                  <td class="px-6 py-4">Black</td>
                </tr>
                <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-[#d9f1f3] :hover:bg-gray-600">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white"
                  >
                    AirTag
                  </th>
                  <td class="px-6 py-4">Silver</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <h2 className="border-b bg-gray-50 px-6  py-2 w-full ">RECENT APPLIED CANDIDATES</h2>
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
              <thead class="text-xs text-[#11a9b5] uppercase bg-gray-50 ">
              <tr>
                  <th scope="col" class="px-6 py-3">
                     Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Job applied 
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-[#d9f1f3] :hover:bg-gray-600">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white"
                  >
                    Magic Keyboard
                  </th>
                  <td class="px-6 py-4">Black</td>
                </tr>
                <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-[#d9f1f3] :hover:bg-gray-600">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white"
                  >
                    Apple TV 4K
                  </th>
                  <td class="px-6 py-4">Black</td>
                </tr>
                <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-[#d9f1f3] :hover:bg-gray-600">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white"
                  >
                    AirTag
                  </th>
                  <td class="px-6 py-4">Silver</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <h2 className="border-b bg-gray-50 px-6  py-2 w-full ">RECENT RESUME DOWNLOADS</h2>
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
              <thead class="text-xs text-[#11a9b5] uppercase bg-gray-50 ">
              <tr>
                  <th scope="col" class="px-6 py-3">
                     Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Job applied 
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-[#d9f1f3] :hover:bg-gray-600">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white"
                  >
                    Magic Keyboard
                  </th>
                  <td class="px-6 py-4">Black</td>
                </tr>
                <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-[#d9f1f3] :hover:bg-gray-600">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white"
                  >
                    Apple TV 4K
                  </th>
                  <td class="px-6 py-4">Black</td>
                </tr>
                <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-[#d9f1f3] :hover:bg-gray-600">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white"
                  >
                    AirTag
                  </th>
                  <td class="px-6 py-4">Silver</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <h2 className="border-b bg-gray-50 px-6  py-2 w-full ">RECENT EMAILS SENT</h2>
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
              <thead class="text-xs text-[#11a9b5] uppercase bg-gray-50 ">
              <tr>
                  <th scope="col" class="px-6 py-3">
                     Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Job applied 
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-[#d9f1f3] :hover:bg-gray-600">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white"
                  >
                    Magic Keyboard
                  </th>
                  <td class="px-6 py-4">Black</td>
                </tr>
                <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-[#d9f1f3] :hover:bg-gray-600">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white"
                  >
                    Apple TV 4K
                  </th>
                  <td class="px-6 py-4">Black</td>
                </tr>
                <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-[#d9f1f3] :hover:bg-gray-600">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white"
                  >
                    AirTag
                  </th>
                  <td class="px-6 py-4">Silver</td>
                </tr>
              </tbody>
            </table>
          </div>

        
        </div>
      )}
    </div>
  );
};

export default ChartsPage;
