import React from "react";
import { Link } from "react-router-dom";
const NoJobsFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-xl  bg-[#11a9b5] p-2">
      <div className="text-center mb-40">
      <iframe src="https://lottie.host/embed/464ca3d2-fc8c-4bea-a00d-97e484fd3599/UGjvYNKR0L.lottie" className="mx-auto mb-2" height={400} width={500}></iframe>
        <h2 className="text-2xl font-bold text-gray-800 mb-2">
          No Jobs Found
        </h2>
        <p className="text-gray-100 mb-2 px-20">
          We couldn't find any jobs matching your criteria. Try adjusting your
          filters or check back later.
        </p>
        <Link to='/'>
        <button
           // Example action
           className="px-6 py-2 text-white bg-blue-900 hover:bg-blue-700 font-medium rounded-lg shadow-md transition"
           >
          Back to Home
        </button>
            </Link>
      </div>
    </div>
  );
};

export default NoJobsFound;
