import React ,{useState} from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css'
import { useAuth } from "./Context";

function Loginmodal({ setteacherlogin, logo1 ,id}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [showpass, setshowpass] = useState(false);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate()
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true)
    const result = await login(email, password);
    const user = localStorage.getItem("userType");
    console.log(user)
    if ( user === '0') {
      setloading(false)
      console.log('ho gya')
      setSuccessMessage(result.message);
      if(id){
        navigate(`/candidatedashboard/jobs/apply/${id}`)
      } else{

        navigate('/candidatedashboard/jobs')
      }
      setteacherlogin(false)
    }
    else{
      setloading(false)
      setErrorMessage("Invalid Credentials")
    }
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center z-50 items-center">
    <div className="w-full max-w-sm mx-auto overflow-hidden bg-white z-50 rounded-lg shadow-md">
      <div className="px-6 py-4">
        <button
          onClick={() => setteacherlogin(false)}
          className="text-black relative left-[300px] rounded hover:bg-slate-100"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="flex justify-center mx-auto">
          <img className="h-6 sm:h-[50px]" src={logo1} alt="Logo" />
        </div>

        <h3 className="mt-3 text-xl font-medium text-center text-gray-600">
          Welcome to MySchoolNaukri
        </h3>

        <p className="mt-1 text-center text-gray-500">One step closer to hire</p>
        {errorMessage && (
           <div class="flex w-full px-4 py-1 max-w-sm border-red-400 overflow-hidden bg-gray-200 mb-2 rounded-lg  ">
           <div class="flex items-center justify-center w-12 bg-gray-300 rounded-full ">
           <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-red-400 fill-current" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>
           </div>
       
           <div class="px-4 -mx-3">
               <div class="mx-3">
                   <span class="font-semibold text-red-400">Something went wrong</span>
                   <p class="text-sm text-gray-600 da">{errorMessage}</p>
               </div>
           </div>
       </div>
        )}

        {successMessage && (
          <div class="flex w-full px-4 py-1 max-w-sm overflow-hidden bg-gray-200 mb-2 rounded-lg shadow-md ">
          <div class="flex items-center justify-center w-12 bg-emerald-500">
              <svg class="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z" />
              </svg>
          </div>
      
          <div class="px-4 -mx-3">
              <div class="mx-3">
                  <span class="font-semibold text-emerald-500">Success</span>
                  <p class="text-sm text-gray-600 da">{successMessage}</p>
              </div>
          </div>
      </div>
      
        )}
        <form onSubmit={handleSubmit}  className="space-y-6">
          <div className="w-full mt-4">
            <input
              className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border rounded-lg focus:border-[#11a9b5] focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-[#11a9b5]"
              type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
              placeholder="Email Address"
              aria-label="Email Address"
            />
          </div>

          <div className="relative flex items-center mt-2">
              <button type="button" onClick={()=>setshowpass(!showpass)} class="absolute right-0 focus:outline-none rtl:left-0 rtl:right-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 mx-4 text-gray-400 transition-colors duration-300 dark:text-gray-500 hover:text-gray-500 dark:hover:text-gray-400"
                >
                  <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                  <path
                    fill-rule="evenodd"
                    d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>

              <input
                type={showpass?"text":"password"}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="********"

                className="block w-full px-4 py-2  text-gray-700 placeholder-gray-500 bg-white border rounded-lg focus:border-[#11a9b5] focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-[#11a9b5]"
              />
            </div>

          <div className="w-full mt-2">
            <input className="px-4 py-2" type="checkbox" />
            <label htmlFor="horns" className="px-2 py-2">
              Keep me logged in
            </label>
          </div>

          <div className="flex items-center justify-around mt-4">
            <Link to="forgetpass" onClick={() => setteacherlogin(false)} className="text-sm text-gray-600 hover:text-gray-500">
              Forget Password?
            </Link>
          
            <button type="sumbit" disabled={loading} className="px-6 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#11a9b5] rounded-lg hover:bg-[#11a9b5] focus:outline-none focus:ring focus:ring-[#11a9b5] focus:ring-opacity-50">
            {loading ? (<div role="status" className=' '>
    <svg aria-hidden="true" class="w-6 h-6 text-gray-300 animate-spin  fill-blue-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>):"Login"} 
            </button>
            <p className="px-2 py-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#11a9b5] rounded-lg hover:bg-[#11a9b5] focus:outline-none focus:ring focus:ring-[#11a9b5] focus:ring-opacity-50">
              <Link onClick={() => setteacherlogin(false)} to="/otplogin"> OTP Login</Link>         
            </p>
          </div>
        </form>
      </div>

      <div className="flex items-center justify-center py-4 text-center bg-gray-50">
        <span className="text-sm text-gray-600">
          Don't have an account?{" "}
        </span>

        <Link
          to="/Candidate_onboarding1"
          onClick={() => setteacherlogin(false)}
          className="mx-2 text-sm font-bold text-[#11a9b5] hover:underline"
        >
          Sign Up
        </Link>
      </div>
     
    </div>
  </div>
  )
}

export default Loginmodal