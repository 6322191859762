import React ,{useEffect,useState} from 'react';
import { useAuth } from '../../Utils/Context';
import Jobcardc from '../components/Jobcardc';
import Candifilter from '../components/Candifilter';
import SkeletonCard from '../../dashboard/components/Skeleton';
import { useNavigate } from 'react-router-dom';
import Appliedfilter from '../components/Appliedfilter';

function Applied() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {api} = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token'); // Replace with your actual token

        const response = await api.get('/api/candidate/apply_job_list', {
          headers: {
            Authorization: `Bearer ${token}`, // Include the Bearer token
            'Content-Type': 'application/json',
          },
        });

        setData(response.data); // Set the response data to state
        setLoading(false); // Set loading to false once data is fetched
      } catch (err) {
        setError(err);
        setLoading(false); // Stop loading on error
      }
    };

    fetchData();
  }, []); // Empty dependency array to run only once when the component mounts
console.log(data)
  if (loading) {
    return <SkeletonCard/>;
  }

  if (error) {
    return <div className="text-center text-2xl text-red-500">
    Unable to Load ,Please try again later
  </div>
  }
 // Check if data is an array or handle it accordingly
 const jobsArray = Array.isArray(data) ? data : data?.jobs || [];

 if (!Array.isArray(jobsArray) || jobsArray.length === 0) {
   return <div>No applied jobs found.</div>;
 }

 const handleApplyFilters = (filters) => {
  // Assuming `filters` is an object like { designation: 'Engineer', experience: '3' }
  const queryParams = new URLSearchParams(filters).toString();
  navigate(`/candidatedashboard/jobs?${queryParams}`);
};


  return (
    <div>
     
      <div className="mb-4 mt-2  justify-center   sm:bg-slate-50 flex flex-wrap border-gray-600 ">
        <h2 className='text-2xl font-semibold mt-2'>Applied Jobs </h2>
        <div className="grid sm:grid-cols-3 sm:gap-20 mt-0 sm:mt-8  mb-4">
          <div className="col-span-1     mt-[0px] w-full sm:px-0 px-12 -ml-[40px] sm:ml:-[230px] sm:ml-0 sm:mt-0 sm:block hidden sm:z-0 z-30">
            <Appliedfilter onApplyFilters={handleApplyFilters} />
          </div>
          <div className="space-y-4 mx- sm:mx-0 sm:mt-0 mt-10 col-span-2">
            {jobsArray.map((job) => (
              <Jobcardc key={job.id}
                id={job.id}
                state_name={job.state_name}
                name={job.name}
                school_name={job.school_name}
                city_name={job.city_name}
                job_description={job.job_description}
                salarymax={job.salarymax}
                date={job.max_created_at}
                applicant={job.count}
              />
            ))}
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Applied