import React,{useEffect} from 'react'
import logo1 from '../assets/flogo1.jpeg'

function Emailverify() {
  // useEffect(() => {
  //   // Function to dynamically add the Google Ads conversion script
  //   const addGtagConversionScript = () => {
  //     const script = document.createElement("script");
  //     script.innerHTML = `
  //       gtag('event', 'conversion', {'send_to': 'AW-11322547281/X1beCLuHqdwYENG4gZcq'});
  //     `;
  //     document.body.appendChild(script);
  //   };

  //   addGtagConversionScript(); // Call function to add the script
  // }, []); 
  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'CompleteRegistration');
    }
  }, []);
  
  return (
    <section class="bg-white :bg-gray-900">
    <div class="container flex flex-col items-center justify-center py-20 px-6 mx-auto">
    <div className="flex justify-center mx-auto">
          <img className="h-6 sm:h-[50px]" src={logo1} alt="Logo" />
        </div>

        <h3 className="mt-3 text-xl font-medium text-center text-gray-600">
          Welcome to MySchoolNaukri
        </h3>

       

        <div className="  flex flex-col justify-center items-center p-4">
      {/* Card Container */}
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md text-center">
        {/* Icon */}
        <div className="mb-4">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='h-16 mx-auto'><path fill="#06d04a" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
        </div>

        {/* Heading */}
        <h1 className="sm:text-2xl text-lg font-bold text-gray-800 mb-2">
Registration Successfull!! <br /> Verify Your Email
</h1>

{/* Description */}
<p className="text-gray-600 sm:text-lg text-sm mb-6">
Verification Email has been sent. You can open your mail to <span  className='text-[#11a9b5] font-bold '>Verify  </span> your Email & access all the features of our platform.
</p>

        {/* Button */}
      
      </div>
    </div>
    </div>
</section>
  )
}

export default Emailverify