import React from 'react'

function Applymodal({close}) {
    const candidates =[
        {id:"234dg23353454",name:"kamakshi"},
        {id:"234dg23353454",name:"ramhesh"},
        {id:"234dg23353454",name:"gaytri"},
        {id:"234dg23353454",name:"kamak0i"},
        {id:"234dg23353454",name:"kamalesh"},


    ]
  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center z-50 items-center'>
        

<div class=" w-full max-w-xl mx-auto overflow-hidden  z-50 rounded-lg ">
    <div class="relative  w-full max-w-2xl max-h-full">
        <div class="relative bg-white rounded-lg shadow ">
            <div class="flex bg-[#affaff] items-center justify-between p-4 md:p-5 border-b rounded-t :border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 :text-white">
                    Candidates Applied to this job
                </h3>
                <button onClick={close} type="button" class="text-gray-800 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center :hover:bg-gray-600 :hover:text-white" data-modal-hide="default-modal">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <div class=" space-y-1 p-2">
                {candidates.map((candidate,index)=>(

                    <p key={candidate.id} class="text-base border px-3 py-1 rounded-lg leading-relaxed text-gray-800 hover:bg-slate-200">
                        {index+1}.)    {candidate.name}
                </p>
                ))}
                
            </div>
           
        </div>
    </div>
</div>

    </div>
  )
}

export default Applymodal