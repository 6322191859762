import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../Utils/Loading';
import user from '../../../src/assets/user.png'
import { Location } from '../components/Options';
import Videoresume from '../components/Videoresume';
import { updateCandidateStatus } from '../components/Function';
const Candiprofile = () => {
    const { id } = useParams(); // Get the candidate/job ID from the URL params
    const [candidate, setCandidate] = useState(null); // Store specific candidate data
    const [preferredLocation, setpreferredLocation] = useState([]); // Store specific candidate data
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [error, setError] = useState(null); // Error state
    const {options} = Location()
    const fetchJobDetails = async () => {
      setIsLoading(true);
      setError(null); // Reset error before fetching
      try {
        const response = await axios.post(`https://info.myschoolnaukri.com/api/candidates_list?candidateid=${id}`);
   
        // Ensure that the response data is an array and find the candidate by ID
        if (Array.isArray(response.data)) {
          // const job = response.data.find((job) => String(job.id) === String(id));
          const job = response.data // Convert both to strings for comparison
          setCandidate(job); // Set candidate to null if not found
        //   const preferrLocation = options.find((op)=>String(op.value) === String(candidate.state))
        //   setpreferredLocation(preferrLocation)
        } else {
          throw new Error("Invalid data format");
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
        setError("Failed to fetch candidate details. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
  
    const fetchlocation = () => {
        // Ensure both options and candidate are defined before using find
        if (Array.isArray(options) && candidate?.state) {
          const preferrLocation = options.find((op) => String(op.value) === String(candidate.state));
          setpreferredLocation(preferrLocation || null); // Set to null if not found
        } else {
          setpreferredLocation(null); // Handle case where candidate or options are undefined
        }
      };
      useEffect(() => {
        if (candidate) {
          fetchlocation(); // Fetch location only when candidate is available
        }
      }, [candidate, options]);
   

  
    useEffect(() => {
      fetchJobDetails();
     
    }, [id ]); 

     // Render loading, error, or candidate details
  if (isLoading) return  <Loading/>
  if (error) return <p>{error}</p>;
  if (!candidate) return <p>Candidate not found</p>;
  const handleButtonClick = async (candidateId,status) => {
  

    try {
      await updateCandidateStatus(candidateId, status);
     
    } catch (error) {
      // Show error toast
      throw error;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <div className="bg-white shadow">
        <div className="container mx-auto p-5">
          <div className="flex  items-center  justify-between">
            <div className=" flex flex-wrap justify-between px-4 w-full items-center">
                <div className=' flex  items-center  space-y-2 gap-x-4'>
              <img
                src={user}
                alt="Profile"
                className="sm:w-32 sm:h-32 w-20 h-20 hidden sm:block rounded-full border-4  border-gray-300"
              />
             <div className='space-y-2 '>

                
                <h1  className="text-2xl font-bold">{candidate[0].name}</h1>
                <p className="text-gray-800"><span className='font-medium text-[#11a9b5] '>Gender</span>  {(candidate[0].gender==="0" ? "male" :"female")}</p>
                <p className="text-gray-800"><span className='font-medium text-[#11a9b5] '> Location:</span>  {candidate[0]?.state_name  || 'not specified ' } , {candidate[0]?.city_name  || 'not specified ' }</p>
                <p className="text-gray-800"><span className='font-medium text-[#11a9b5] '>Preffred Job Location:</span> {preferredLocation?.label || 'Not specified'} </p>
             </div>
                </div>
                
                
            <div className=' flex  items-center sm:px-20 mb-4  gap-x-2'>
            <h2 className="text-xl text-[#11a9b5]  font-medium  ">Education: <span className="text-gray-800">{candidate[0].education? candidate[0].education : 'Information is not Provied by Candidate' }</span> </h2>
             
              
              
         
            </div>
               
            </div>
            <div>
               
            </div>
          </div>
        <div className='w-full flex justify-end gap-x-4 px-6 items-center '>

             
<button onClick={()=>handleButtonClick(id,2)} className=" px-4 py-2 bg-[#11a9b5] text-white hover:text-white font-bold  rounded-lg hover:bg-[#0f3057] ">
      Shortlist
    </button>
  <button onClick={()=>handleButtonClick(id,1)} className="px-4 py-2 bg-[#11a9b5] text-white  hover:text-white font-bold rounded-lg hover:bg-[#0f3057] ">
    Save Profile
  </button>
  </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container  mt-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
          {/* Left Sidebar */}
          <div className="sm:col-span-1 col-span-2">
        
            <div className="bg-white p-5 rounded-lg shadow">
            <div className='gap-y-2 mb-4 sm:mb-0 space-y-2'>

                
<Link className="text-gray-800 " to={`mailto:${candidate[0].email}`}><span className='font-medium text-[#11a9b5] '>Email: </span> {candidate[0].email}</Link>                

<p className="text-gray-800"><span className='font-medium text-[#11a9b5] '>Phone:</span> +91 {candidate[0].mobile}</p>
</div>
            </div>

            
            
            
          </div>

          {/* Right Sidebar - Experience and Education */}
          <div className="sm:col-span-1 col-span-2">
            {/* Experience */}
            <div className="bg-white p-5 rounded-lg shadow mb-5">
              <h2 className="text-xl text-[#11a9b5]  font-bold  mb-3">Experience</h2>
              <div className="mb-4">
               
                <p className="text-gray-500">{candidate[0].total_exp_yr? candidate[0].total_exp_yr :"0"} Yr {candidate[0].total_exp_mn? candidate[0].total_exp_mn :"0"} Mn </p>
               
              </div>
           
            </div>

         
          </div>
        </div>
      </div>
      <Videoresume videoLink={"sfff.com"} resumeLink ={candidate[0]?.resume}/>
   
    </div>
  );
};

export default Candiprofile;
