import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../Utils/Context";
import Loading from "../../Utils/Loading";
import { useForm } from "react-hook-form";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PackagePlan from "../components/Packageplan";

function Subcription() {
  const [show, setshow] = useState(true);
    const {api} = useAuth()
    const { register, handleSubmit } = useForm();
    const token = localStorage.getItem("token");
    console.log(token)
    const fetchpackages = async () => {
        const token = localStorage.getItem("token");
        try {
          const response = await api.get(
            '/api/admin/pricing_master',
            {}, // Post body, if required
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
      
          return response.data; // Return the data property from Axios response
        } catch (error) {
          console.error("Failed to fetch candidates:", error);
          throw new Error("Failed to fetch candidates");
        }
      };
      
  // Fetch data with React Query and pass page as a dependency
  const { data, isLoading, isError  , error} = useQuery({queryKey:["packages"], queryFn:() => fetchpackages(), 
    keepPreviousData: true, // Retain previous page data while loading new page
  });

  // Handle loading and error states
  if (isLoading) return null;
  if (isError) return <div className='text-center text-2xl text-red-500'>Error: {error.message}</div>;


  const onSubmit = async (data) => {
    const token = localStorage.getItem("token");
    try { // replace with actual token
      const response = await api.post(
        "/api/admin/save_packages", // replace with actual API endpoint
        {
          subscription_for: data.subscription_for === "1" ? 1:0,
          pack_plan: data.pack_plan,
          plan_price: parseInt(data.plan_price),
          valid_upto: parseInt(data.valid_upto, 10),
          total_no_of_jobs: parseInt(data.total_no_of_jobs, 10),
          total_cv_downloads: parseInt(data.total_cv_downloads, 10),
          data_access_validity: parseInt(data.data_access_validity, 10),
          email_contact_infor: data.email_contact_infor === "1" ? 1:0,
          in_built_ats: data.in_built_ats === "1" ? 1:0,
          tech_support_status: data.tech_support_status === "1" ? 1:0,
          disocunt: parseInt(data.disocunt),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Form submitted successfully!");
      console.log("Response:", response.data);
    } catch (error) {
      toast.error("Failed to submit form. Please try again.");
      console.error("Error:", error);
    }
  };
  return (
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg px-4 py-4 ">
      <h2 className="mb-4 font-bold text-xl px-2 ">Subcriptions Lists</h2>
      <button
        onClick={() => setshow(!show)}
        class="font-medium text-white bg-[#11a9b5] mb-4 px-3 py-1 rounded-lg "
      >
        {show ? "Add Subcription" : "Subcriptions"}{" "}
      </button>

      {show && (
        <div className="">
          <table class=" text-sm text-left  rtl:text-right text-gray-500 ">
            <thead class="text-xs text-gray-700 uppercase bg-gray-200 ">
              <tr>
                <th scope="col" class="p-1">
                  S.No.
                </th>
                <th scope="col" class="px-6 py-3">
                 Plan name
                </th>
                <th scope="col" class="px-6 py-3">
                 Plan Price
                </th>
                <th scope="col" class="px-6 py-3">
                  valid Upto 
                </th>
                <th scope="col" class="px-6 py-3">
                   Job Post Allowed
                </th>
                <th scope="col" class="px-6 py-3">
                   CV downloads
                </th>
                <th scope="col" class="px-6 py-3">
                  Data access Validity
                </th>
                <th scope="col" class="px-6 py-3">
                  Email & contact 
                </th>
                <th scope="col" class="px-6 py-3">
                   ATS
                </th>
                <th scope="col" class="px-6 py-3">
                  Tech support
                </th>
                <th scope="col" class="px-4 py-3">
                  Discount
                </th>
                <th scope="col" class="px-4 py-3">
                  Activity
                </th>
              </tr>
            </thead>
            <tbody>
             
              {data?.map((sub, index) => (
             <tr key={sub.id} className="bg-white border-b hover:bg-gray-200">
               <td className="p-4">{ index + 1}</td>
               <td className="px-6 py-4">{sub.subscription_plan}</td>
               <td className="px-6 py-4">{sub.subscription_price}</td>
               <td className="px-6 py-4">{sub.valid_upto}</td>
               <td className="px-6 py-4">{sub.total_job_post_no}</td>
               <td className="px-6 py-4">{sub.total_cv_downloads}</td>
               <td className="px-6 py-4">{sub.data_access_validity}</td>
               <td className="px-6 py-4">{sub.email_contact_infor?"Yes":"No"}</td>
               <td className="px-6 py-4">{sub.in_built_ats?"Yes":"No"}</td>
               <td className="px-6 py-4">{sub.tech_support_status?"Yes":"No"}</td>
               <td className="px-6 py-4">{sub.disocunt}</td>
               <td className="px-6 py-4"><PackagePlan id={sub.id} delete_status={sub.delete_status} /></td>
               
               {/* <td className="px-6 py-4">{sub.delete_status?<svg xmlns="http://www.w3.org/2000/svg" className="h-5" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>:<svg xmlns="http://www.w3.org/2000/svg" className="h-5" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"/></svg>}</td> */}
               {/* <td className="px-6 py-4 ">{sub.subscription_for}</td> */}
               
              {/* 1 disable and 0 mai enable */}
              
             </tr>
           ))}
             
            </tbody>
          </table>
         
        </div>
      )}
      {show === false && (
        <div className="flex-col bg-gray-200  space-y-6 mx-auto px-3 py-2 rounded-lg mt-2 flex-wrap ">
            <h2 className="mb-4 font-bold text-xl px-2 ">Create Packages</h2>
            <form onSubmit={handleSubmit(onSubmit)} className="grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <label className="block mb-2 text-sm font-medium">Select Subscription For</label>
          <select {...register("subscription_for")} className="bg-gray-50 border text-sm rounded-lg p-2.5 w-full">
            <option value="1">for Job posting</option>
            {/* <option value="Single school - 3 months validity">Single school - 3 months validity</option>
            <option value="Subscription Plan (Single School)">Subscription Plan (Single School)</option>
            <option value="Subscription Plan (Upto 5 School)">Subscription Plan (Upto 5 School)</option>
            <option value="Subscription Plan (More Than 5 School)">Subscription Plan (More Than 5 School)</option> */}
          </select>
        </div>
        
        <div>
          <label className="block mb-2 text-sm font-medium">Plan Name</label>
          <input type="text" {...register("pack_plan")} placeholder="Plan" required className="bg-gray-50 border text-sm rounded-lg p-2.5 w-full" />
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium">Plan Price</label>
          <input type="number" {...register("plan_price")} placeholder="Price" required className="bg-gray-50 border text-sm rounded-lg p-2.5 w-full" />
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium">Valid Upto (in months)</label>
          <input type="number" {...register("valid_upto")} placeholder="Valid Upto" required className="bg-gray-50 border text-sm rounded-lg p-2.5 w-full" />
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium">Total No. of Job Postings</label>
          <input type="number" {...register("total_no_of_jobs")} placeholder="Jobs Posting" required className="bg-gray-50 border text-sm rounded-lg p-2.5 w-full" />
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium">Total No. of CV Downloads</label>
          <input type="number" {...register("total_cv_downloads")} placeholder="CV Downloads" required className="bg-gray-50 border text-sm rounded-lg p-2.5 w-full" />
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium">Data Access Validity (in months)</label>
          <input type="number" {...register("data_access_validity")} placeholder="Data Access Validity" required className="bg-gray-50 border text-sm rounded-lg p-2.5 w-full" />
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium">Email & Contact Info</label>
          <select {...register("email_contact_infor")} className="bg-gray-50 border text-sm rounded-lg p-2.5 w-full">
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium">In-built ATS</label>
          <select {...register("in_built_ats")} className="bg-gray-50 border text-sm rounded-lg p-2.5 w-full">
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium">Tech Support</label>
          <select {...register("tech_support_status")} className="bg-gray-50 border text-sm rounded-lg p-2.5 w-full">
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium">Discount (%)</label>
          <input type="number" {...register("disocunt")} placeholder="Discount" required className="bg-gray-50 border text-sm rounded-lg p-2.5 w-full" />
        </div>
<div className="py-6 mx-auto ">

        <button type="submit" className="font-medium text-white bg-[#11a9b5] px-8 h-10  w-fit  rounded-lg">Submit</button>
</div>
      </form>

        </div>
      )}
    </div>
  );
}

export default Subcription;
