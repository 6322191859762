import React, { useState } from 'react'

import Select from "react-select";
import VirtualizedMenuList from "../../Utils/VirtualizedMenuList";
import {  Location, Specilization } from "../../dashboard/components/Options";
function Notifi() {
  const[show , setshow ] = useState(false)
  const { options, isLoading } = Location();
  const { options2, isLoading2 } = Specilization();
 
  return (
    <div class="relative py-4 overflow-x-auto shadow-md sm:rounded-xl">
    <h2 className='mb-4 font-bold text-xl px-2'> Notification  List</h2>
      <div class="pb-4 flex justify-between py-2 px-4 bg-white ">
          <button onClick={()=> setshow(!show)} class="font-medium text-white bg-[#11a9b5] px-3 py-1 rounded-lg ">{show? "Add Notitication" : "Notifications"  } </button>
          <div class="relative mt-1">
              <div class="absolute  inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg class="w-4 h-4 text-gray-500 :text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                  </svg>
              </div>
              <input type="text" id="table-search" class="block py-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-200 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500" placeholder="Search for Notification"/>
          </div>
      </div>
      {show && (

     
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 :text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-200 :bg-gray-700 :text-gray-400">
              <tr>
                  <th scope="col" class="p-4">
                      S.No.
                  </th>
                  <th scope="col" class="px-6 py-3">
                       name
                  </th>
                  <th scope="col" class="px-6 py-3">
                      Resume
                  </th>
                  <th scope="col" class="px-6 py-3">
                      Email
                  </th>
                 
                 
              </tr>
          </thead>
          <tbody>
          <tr class="bg-white :bg-gray-800 border-b  hover:bg-gray-200 :hover:bg-gray-600">
                  <td class="w-4 p-4">
                     6
                  </td>
                  <th scope="row" class="px-6 py-4 border-b font-medium text-gray-900 whitespace-nowrap :text-white">
                      Apple iMac 27"
                  </th>
                  <td class="px-6 py-4 border-b ">
                      Silver
                  </td>
                  <td class="px-6 py-4 border-b ">
                      PC Desktop
                  </td>
                  
               
              </tr>
              <tr class="bg-white :bg-gray-800 border-b  hover:bg-gray-200 :hover:bg-gray-600">
                  <td class="w-4 p-4">
                     6
                  </td>
                  <th scope="row" class="px-6 py-4 border-b font-medium text-gray-900 whitespace-nowrap :text-white">
                      Apple iMac 27"
                  </th>
                  <td class="px-6 py-4 border-b ">
                      Silver
                  </td>
                  <td class="px-6 py-4 border-b ">
                      PC Desktop
                  </td>
                  
               
              </tr> <tr class="bg-white :bg-gray-800 border-b  hover:bg-gray-200 :hover:bg-gray-600">
                  <td class="w-4 p-4">
                     6
                  </td>
                  <th scope="row" class="px-6 py-4 border-b font-medium text-gray-900 whitespace-nowrap :text-white">
                      Apple iMac 27"
                  </th>
                  <td class="px-6 py-4 border-b ">
                      Silver
                  </td>
                  <td class="px-6 py-4 border-b ">
                      PC Desktop
                  </td>
                  
               
              </tr> <tr class="bg-white :bg-gray-800 border-b  hover:bg-gray-200 :hover:bg-gray-600">
                  <td class="w-4 p-4">
                     6
                  </td>
                  <th scope="row" class="px-6 py-4 border-b font-medium text-gray-900 whitespace-nowrap :text-white">
                      Apple iMac 27"
                  </th>
                  <td class="px-6 py-4 border-b ">
                      Silver
                  </td>
                  <td class="px-6 py-4 border-b ">
                      PC Desktop
                  </td>
                  
               
              </tr> <tr class="bg-white :bg-gray-800 border-b  hover:bg-gray-200 :hover:bg-gray-600">
                  <td class="w-4 p-4">
                     6
                  </td>
                  <th scope="row" class="px-6 py-4 border-b font-medium text-gray-900 whitespace-nowrap :text-white">
                      Apple iMac 27"
                  </th>
                  <td class="px-6 py-4 border-b ">
                      Silver
                  </td>
                  <td class="px-6 py-4 border-b ">
                      PC Desktop
                  </td>
                  
               
              </tr>
          </tbody>
      </table>
       )}
      
      {show === false && (

     
       <div className='flex-col  space-y-6 mx-auto max-w-2xl mt-12 flex-wrap '>
      <div className="flex gap-8 justify-between">

     
<div className=' w-full'>
<label for="message" class="block mb-2 text-sm font-medium text-gray-900 :text-white">Set specific location</label>
<Select
           
           className="w-full"
           classNamePrefix="react-select"
           
          options={options}
          isLoading={isLoading}
          placeholder={" Location"}
          isClearable
          components={{ MenuList: VirtualizedMenuList }}
        />

</div>
<div className=' w-full'>
<label for="message" class="block mb-2 text-sm font-medium text-gray-900 :text-white">Set specific  Specialization</label>
<Select
           
           className="w-full"
           classNamePrefix="react-select"
           
          options={options2}
          isLoading={isLoading2}
          placeholder={" Specialization"}
          isClearable
          components={{ MenuList: VirtualizedMenuList }}
        />

</div>
</div>
<div>

<label class="block mb-2 text-sm font-medium text-gray-900 :text-white" for="file_input">Upload Notifcation logo</label>
<input class="block w-full py-3 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 :text-gray-400 focus:outline-none :bg-gray-700 :border-gray-600 :placeholder-gray-400" id="file_input" type="file"/>
      </div>
      
      <div>
            
<label for="message" class="block mb-2 text-sm font-medium text-gray-900 :text-white">Notification text</label>
<textarea id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500" placeholder="Write here..."></textarea>

        </div>

        <button  class="font-medium text-white bg-[#11a9b5] px-3 py-1 rounded-lg ">Genrate Notification </button>
       </div>
        )}
  </div>
  )
}

export default Notifi