import React from 'react'
import Coursescard from './Coursescard'
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Autoplay } from 'swiper/modules';
import Bannercourses from '../components/Bannercourses';
import Coursesbanner2 from '../components/Coursesbanner2';
import { Link } from 'react-router-dom';
function Courses() {
   
  return (
    
    <>
    
<section class="bg-gradient-to-b from-[#d9f1f3] from-10%  to-blue-50 ">
    <div class="px-4 mx-auto max-w-screen-xl text-center py-20 lg:py-20">
        <h1 class="mb-4 text-4xl font-bold tracking-tight leading-none text-blue-950 md:text-5xl lg:text-6xl">Start our <span className='text-[#11a9b5]'>E-learning </span>  Courses</h1>
        <p class="mb-8 text-lg font-normal text-gray-600 lg:text-xl sm:px-16 lg:px-48">Through our patnership with <span className='text-[#11a9b5]  '>STTAR </span> , we bring you  highly effective upskilling courses.  Always stay one step ahead in your career</p>
       
    </div>
</section>
<div class="px-4 mx-auto max-w-screen-xl  py-10 ">
<h1 class=" text-3xl font-bold tracking-tight leading-none text-blue-950 "><span className='text-[#11a9b5]'>Recommened </span>  Courses</h1>
<Link to='/courses/134234' class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center :bg-blue-600 :hover:bg-blue-700 :focus:ring-blue-800">View all</Link>


</div>
<Swiper
        slidesPerView={4}
        spaceBetween={0}
        navigation
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        loop={true}
        modules={[Navigation, Autoplay]}
         breakpoints={{
          320: { slidesPerView: 1, spaceBetween: 10 }, // On mobile, show 1 card
          640: { slidesPerView: 2, spaceBetween: 15 }, // On small screens, show 2 cards
          768: { slidesPerView: 3, spaceBetween: 20 }, // On tablets, show 3 cards
          1024: { slidesPerView: 4, spaceBetween: 20 }, // On desktop, show 4 cards
        }}
        className="w-full max-w-7xl mb-20"
      >
      

        {[...Array(8)].map((_, i) => (
            <div className='px-10'>
          <SwiperSlide key={i}>

           <Coursescard/>
          </SwiperSlide>
            </div>
        ))}
      </Swiper>

      <Bannercourses/>
       <Coursesbanner2/>
    </>

  )
}

export default Courses