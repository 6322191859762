import React from 'react'

function Terms() {
    const conditions = [
        "Violate any applicable local, provincial, state, national or international law, statute, ordinance, rule or regulation.",
        "Interfere with or disrupt computer networks connected to MySchoolNaukri.",
        "Impersonate any other person or entity, or make any misrepresentation as to your employment by or affiliation with any other person or entity.",
        "Forge headers or in any manner manipulate identifiers in order to disguise the origin of any user information.",
        "Interfere with or disrupt the use of MySchoolNaukri by any other user, nor 'stalk', threaten, or in any manner harass another user.",
        "Use MySchoolNaukri in such a manner as to gain unauthorized entry or access to the computer systems of others.",
        "Reproduce, copy, modify, sell, store, distribute or otherwise exploit for any commercial purposes MySchoolNaukri, or any component thereof (including, but not limited to any materials or information accessible through MySchoolNaukri).",
        "Use content from the Site for derivative works with a commercial motive without prior written consent of the ERJT.",
        "Use any device, software or routine to interfere or attempt to interfere with the proper working of MySchoolNaukri.",
        "Impose an unreasonable or disproportionately large load on MySchoolNaukri infrastructure.",
        "Spam MySchoolNaukri/ERJT by indiscriminately and repeatedly posting content or forwarding mail that may be considered spam.",
        "Access data not intended for you or log into server or account that you are not authorized to access.",
        "Constitute an act of reverse engineering, decompiling, disassembling, deciphering or otherwise attempting to derive the source code for the Site or any related technology or any part thereof.",
        "Engage in 'framing,' 'mirroring,' or otherwise simulating the appearance or function of the Site.",
        "Attempt to probe, scan or test the vulnerability of a system or network.",
        "Use automated means to crawl and/or scrape content from MySchoolNaukri and to manually scrape content from MySchoolNaukri.",
        "The Site uses technological means to exclude Robots etc. from crawling it and scraping content. You undertake not to circumvent these methods.",
        "Access the Site except through the interfaces expressly provided by ERJT.",
        "Attempt or breach security or authentication measures without proper authorization.",
        "Providing deeplinks into MySchoolNaukri without prior permission of ERJT is prohibited. Extracting data from MySchoolNaukri using any automated process such as spiders, crawlers etc. or through any manual process for a purpose which has not been authorised in writing.",
        "Upload, post, email, transmit or otherwise make available either directly or indirectly, any unsolicited bulk e-mail or unsolicited commercial e-mail.",
        "Subscribers shall under no circumstance sublicense, assign, or transfer the License, and any attempt at such sublicense, assignment or transfer is void."
      ];

      const dataProtectionPolicies = [
        "You will comply with all applicable data protection laws in relation to the processing of personal data; and not process personal data in an unlawful manner and excessive with regard to agreed purposes as defined in the privacy policy and these terms and conditions.",
        "You shall implement adequate technical and organizational controls to protect the shared personal data obtained from the Company against unauthorized or unlawful processing and against accidental loss, destruction, damage, alteration, or disclosure.",
        "The onus of any misuse of personal details accessed through your account lies on You. Access to services subscribed by You may be availed of and extended to authorized personnel only i.e., persons who are bound by employment agreements and confidentiality agreements.",
        "You agree to provide reasonable assistance as is necessary to facilitate the handling of any Data Security Breach (as relevant under privacy laws applicable) in an expeditious and compliant manner.",
        "You agree that the responsibility for complying with a data subject/data principal request lies with the Party which holds/processes the Personal Data collected/shared.",
        "You warrant and represent that the institution shall not disclose or transfer Personal Data obtained from the Company to any sub-processors without ensuring that adequate and equivalent safeguards to the Personal Data.",
        "You shall retain or process shared Personal Data for no longer than is necessary to carry out the agreed purposes.",
        "You shall act as an independent Data Controller in respect of shared personal data obtained from the Company once the data is collected by You and You shall be responsible for its secure use at all times."
      ];
    
      const resumeAccessPolicies = [
        "Single CV Access (1 CV quota) will get utilized for the following actions: Viewing a CV.",
        "Double CV Access (2 CV quota) will get utilized for the following actions: Downloading a CV in Excel Format.",
        "Repeated access (view/download/Click2View) of a CV, however, will not be counted again within a period of 10 days from the date of first access of a particular CV.",
        "CV Access quota is debited at account level, i.e., a CV accessed by two or more sub-users under the same account will be counted only once.",
        "This also includes different profiles under one resume, i.e., if different profiles under the same user name are accessed, it will still be counted as 1 access (for 90 days from the date of first access).",
        "In case 1 CV Access quota has been utilized for a CV (due to CV View/Click2View), then only 1 additional CV Access quota will be utilized if the user downloads CV within a period of 10 days from the date of first access of a particular CV.",
        "Using the send email feature, a subscriber/recruiter can send a job intimation to the jobseekers by consuming email quota."
      ];

      const spamPolicies = [
        "Unsolicited Bulk Messages/Unsolicited Commercial Communications.",
        "Voice Calls/SMS to telephone numbers registered on the National Consumer Preference Register.",
        "Non Job related mails.",
        "Messages with misleading subject lines.",
        "Blank Messages.",
        "Extraordinary High Number of mails.",
        "Mails soliciting payments.",
        "Misleading/Fraudulent mails."
      ];
  return (
    <div className="container mx-auto px-4 py-10">
      <h1 className="text-4xl font-bold text-center mb-10">Terms & Conditions</h1>
      <div className="prose mx-auto max-w-4xl text-justify">
        <h2 className="text-2xl font-semibold mb-4">Purpose</h2>
        <p>
          MySchoolNaukri is intended only to serve as a preliminary medium of contact and exchange of information for its users/members/visitors who have a bona fide intention to contact and/or be contacted for the purposes related to genuine existing job vacancies and for other career enhancement services.
        </p>

        <h2 className="text-2xl font-semibold mt-8  mb-4">Use to be in Conformity with the Purpose</h2>
        <p>
          MySchoolNaukri (and related products) or services that are subscribed to or used are meant for the purpose and only for the exclusive use of the subscriber/registered user. Copying, downloading, recreating, or sharing passwords or sublicensing in any manner not in accordance with these terms is a misuse of the platform.or service or product and ERJT reserves its rights to act in such manner as to protect its loss of revenue or reputation or claim damages including stopping your service or access and reporting to relevant authorities. In the event you are found to be copying or misusing or transmitting or crawling any data or photographs or graphics or any information available on MySchoolNaukri for any purpose other than that being a bonafide Purpose, we reserve the right to take such action that we deem fit including stopping access and claiming damages
        </p>

        <p>
        The site is a public site with free access and ERJ Technologies (OPC) Private Limited assumes no liability for the quality and genuineness of responses. ERJ Technologies (OPC) Private Limited cannot monitor the responses that a person may receive in response to information he/she has displayed on the site. The individual/company would have to conduct its own background checks on the bonafide nature of all response(s).
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Data Usage and Information Sharing</h2>
        <p>
          You give us permission to use the information about actions that you have taken on MySchoolNaukri in connection with ads, offers, and other content (whether sponsored or not) that we display across our services, without any compensation to you. <br /> The platform may contain links to third party websites, these links are provided solely as convenience to You and the presence of these links should not under any circumstances be considered as an endorsement of the contents of the same, if You chose to access these websites you do so at your own risk.
        </p>

        <p>
          We use data and information about you to make relevant suggestions and recommendations to you and others. ERJT does not share personally identifiable data without permission, except in response to legal processes.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Obligations of the User</h2>
        <p>
        Whilst using this platform an obligation is cast upon you to only provide true and correct information and in the case of creating a profile you undertake to at all times keep the information up to date. ERJ Technologies (OPC) Private Limited will not be liable on account of any inaccuracy of information on this web site. It is the responsibility of the visitor to further research the information on the site. Any breach of privacy or of the information provided by the consumer to ERJ Technologies (OPC) Private Limited to be placed on the website by technical or any other means is not the responsibility of ERJ Technologies (OPC) Private Limited ERJ Technologies (OPC) Private Limited does not guarantee confidentiality of information provided to it by any person acquiring/using all/any information displayed on the MySchoolNaukri website or any of its other websites / domains owned and operated by ERJ Technologies (OPC) Private Limited
        </p>

        <p>ERJ Technologies (OPC) Private Limited does not share personally identifiable data of any individual with other companies / entities without obtaining permission except with those acting as our agents.. ERJ Technologies (OPC) Private Limited shall share all such information that it has in its possession in response to legal process, such as a court order or subpoena. The user shall not utilize the services offered by MySchoolNaukri/ERJT in any manner so as to impair the interests and functioning of MySchoolNaukri/ERJT. The user undertakes not to duplicate, download publish, modify and distribute material on MySchoolNaukri unless specifically authorized by ERJT in this regard.</p>
 
        <p>The user undertakes to use MySchoolNaukri for his/her own purposes. Using content from MySchoolNaukri for derivative works with a commercial motive without prior written consent from ERJT is strictly prohibited.</p>

        <p>
        Users undertake that the services offered by MySchoolNaukri/ ERJT shall not be utilized to upload, post, email, transmit or otherwise make available either directly or indirectly, any unsolicited bulk e-mail or unsolicited commercial e-mail. ERJT reserves the right to filter and monitor and block the emails sent by you/user using the servers maintained by ERJT to relay emails. All attempts shall be made by ERJT and the user to abide by International Best Practices in containing and eliminating Spam.
        </p>

        <p>Users shall not spam the platform maintained by MySchoolNaukri / ERJT or indiscriminately and repeatedly post jobs/forward mail indiscriminately etc. Any conduct of the user in violation of this clause shall entitle ERJT to forthwith terminate all services to the user without notice and to forfeit any amounts paid by him.</p>

        <p>The user shall not upload, post, transmit, publish, or distribute any material or information that is unlawful, or which may potentially be perceived as being harmful, threatening, abusive, harassing, defamatory, libelous, vulgar, obscene, or racially, ethnically, or otherwise objectionable.</p>

        <p>The user expressly states that the resume/insertion or information/ data being fed into the network of ERJ Technologies (OPC) Private Limited by the user is correct and complete in all respects and does not contain any false, distorted, manipulated, fraudulent or misleading facts or averments. ERJ Technologies (OPC) Private Limited expressly disclaims any liability arising out of the said resume insertion/information/ data so fed into the network of ERJ Technologies (OPC) Private Limited by the user. Further, the user agrees to indemnify ERJ Technologies (OPC) Private Limited for all losses incurred by ERJ Technologies (OPC) Private Limited due to any false, distorted, manipulated, defamatory, libelous, vulgar, obscene, fraudulent or misleading facts or otherwise objectionable averments made by the user on the network of ERJ Technologies (OPC) Private Limited.</p>

        <p>The User is solely responsible for maintaining confidentiality of the User password and user identification and all activities and transmission performed by the User through his user identification and shall be solely responsible for carrying out any online or off-line transaction involving credit cards / debit cards or such other forms of instruments or documents for making such transactions and ERJ Technologies (OPC) Private Limited assumes no responsibility or liability for their improper use of information relating to such usage of credit cards / debit cards used by the subscriber online / off-line.</p>

        
        <h2 className="text-2xl font-semibold mt-8 mb-4">Governing Law</h2>
        <p>
        The User/Subscriber/Visitor to MySchoolNaukri and/or its affiliated websites does hereby specifically agree that he/she shall, at all times, comply with the requirements of the Information Technology Act, 2000 as also rules, regulations, guidelines, bye laws and notifications made thereunder, while assessing or feeding any resume/ insertion or information/data into the computers, computer systems or computer network of ERJ Technologies (OPC) Private Limited. The said User/ subscriber/ visitor to MySchoolNaukri and/or its affiliated websites does further unequivocally declare that in case he violates any provisions of the Information Technology Act, 2000 and/or rules, regulations, guidelines, byelaws and notifications made thereunder, he shall alone be responsible for all his acts, deeds and things and that he alone shall be liable for civil and criminal liability there under or under any other law for the time being in force.
        </p>    

        <p>The User is solely responsible for obtaining, at his own cost, all licenses, permits, consents, approvals and intellectual property or other rights as may be required by the user for using the Service</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Prohibited Activities</h2>
        <ul className="list-decimal ml-8 space-y-2">
        {conditions.map((condition, index) => (
          <li key={index} className="text-md text-justify">
            {condition}
          </li>
        ))}
      </ul>
      <p>1. For which you do not have all necessary rights and licenses;  <br/>
2. Which infringes, violates, breaches or otherwise contravenes the rights of any third party, including any copyright, trademark, patent, rights of privacy or publicity or any other proprietary right; <br/>
3. Which contains a computer virus, or other code, files or programs intending in any manner to disrupt or interfere with the functioning of MySchoolNaukri, or that of other computer systems; <br/>
4. That is grossly harmful, harassing, invasive of another's privacy, hateful, disparaging, relating to money laundering or unlawful, or which may potentially be perceived as being harmful, threatening, abusive, harassing, defamatory, libelous/blasphemous, vulgar, obscene, or racially, ethnically, or otherwise unlawful in any manner whatsoever; <br/>
5. Which constitutes or encourages conduct that would constitute a criminal offence, give rise to other liability, or otherwise violate applicable law; <br/>
6.That deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature; <br/>
7. That belongs to another person and to which the user does not have any right to; 8. That harm minors in any way;  <br/>
8. That threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting any other nation. <br/></p>

<h2 className="text-2xl font-semibold mt-8 mb-4">Intellectual Property</h2>

<p>The user shall not infringe on any intellectual property rights of any person / entity or retain information / download any information from any computer system or otherwise with an intention to do so.</p>

<p>ERJ Technologies (OPC) Private Limited will make best efforts to do so but does not warrant that any of the web sites or any affiliate site(s) or network system linked to it is free of any operational errors nor does it warrant that it will be free of any virus, computer contaminant, worm, or other harmful components. The subscription of a user shall be subject to Quotas as applicable and as advised. E-Mails provided as part of contact details are expected to be genuine and access to such email accounts is available to authorised personnel only.</p>

<p>ERJ Technologies (OPC) Private Limited shall not be liable for any loss or damage sustained by reason of any disclosure (inadvertent or otherwise) of any information concerning the user's account and / or information relating to or regarding online transactions using credit cards / debit cards and / or their verification process and particulars nor for any error, omission or inaccuracy with respect to any information so disclosed and used whether or not in pursuance of a legal process or otherwise.</p>

<p>Payments for the services offered by MySchoolNaukri shall be on a 100% advance basis. Refund if any will be at the sole discretion of ERJ Technologies (OPC) Private Limited. ERJT offers no guarantees whatsoever for the accuracy or timeliness of the refunds reaching the Customers card/bank accounts. ERJT gives no guarantees of server uptime or applications working properly. All is on a best effort basis and liability is limited to refund of amount only. ERJT undertakes no liability for free services. ERJT reserves its right to amend / alter or change all or any disclaimers or terms of agreements at any time without any prior notice. All terms / disclaimers whether specifically mentioned or not shall be deemed to be included if any reference is made to them</p>

<p>Unless otherwise specified and notwithstanding anything contained in any other agreement or arrangement, by whatever name called, the performance obligation of ERJT (service provider) is to provide access of its on-line portal to the customer for the duration of the subscription period & reference to any usage, by whatever name called or any other performance obligation, if any, is to provide the upper limit for consumption, which by itself, does not create any additional performance obligation upon ERJT</p>

<p>Subscriber/user acknowledges and agrees that ERJT/MySchoolNaukri, at its sole discretion and without prejudice to other rights and remedies that it may have under the applicable laws, shall be entitled to set off the amount paid or payable by a subscriber/user against any amount(s) payable by Subscriber/user to ERJT under any other agreement or commercial relationship towards other products/services</p>

<p>ERJ Technologies (OPC) Private Limited further reserves its right to post the data on the website MySchoolNaukri or on such other affiliated sites and publications as ERJ Technologies (OPC) Private Limited may deem fit and proper at no extra cost to the subscriber / user.</p>

<p>The subscription / agreement between ERJT and the subscriber / user is not a "non-poach agreement" nor can the same be termed or used as an alternative to "non-poach agreement" in as much as ERJT / MySchoolNaukri is a public site and all information posted by ERJT goes to the public domain except information / data which is specifically assigned a non-public / private character.</p>

<p>Any agreement for a subscription / usage entered into by ERJT does not confer exclusivity of service on any subscriber / user. <br/>
ERJ Technologies (OPC) Private Limited (India) Ltd will not be party to any legal proceedings between a user (e.g. a subscriber) and a party contracted through the site. In case ERJ Technologies (OPC) Private Limited is implicated in any legal proceedings, costs will be recovered from the party that names ERJ Technologies (OPC) Private Limited ERJ Technologies (OPC) Private Limited however will abide with any court order served on it through due process. ERJT controls and operates this Platform from its headquarters in Noida and makes no representation that the materials on MySchoolNaukri are appropriate or available for use in other locations. If you use this Website from other locations, you are responsible for compliance with applicable local laws including but not limited to the export and import regulations of other countries. </p>

<p>In case a person using the world wide web/internet receives a spam or virus which includes a link to MySchoolNaukri or to any other site maintained, operated or owned by ERJT, it should not be held responsible for the same. ERJT assumes no responsibility for such mails.</p>

<p>The services provided by the websites maintained, operated or owned by ERJT do not extend to acting as an agent (express or implied) on behalf of any subscriber or user.
ERJT has no agents and does not operate through any agents save for those specifically mentioned on the home page of the website.
The Terms and conditions mentioned above regulate the usage of MySchoolNaukri. Any person using MySchoolNaukri in violation of the stipulations contained in the Terms and Conditions of MySchoolNaukri shall render himself/herself liable to appropriate action in a court of law both civil and criminal</p> <br/>

<p className='font-medium text-sm'>BY ACCEPTING THESE TERMS AND CONDITIONS, YOU AGREE TO INDEMNIFY AND OTHERWISE HOLD HARMLESS ERJT, ITS DIRECTORS, OFFICERS, EMPLOYERS, AGENTS, SUBSIDIARIES, AFFILIATES AND OTHER PARTNERS FROM ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF, RELATING TO, OR RESULTING FROM YOUR USE OF THE SERVICES OBTAINED THROUGH MySchoolNaukri INCLUDING BUT NOT LIMITED TO INFORMATION PROVIDED BY YOU OR ANY OTHER MATTER RELATING TO MySchoolNaukri. ANY REFERENCE TO DUTIES AND TAXES ETC IN THESE TERMS OF USE SHALL INCLUDE GOODS AND SERVICES TAX (HEREIN REFERRED AS GST) FROM THE DATE GST LAW IS IMPLEMENTED IN INDIA. ANY ADDITIONAL TAX LIABILITY ARISING ON ACCOUNT OF INTRODUCTION OF GST (WHETHER ON ACCOUNT OF INCREASE IN RATE OR ANY CHANGE BROUGHT IN BY THE NEW TAX REGIME) WOULD BE RECOVERED OVER AND ABOVE THE AGREED CONTRACT PRICE / SERVICE FEE.</p> <br/>
        
        <p>
        If any dispute arises between a user/users and ERJT arising out of use of MySchoolNaukri or thereafter, in connection with the validity, interpretation, implementation or alleged breach of any provision of these Terms and Conditions, the dispute shall be referred to a sole arbitrator who shall be an independent and neutral third party identified by the Company. Decision of the arbitrator shall be final and binding on both the parties to the dispute. The place of arbitration shall be New Delhi, India. The Arbitration & Conciliation Act, 1996 as amended, shall govern the arbitration proceedings
        </p> <br/>

        <p>
        These Terms and Conditions shall be governed by the laws of Republic of India. The exclusive forum for any disputes arising out of or relating to these Terms of Use shall be a court of law located in New Delhi, India..
        </p>

        
        <p>
        ERJ Technologies (OPC) Private Limited, ERJ Technologies (OPC) Private Limited India, ERJT and ERJ Technologies (OPC) Private Limited are used interchangeably and are synonymous. 
        </p> <br/>

        <p>
        The user of MySchoolNaukri is subject to the Privacy Policy available through this link
        </p>

        <p>In case of non compliance of these Terms and Conditions or Privacy Policy ERJT may terminate usage rights and take down any offending information that might might have been upoloaded by such subscriber/user
        </p>
        <h2 className="text-2xl font-semibold mt-8 mb-4">ADDITIONALLY, BASIS SERVICES CHOSEN, CERTAIN PRODUCT/SERVICE SPECIFIC CONDITIONS MAY APPLY.</h2>
        <h2 className="text-xl font-semibold mt-8">job seekers.</h2>
        <p>1. Training Sessions <br />
2. Teaching Resources <br />
3. Counselling <br /></p>
        <h2 className="text-xl font-semibold mt-8">Recruiters</h2>
        <p>1. Classified Job listing (single) <br />
        2. Resume Downloads Subscriptions for various period <br />
        </p>
        
        <h2 className="text-xl font-semibold mt-8 mb-4">Recruiter</h2>
        <p>Additional Terms applicable to Recruiters "You" accessing any portion of the website MySchoolNaukri:</p> <br/>
        <h2 className="text-2xl font-bold mb-4">Data Protection Policies</h2>
      <ul className="list-disc ml-8 space-y-2">
        {dataProtectionPolicies.map((policy, index) => (
          <li key={index} className="text-md">
            {policy}
          </li>
        ))}
      </ul>

      <h2 className="text-2xl font-bold mt-6 mb-4">Resume Download/CV Access Policies</h2>
      <ul className="list-disc ml-8 space-y-2">
        {resumeAccessPolicies.map((policy, index) => (
          <li key={index} className="text-md">
            {policy}
          </li>
        ))}
      </ul>


        <h2 className="text-2xl font-semibold mt-8 mb-4">ANTI SPAM POLICY</h2>
        <p>The use and access to Resume Download database is subject to this policy. The services provided to you are aimed at providing recruitment solutions and should be restricted to contacting suitable candidates for genuine jobs in existence.</p>

        <p>Mailing practices such as transmitting marketing and promotional mailers/ Offensive messages/ messages with misleading subject lines in order to intentionally obfuscate the original message, are strictly prohibited.</p>

        <p>We reserve the right to terminate services, without prior notice, to the originator of Spam. No refund shall be admissible under such circumstances.</p> <br/>

        <p>Following is an illustrative (not exhaustive) list of the kinds of messages which can be classified as spam:</p> <br/>
        <ul className="list-disc ml-8 space-y-2 mb-4">
        {spamPolicies.map((policy, index) => (
          <li key={index} className="text-md">
            {policy}
          </li>
        ))}
      </ul>
      <p> Users agree to indemnify and hold harmless ERJ Technologies (OPC) Private Limited (India) Limited from any damages or claims arising out of usage of their Resume Download accounts for transmitting spam.</p> <br/>
      <p className='font-medium mb-2'>Users are advised to change their passwords frequently in order to reduce the possibility of misuse of their accounts To seek more information and to report Spam. Please mail us at: support@MySchoolNaukri.com</p>
    
      <p>Note: The terms in this agreement may be changed by ERJ Technologies (OPC) Private Limited at any time. ERJ Technologies (OPC) Private Limited is free to offer its services to any client/prospective client without restriction.</p>
      </div>
    </div>
  )
}

export default Terms