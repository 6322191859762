// Import React, Swiper components and Swiper styles
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper core and required modules
import {  Navigation ,Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const testimonials = [
  {
    name: "Mr. Tajesh Kumar Turkar",
    designation: "Principal, Mount Litera Zee School, Balaghat",
    testimonial: "The team is extremely humble and supportive & are actually passionate to help educators.",
    image: "frontimage/testimonial1.jpeg"
  },
  {
     name: "Mr. S Sunil Kumar",
     designation: "Principal, KABD School, Painkulam, Kanyakumari",
     testimonial: "It's quick, it's efficient and it's free for job seeker.",
     image: "frontimage/testimonial2.jpeg"
  },
   {
     name: "Mrs. Bidisha Chatterjee",
     designation: "Principal, Trust Field School, Murshidabad",
     testimonial: "MySchoolNaukri.com has a huge network of schools and has a lot of jobs to choose from.",
     image: "frontimage/testimonial3.jpeg"
  },
  {
     name: "Mrs. Priyanka Jain",
     designation: "Head Mistress, Podar World School, Mumbai",
     testimonial: "It is a much-needed startup. Teachers all over the country struggle to find good opportunities around them. I think MySchoolNaukri is a great initiative to help teachers.",
     imag : "frontimage/testimonial4.jpeg"
  }
];

const Testimonial = () => {
  return (
    <div className="container mx-auto px-4 mb-14 py-10">
      <h2 className="text-3xl font-bold text-center mb-8">What People  <span className='text-[#11a9b5]'>Say </span></h2>
      
      {/* Swiper Component */}
      <Swiper
        
        navigation
        modules={[ Navigation,Autoplay]}
        className="mySwiper"
        spaceBetween={10}
        slidesPerView={1}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        loop={true}
        grabCursor={true}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index} className='px-2'>
          
          
      <div class="p-4 md:w-1/2 mx-auto w-full transition-all duration-500 ease-in-out transform hover:scale-105 ">
        <div class="h-full bg-[#d9f1f3] shadow-xl p-8 rounded ">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="block w-5 h-5 text-gray-400 mb-4" viewBox="0 0 975.036 975.036">
            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
          </svg>
          <p class="leading-relaxed mb-6 text-md text-justify">{testimonial.testimonial}</p>
          <p class="inline-flex items-center">
            <img alt="testimonial" src="https://dummyimage.com/106x106" class="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"/>
            <span class="flex-grow flex flex-col pl-4">
              <span class="title-font font-semibold text-gray-900">{testimonial.name}</span>
              <span class="text-gray-500 text-sm">{testimonial.designation}</span>
            </span>
          </p>
        </div>
      </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Testimonial;
