import React from 'react'
import backgroundImage from '../assets/header.jpeg'

function Header({title,highlight,subtitile}) {
  return (
    <div
        class="w-full sm:bg-center  bg-cover h-[12rem] "
        style={{
            backgroundImage: `url(${backgroundImage})`, // Use the imported image
          }}
      >
        <div class="flex items-center justify-center w-full h-full bg-gray-900/60">
          <div class="flex flex-wrap w-full mb-4 sm:mb-10 flex-col items-center text-center">
            <h1 class="sm:text-3xl text-2xl font-bold title-font mb-2 text-white">
              {title} <span className="text-[#11a9b5]">{highlight}</span>{" "}
            </h1>
            <p class="lg:w-1/2 w-full leading-relaxed text-white">
              {subtitile}
            </p>
          </div>
        </div>
      </div>
  )
}

export default Header