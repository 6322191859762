import React, { useState } from "react";
import { Designation, StateLocation } from "../dashboard/components/Options";
import { Link } from "react-router-dom";
import Loading from '../Utils/Loading'
const JobSearchPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("State");
  const [searchTerm, setSearchTerm] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const categories = ["State", "Designation"];
  const { options3 ,isLoading3 } = Designation();
  const { stateOptions,isLoading4 } = StateLocation();

  const jobs = {
    "State": stateOptions,
    "Designation":  options3,
  };

  
  const filteredJobs = jobs[selectedCategory].filter((job) =>
    job.label.toLowerCase().includes(searchTerm)
  );

  return (
    <div className="flex min-h-screen">
      {/* Mobile Sidebar Toggle */}
      {/* <button
        className="sm:hidden fixed top-30 right-0 bg-blue-950 text-white px-3 py-2 rounded shadow-lg z-10"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        {isSidebarOpen ? "Close Menu" : "Open Menu"}
      </button> */}

      {/* Sidebar */}
      <div
        className={`${
          isSidebarOpen ? "block transition-transform" : " hidden"
        } sm:block fixed sm:static top-20 left-0 h-full sm:h-auto w-64  bg-gray-100 p-4 shadow-md z-10`}
      >
        <h2 className="font-bold text-lg mb-4">Job Categories</h2>
        <ul>
          {categories.map((category) => (
            <li
              key={category}
              className={`cursor-pointer py-2 px-4 mb-2 rounded ${
                selectedCategory === category
                  ? "bg-blue-950 text-white"
                  : "hover:bg-gray-200"
              }`}
              onClick={() => {
                setSelectedCategory(category);
                setIsSidebarOpen(false); // Close sidebar on mobile
              }}
            >
              {category}
            </li>
          ))}
        </ul>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-6 ml-0 sm:ml-20">
        <div className="mb-8">
          <div className="relative w-full shadow-lg rounded-xl  ">
        <span class="absolute inset-y-0 left-0 flex items-center pl-3">
          <svg class="w-5 h-5 text-gray-900" viewBox="0 0 24 24" fill="none">
            <path
              d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </span>
          <input
            type="text"
            placeholder={`Search for ${selectedCategory}s`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full bg-slate-100 ps-12 font-semibold text-blue-900 p-4 border rounded-xl shadow-lg "
          />
                    </div>

               <ul className="flex sm:hidden  mt-4 gap-2 ">
          {categories.map((category) => (
            <li
              key={category}
              className={`cursor-pointer  py-2 px-4 mb-2 rounded-xl ${
                selectedCategory === category
                  ? "bg-blue-950 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => {
                setSelectedCategory(category);
                setIsSidebarOpen(false); // Close sidebar on mobile
              }}
            >
              {category}
            </li>
          ))}
        </ul>
        </div>
        <h2 className="font-bold text-xl mb-4"> Jobs by {selectedCategory}</h2>
         { filteredJobs.length === 0 && (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {[...Array(6)].map((_, index) => (
            <div
              key={index}
              className="border p-3 rounded shadow animate-pulse"
            >
              <div className="h-4 bg-gray-300 rounded w-3/4 mb-4"></div> {/* Placeholder for job label */}
              <div className="h-2 bg-gray-300 rounded w-1/2"></div> {/* Placeholder for link */}
            </div>
          ))}
        </div>
         )} 

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredJobs.map((job) => (
            <Link  to={`/search?query=${encodeURIComponent(job.label)}`} className="block text-black  ">
            <div
              key={job.value}
              className="border cursor-pointer p-4  hover:bg-[#85d1d6] hover:text-white rounded shadow hover:shadow-lg hover:scale-105 transition-shadow smooth"
            >
               <p className="text-black  transition-colors">  

                {job.label} 
               </p>
                        </div>
              </Link>  
          ))}
        </div>
      </div>
    </div>
  );
};

export default JobSearchPage;
