import React from "react";
import { useNavigate } from "react-router-dom";

function UnauthorizedPage() {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-md p-8 bg-white rounded-lg shadow-lg text-center">
        <h1 className="text-4xl font-bold text-red-500 mb-4">Unauthorized</h1>
        <p className="text-gray-700 text-lg mb-6">
          Sorry, you don’t have permission to view this page.
        </p>
        <button
          onClick={() => navigate("/")}
          className="px-6 py-2 bg-blue-900 text-white font-semibold rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transition duration-150"
        >
          Go Back to Home
        </button>
      </div>
    </div>
  );
}

export default UnauthorizedPage;
