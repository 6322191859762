import React, { useState } from "react";
import Select from "react-select";
import {
  Location,
  Specilization,
  Designation,
} from "../../dashboard/components/Options";
import VirtualizedMenuList from "../../Utils/VirtualizedMenuList";
function Demovideo() {
  const [step, setStep] = useState(1); // Current step
  const [employmentStatus, setEmploymentStatus] = useState("");
  const { options } = Location();
  const { options2 } = Specilization();
  const { options3 } = Designation();
  const handleSelectChange = (event) => {
  
    setEmploymentStatus(event.target.value);
   
  };
  return (
    <div className="max-w-4xl mt-20 mx-auto  bg-white p-8 rounded-lg shadow-lg">
      <h2 className="text-xl text-center font-semibold text-[#0f3057] capatalize mb-6">
        Please Complete Your{" "}
        <span className="text-[#11a9b5] font-semibold"> Profile.</span> in just
        3 steps.
      </h2>

      <div className="flex justify-between items-center space-x-4 mb-6">
        <span
          className={`border-2 px-2 py-0 ${
            step >= 1 ? "bg-[#10aab4] text-white" : "bg-gray-300"
          } rounded-full`}
        >
          1
        </span>
        <div
          className={`w-1/3 h-2 ${
            step >= 1 ? "bg-[#0f3057]" : "bg-gray-300"
          } rounded-lg`}
        ></div>
        <span
          className={`border-2 px-2 py-0 ${
            step >= 2 ? "bg-[#10aab4] text-white" : "bg-gray-300"
          } rounded-full`}
        >
          2
        </span>
        <div
          className={`w-1/3 h-2 ${
            step >= 2 ? "bg-[#0f3057]" : "bg-gray-300"
          } rounded-lg`}
        ></div>
        <span
          className={`border-2 px-2 py-0 ${
            step >= 3 ? "bg-[#10aab4] text-white" : "bg-gray-300"
          } rounded-full`}
        >
          3
        </span>
      </div>

      <form>
        {step === 1 && (
          <div>
            <h3>Upload a Profile video </h3>
            <div className="flex flex-col sm:flex-row mt-10 mb-10 justify-between items-center">
              <div>
                <label
                  for="image"
                  class="block text-sm text-[#0f3057] font-semibold "
                >
                  Upload a Profile video{" "}
                  <span className="text-red-600"> * </span>
                </label>

                <input
                  type="file"
                  name="file"
                  accept="video/*"
                  className="block w-full px-5 py-3 mt-2 text-gray-700 bg-white border
                   border-gray-200
                   rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]"
                />
              </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <div>
            <h3 className="text-md mb-4">
              Enter your latest work experience details. If you're a fresher,
              then select fresher.
            </h3>
            <div className="mb-12">
              <label class="block mb-2 text-md text-[#0f3057] font-semibold  ">
                Employment Status <span className="text-red-600"> * </span>
              </label>
              <select
              onChange={handleSelectChange}
                name="employmentStatus"
                className="block w-full px-5 py-3 mt-2 text-gray-700 bg-white border
                 border-gray-200
                 rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]"
              >
                <option value="">Select Employment Status</option>
                <option value="fresher">Fresher</option>
                <option value="experienced">Experienced</option>
              </select>
            </div>

            {employmentStatus === "experienced" && (
              <div>
                <h3 className="text-lg font-semibold mb-4">
                  Work Experience Details
                </h3>
                <div className="grid grid-cols-1 gap-6 mb-4 mt-8 md:mb-6 md:grid-cols-2">
                  <div>
                    <label class="block mb-2 text-sm text-[#0f3057] font-semibold :text-gray-200">
                      Job Profile Title <span className="text-red-600">* </span>
                    </label>

                    <Select
                      name="jobProfileTitle"
                      options={options2}
                      components={{ MenuList: VirtualizedMenuList }}
                      placeholder="Select Job Profile"
                    />
                  </div>

                  <div>
                    <label class="block mb-2 text-sm text-[#0f3057] font-semibold :text-gray-200">
                      School Name <span className="text-red-600">* </span>
                    </label>
                    <input
                      type="text"
                      name="schoolName"
                      className="block w-full px-5 py-3 mt-2 text-gray-700 bg-white border
                        border-gray-200
                        rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]"
                    />
                  </div>

                  <div>
                    <label class="block mb-2 text-sm text-[#0f3057] font-semibold :text-gray-200">
                      Locations <span className="text-red-600"> * </span>
                    </label>

                    <Select
                      id="search-select"
                      options={options}
                      isClearable
                      placeholder="Select Job Locations"
                      components={{ MenuList: VirtualizedMenuList }}
                    />
                  </div>
                  <div>
                    <label class="block mb-2 text-sm text-[#0f3057] font-semibold :text-gray-200">
                      Notice Period <i>(In months)</i>{" "}
                      <span className="text-red-600"> * </span>
                    </label>

                    <input
                      type="number"
                      name="noticePeriod"
                      className="block w-full px-5 py-3 mt-2 text-gray-700 bg-white border
             border-gray-200
             rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]"
                    />
                  </div>
                  <div>
                    <label class="block mb-2 text-sm text-[#0f3057] font-semibold :text-gray-200">
                      Total Teaching Experience <i>(In years)</i>{" "}
                      <span className="text-red-600"> * </span>
                    </label>
                  
                    <input
                      type="number"
                      name="totalTeachingExperience"
                      className="block w-full px-5 py-3 mt-2 text-gray-700 bg-white border
             border-gray-200
             rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]"
                    />
                  </div>
                  <div>
                    <label class="block mb-2 text-sm text-[#0f3057] font-semibold ">
                      Total Administrative Experience <i>(In years)</i>{" "}
                      <span className="text-red-600"> * </span>
                    </label>

                    <input
                      type="number"
                      name="totalAdministrativeExperience"
                      className="block w-full px-5 py-3 mt-2 text-gray-700 bg-white border
            border-gray-200
             rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {step === 3 && (
          <div>
            <h3 className="text-lg font-semibold text-center mb-4">
              Enter your highest Qualification
            </h3>
            <div className="grid grid-cols-1 gap-6 mb-4 mt-8 md:mb-6 md:grid-cols-2">
              <div>
                <label class="block mb-2 text-md text-[#0f3057] font-semibold ">
                  Education Type <span className="text-red-600"> * </span>
                </label>
              
                <select
                  name="educationtype"
                  className="block w-full px-5 py-3 mt-2 text-gray-700 bg-white border
             border-gray-200
             rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]"
                >
                  <option value="">Select Graduation</option>
                  <option value="graduate">Graduate</option>
                  <option value="postgraduate">Post Graduate</option>
                </select>
              </div>

              <div>
                <label class="block mb-2 text-md text-[#0f3057] font-semibold ">
                  Degree <span className="text-red-600"> * </span>
                </label>
                <Select
                  id="search-select"
                  options={options3}
                  isClearable
                  placeholder="Select degree"
                  components={{ MenuList: VirtualizedMenuList }}
                  className="block w-full px-5 py-3 mt-2 text-gray-700 bg-white border
                 border-gray-200
                 rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]"
                />
              </div>
              <div>
                <label class="block mb-2 text-md text-[#0f3057] font-semibold ">
                  Field of Study <span className="text-red-600"> * </span>
                </label>
                <Select
                  id="search-select"
                  options={options3}
                  isClearable
                  placeholder="Select fieldofstudy"
                  components={{ MenuList: VirtualizedMenuList }}
                  className="block w-full px-5 py-3 mt-2 text-gray-700 bg-white border
                 border-gray-200
                 rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]"
                />
              </div>

              <div>
                <label class="block mb-2 text-sm text-[#0f3057] font-semibold ">
                  Start/Completion Year{" "}
                  <span className="text-red-600"> * </span>
                </label>
                <div className="flex w-full space-x-6 justify-center ">
                  <div>
                    <input
                      type="number"
                      name="startyear"
                      placeholder="start year"
                      className="block w-full px-5 py-3 mt-2 text-gray-700 bg-white border
                     border-gray-200
                     rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]"
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      name="endyear"
                      placeholder="Completion year"
                      className="block w-full px-5 py-3 mt-2 text-gray-700 bg-white border
                    border-gray-200
                     rounded-lg focus:border-[#11a9b5] focus:ring-[#11a9b5]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
</form>
        <div
          className={`flex justify-between  ${
            step === 1 ? "lg:mx-[730px]" : ""
          }`}
        >
          {step > 1 && (
            <button
              type="button"
              onClick={() => setStep(step - 1)}
              className="ml-4 mt-4  px-6 py-3 text-black bg-gray-400 hover:bg-gray-400 font-semibold rounded-lg"
            >
              Previous
            </button>
          )}

          <button
        
            // disabled={!formik.isValid || !formik.dirty}
            onClick={() => setStep(step + 1)}
            className="mt-4 ml-4 px-6 py-3 rounded-lg bg-gray-400 text-black font-semibold "
          >
            {step === 3 ? "Submit" : "Next"}
          </button>
        </div>
      
    </div>
  );
}

export default Demovideo;
