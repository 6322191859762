import React, { useState ,useEffect} from 'react'
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import VirtualizedMenuList from "../../Utils/VirtualizedMenuList";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from '../../Utils/Context';
function Addemployer() {
  const { register, handleSubmit,control, formState: { errors } } = useForm();
  const {api} = useAuth()
  const [schoolOptions, setSchoolOptions] = useState([]);
  const token = localStorage.getItem('token');
  const[loading,setloading] = useState(false)
  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await api.get('/api/admin/pricing_master', {
          headers: {
            Authorization: `Bearer ${token}`, // Include the Bearer token in the headers
          },
        });

        const schoolList = response.data; // Assuming response.data is an array of school objects

        // Map through the schoolList to create an array of options
        const options = schoolList.map(school => ({
          value: school.id, // Use id as value
          label: school.subscription_plan, // Use subscription_plan as label
        }));

        // Update state with the new options
        setSchoolOptions(options);
      } catch (error) {
        console.error('Error fetching school data:', error);
      }
    };

    fetchSchools();
  }, [token]); 
  const onSubmit = async (data) => {
    try {
      // Extract the value of the selected subscription name
      const subscriptionId = data.subscription_name.value; // Get the selected option's value

      // Prepare data to be sent to the API
      const formData = {
        firstname: data.firstname,
        lastname: data.lastname,
        contact: data.contact,
        email: data.email,
        password: data.password,
        subscription_id: subscriptionId, // Send only the subscription ID
        organizationname: data.organizationname,
      };
setloading(true)
      // Send the form data to the API
      const response = await api.post('/api/admin/regschool', formData, {
        headers: {
          Authorization: `Bearer ${token}`,        },
      });

      console.log('Response:', response.data);
      toast.success(response.data.msg);
      setloading(false)
      // Handle success (e.g., show a toast notification or redirect)
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error("Failed to  submit");
      setloading(false)
      // Handle error (e.g., show an error message)
    }
  };

  



 

  return (
    <div className='px-4 py-14 '>
     <h2 className='mb-4 font-bold text-xl px-2'>Add Employer</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
    <div className="grid gap-6 mb-6 md:grid-cols-2">
      
      {/* School Name */}
      <div>
        <label htmlFor="school_name" className="block mb-2 text-sm font-medium text-gray-900">First name</label>
        <input 
          type="text" 
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
          placeholder="first name"
          {...register("firstname", { required: "first name is required" })}
        />
        {errors.firstname && <p className="text-red-600 text-sm">{errors.firstname.message}</p>}
      </div>

    
      <div>
        <label className="block mb-2 text-sm font-medium text-gray-900">last name</label>
        <input 
          type="text" 
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
          placeholder="last name"
          {...register("lastname", { required: "last name is required" })}
        />
        {errors.lastname && <p className="text-red-600 text-sm">{errors.lastname.message}</p>}
      </div>

      {/* School Number */}
      <div>
        <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">Contact number</label>
        <input 
          type="tel" 
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
          placeholder="98345678"
          {...register("contact", { 
            required: "School number is required",
            length:10, 
            pattern: { 
              value: /^[6-9]\d{9}$/,  // Ensures it starts with 6-9 and is 10 digits
              message: "Invalid Indian phone number (must be 10 digits)"
            }
          })}
        />
        {errors.contact && <p className="text-red-600 text-sm">{errors.contact.message}</p>}
      </div>

      {/* School Email */}
      <div className="mb-6">
        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900"> Email</label>
        <input 
          type="email" 
          id="email"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
          placeholder="school@example.com"
          {...register("email", { 
            required: "Email is required", 
            pattern: { 
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
              message: "Invalid email format"
            }
          })}
        />
        {errors.email && <p className="text-red-600 text-sm">{errors.email.message}</p>}
      </div>
     
    

  
    <div>
      <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Password</label>
      <input 
        type="password" 
        id="password"
        className="mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
        placeholder="password"
        {...register("password", { required: "School password is required" })}
      />
      {errors.password && <p className="text-red-600 text-sm">{errors.password.message}</p>}

        {/* State Select */}
        <div>
        <label
          htmlFor="state"
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Subscription name
        </label>
        <Controller
          name="subscription_name"
          control={control}
          rules={{ required: "subscription name is required" }}
          render={({ field }) => (
            <Select
              {...field}
              
         className="w-full"
         classNamePrefix="react-select"
         
        options={schoolOptions}
        
              placeholder="Select subscription name"
              isClearable
              components={{ MenuList: VirtualizedMenuList }}
            />
          )}
        />
       
        {errors.subscription_name && (
          <p className="text-red-600 text-sm">{errors.subscription_name.message}</p>
        )}
      </div>
      </div>
      <div>
        <label  className="block mb-2 text-sm font-medium text-gray-900">Organization name</label>
        <input 
          type="text" 
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
          placeholder="organization name"
          {...register("organizationname", { required: "organization name is required" })}
        />
        {errors.organizationname && <p className="text-red-600 text-sm">{errors.organizationname.message}</p>}
      </div>
    </div>
  
    {/* Submit and Cancel Buttons */}
    <div className="flex justify-end mt-8 gap-4">
      {loading && (
        <div role="status">
        <svg aria-hidden="true" class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg>
        <span class="sr-only">Loading...</span>
    </div>
      )}
        <button type="submit" className="text-white bg-[#0f3057] hover:bg-[#d9f1f3] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Add Employer</button>
    </div>
  </form></div>
  )
}

export default Addemployer