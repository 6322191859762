import React, { useState  } from 'react';
import logo from '../assets/flogo1.jpeg'
import {  Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../Utils/Context';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const OtpLogin = () => {
    const [mobileNumber, setMobileNumber] = useState('');
    const navigate = useNavigate()
    const {api} = useAuth()
    const [loading, setloading] = useState(false)
    const handleMobileSubmit =  async (e) => {
      e.preventDefault();
      if (mobileNumber.length === 10) {
      setloading(true)
        // Simulate sending OTP via API
        try {
          const response = await api.post(`/api/send_otp_for_login?contact=${mobileNumber}`)
          console.log(response.data)
          if(response.data.msg === "Success"){
            localStorage.setItem('mobileNumber',mobileNumber );
            navigate('/confirmotp')
            setloading(false)
          } 
          
          toast.info(response.data.msg)
        } catch (error) {
          setloading(false)
          console.error("error")
        }finally{

          setloading(false)
        }
      } else {
        alert('Please enter a valid 10-digit mobile number.');
      }
    };
  
  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4 bg-gray-100">
      <ToastContainer/>
      <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-lg ">
      <div className="flex flex-col items-center mb-8">


<img
className=" h-10 mb-4 sm:h-[50px]"
src={logo}
alt="Logo"
/>
<h1 class="text-lg sm:text-2xl font-bold text-gray-800 capitalize ">
Welcome to <span className='text-[#11a9b5]' >MySchoolNaukri </span> 
</h1>
</div>
        
          <form onSubmit={handleMobileSubmit}>
            <h2 className="text-xl font-semibold text-start mb-4">Enter Mobile Number</h2>
            <div class="relative border-r-2">
        <div class="absolute inset-y-0 start-0 top-6  flex items-center ps-3.5 pointer-events-none">
            +91
        </div>
        </div>
            <input
              type="text"
              placeholder="Enter your mobile number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              maxLength={10}
              className="w-full p-3 ps-12 mb-6 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <button
              type="submit"
              className="w-full py-3 bg-[#11a9b5] text-white font-semibold rounded-md hover:bg-[#0f3057] transition"
            >
              {loading? 'loading...':" Send Otp"}
             
            </button>
          </form>
          <div className="flex items-center justify-center py-4 text-center bg-gray-50">
        <span className="text-sm text-gray-600">
          Don't have an account?{" "}
        </span>

        <Link
          to="/Candidate_onboarding1"
          className="mx-2 text-sm font-bold text-[#11a9b5] hover:underline"
        >
          Sign Up
        </Link>
      </div>
      </div>
    </div>
  );
};

export default OtpLogin;
