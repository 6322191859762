// FreeTrialOverPage.jsx
import React from "react";

const FreeTrialOverPage = () => {
  return (
    <div className="h-auto py-20 bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full text-center">
        <h2 className="text-3xl font-bold text-[#11a9b5] mb-6">
          Your Free Trial Has Ended
        </h2>
        <p className="text-lg text-gray-600 mb-6">
          Unfortunately, your free trial has expired. To continue enjoying all
          the features
        </p>
        <div className="mt-4">
        <p className="text-lg text-gray-600 mb-6">
         Click bellow to activate subscription 
        </p>
          <button
            className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition"
            onClick={() => alert("Redirecting to subscription page...")}
          >
            Subscribe Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default FreeTrialOverPage;
