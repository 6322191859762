import React from 'react'
import { Link } from 'react-router-dom'

function JonsearchBanner() {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // smooth scrolling
        });
      };
  return (


<section class="bg-[#0f3057]  :bg-gray-900">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-4xl lg:text-4xl :text-white">Search latest jobs by Categories</h1>
        <p class="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48 :text-gray-400">Easily explore job opportunities by filtering through categories like designation, location to find roles that suit your preferences.</p>
        <div class="flex flex-col gap-6 space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
            <Link to="/JobSearchPage"  onClick={scrollToTop}  class="text-gray-100 hover:text-[#11a9b5] hover:cursor-pointer">
            <p  class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-[#11a9b5]  focus:ring-4 focus:ring-blue-300 :focus:ring-blue-900">
            Jobs by States
                <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                </svg>
            </p>
            </Link>
            <Link to="/JobSearchPage"  onClick={scrollToTop}  class="text-gray-100 hover:text-[#11a9b5] hover:cursor-pointer">
            <p  class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-[#11a9b5]  focus:ring-4 focus:ring-blue-300 ">
            Jobs by Designation
                <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                </svg>
            </p>
            </Link>
           
        </div>
    </div>
</section>
  )
}

export default JonsearchBanner