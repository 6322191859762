import React, { useState } from "react";
import SearchCandi from "./SearchCandi";
import { Link } from "react-router-dom";
function Tabs({onChange}) {
  const[status, setstatus] = useState(null)
  const applyFilters = (num) => {
    // Update the state with the new status
    setstatus(num);
    // Immediately pass the new value (num) to the onChange handler
    onChange({
      status: num !== null ? num : null,
    });
  };
 
  return (
    <>
    <div className=" justify-center px-20 sm:px-0 sm:bg-slate-50 flex flex-wrap border-gray-600">
      {" "}
      <ul class="flex sm:mt-0 sm:py-0 -mt-7 py-4 px-4 sm:px-0 sm:border-b-2 sm:static fixed sm:bg-slate-50 bg-slate-300 sm:z-0 z-30 border-gray-600 w-full flex-wrap  gap-2 items-center text-sm font-medium ">
        <li onClick={()=>applyFilters(0)}  class="sm:me-2 mb-2" role="presentation">
          <button
          
            type="button"
            class="relative inline-flex items-center px-4 py-2.5 text-xs sm:text-sm font-medium text-center text-white bg-[#0f3057] rounded-lg hover:bg-[#11a9b5] focus:ring-4 focus:outline-none focus:ring-blue-300 "
          >
            <span class="sr-only">Notifications</span>
            All Candidate
            {/* <div class="absolute inline-flex items-center justify-center w-fit px-1 py-1 h-6 text-xs font-bold text-white bg-[#11a9b5] border-2 border-white rounded-full -top-2 -end-2 ">
              {totalpage}
            </div> */}
          </button>
        </li>
        <li  onClick={()=>applyFilters(1)}  class="sm:me-2 mb-2 " role="presentation">
          <button
         
            type="button"
            class="relative inline-flex items-center px-5 py-2.5 text-xs sm:text-sm  font-medium text-center text-white bg-[#0f3057] rounded-lg hover:bg-[#11a9b5] focus:ring-4 focus:outline-none focus:ring-blue-300 "
          >
            <span class="sr-only">Notifications</span>
          
            Saved
            {/* <div class="absolute inline-flex items-center justify-center w-fit px-1 py-1  h-6 text-xs font-bold text-white bg-[#11a9b5] border-2 border-white rounded-full -top-2 -end-2 ">
              90
            </div> */}
          </button>
        </li>
        <li  onClick={()=>applyFilters(2)}  class="sm:me-2  mb-2" role="presentation">
          <button
         
            type="button"
            class="relative inline-flex items-center px-5 py-2.5 text-xs sm:text-sm  font-medium text-center text-white bg-[#0f3057] rounded-lg hover:bg-[#11a9b5] focus:ring-4 focus:outline-none focus:ring-blue-300 "
          >
            <span class="sr-only">Notifications</span>
           
            Shortlisted
            {/* <div class="absolute inline-flex items-center justify-center w-fit px-1 py-1  h-6 text-xs font-bold text-white bg-[#11a9b5] border-2 border-white rounded-full -top-2 -end-2 ">
              987
            </div> */}
          </button>
        </li>
        <li class="sm:me-2 mb-2" role="presentation">
          <button
          onClick={()=>applyFilters(3)} 
            type="button"
            class="relative inline-flex items-center px-5 py-2.5 text-xs sm:text-sm  font-medium text-center text-white bg-red-500 rounded-lg hover:bg-[#11a9b5] focus:ring-4 focus:outline-none focus:ring-blue-300 "
          >
            <span class="sr-only">Notifications</span>
            
            Rejected
            {/* <div class="absolute inline-flex items-center justify-center w-fit px-1 py-1  h-6 text-xs font-bold text-white bg-[#11a9b5] border-2 border-white rounded-full -top-2 -end-2 ">
              87
            </div> */}
          </button>
        </li>
        {/* <li class="mb-2" role="presentation">
          <button
            type="button"
            class="relative inline-flex items-center px-5 py-2.5 text-xs sm:text-sm font-medium text-center text-white bg-[#0f3057] rounded-lg hover:bg-[#11a9b5] focus:ring-4 focus:outline-none focus:ring-blue-300 "
          >
            <span class="sr-only">Notifications</span>
            Applied
            <div class="absolute inline-flex items-center justify-center w-fit px-1 py-1  h-6 text-xs font-bold text-white bg-[#11a9b5] border-2 border-white rounded-full -top-2 -end-2 ">
              0
            </div>
          </button>
        </li> */}
      </ul>
        <div className="relative -top-[50px] left-60  z-30">
        <SearchCandi/>
      </div>
       
      
    </div>
      
       </>
  );
}

export default Tabs;
