import React from 'react'
import Card from '../Utils/Card'
import { useNavigate } from 'react-router-dom';
function RecentJob() {
  const navigate = useNavigate();
  const scrollToTop = () => {
    navigate('/all-jobs')
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // smooth scrolling
    });
  };
  return (
    <section class="text-gray-600 body-font">
  <div class="container px-5 sm:py-18 py-8  mb-14 mx-auto">
    <div class="flex flex-wrap w-full mb-10 flex-col items-center text-center">
      <h1 class="sm:text-3xl text-2xl font-bold title-font mb-2 text-gray-900">Recent job  <span className='text-[#11a9b5]'>Postings</span> </h1>
      <p class="lg:w-1/2 w-full leading-relaxed text-gray-500">Check out the latest job vacancies.</p>
    </div>
    
    
      
      <Card />
      
      
      
   
    <button onClick={scrollToTop }    class="flex mx-auto mt-14  text-white bg-[#11a9b5] border-0 py-2 px-8 font-semibold focus:outline-none hover:bg-gray-600 rounded   lg:text-lg text-md">Browser More</button>
  </div>
</section>
  )
}

export default RecentJob