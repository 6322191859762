import React from 'react'

function Ytsection() {
  return (
    <section class="text-gray-600 body-font">
  <div class="justify-center  px-5 py-22 mx-auto flex flex-wrap sm:mb-12 mb-0">
  <h1 class="sm:text-4xl text-2xl font-bold title-font mb-12 text-gray-900">
  Process  <span className='text-[#11a9b5]'>Outline</span> </h1>
    <div class="flex flex-wrap justify-around  w-full">
    <iframe
  width="360"
  height="640"
   className='rounded-2xl shadow-2xl  '
  src="https://www.youtube.com/embed/BskThkYjEPo"
  title="The 3-Step Profile Optimization Process! | MySchoolNaukri"
  // Note: 'frameborder' should be 'frameBorder' in JSX
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  referrerPolicy="strict-origin-when-cross-origin"
  allowFullScreen // Note: 'allowfullscreen' should be 'allowFullScreen' in JSX
/>

        {/* <iframe width="526" height="530" className='rounded-2xl shadow-2xl ' src="https://www.youtube.com/embed/5WxIq6M-dWk" title="MySchoolNaukri: A platform for the teachers, of the teachers, by the teachers" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
      <div class="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6 py-10">
        <div class="flex relative pb-12">
          <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
            <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
          </div>
          <div class="flex-shrink-0 w-10 h-10 rounded-full bg-[#11a9b5] inline-flex items-center justify-center text-white relative z-10">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
              <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
          </div>
          <div class="flex-grow pl-4">
            <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">STEP 1</h2>
            <h2 class=" title-font text-xl text-[#11ac9b] font-bold mb-1 tracking-wider">Make Your Profile</h2>
            <p class="leading-relaxed text-justify"> Sign Up and make your profile as detailed as possible including the demo video and resume.</p>
          </div>
        </div>
        <div class="flex relative pb-12">
          <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
            <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
          </div>
          <div class="flex-shrink-0 w-10 h-10 rounded-full bg-[#11a9b5] inline-flex items-center justify-center text-white relative z-10">
          <svg className='h-6 w-6'  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill='#ffffff' d="M0 128C0 92.7 28.7 64 64 64l256 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2l0 256c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1l0-17.1 0-128 0-17.1 14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"/></svg>
          </div>
          <div class="flex-grow pl-4">
            <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">STEP 2</h2>
            <h2 class=" title-font text-xl text-[#11ac9b] font-bold mb-1 tracking-wider">Upload Demo Video</h2>
            <p class="leading-relaxed text-justify"> Make a demo teaching video where you show the employer how good a teacher you are.</p>
          </div>
        </div>
        <div class="flex relative pb-12">
          <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
            
          </div>
          <div class="flex-shrink-0 w-10 h-10 rounded-full bg-[#11a9b5] inline-flex items-center justify-center text-white relative z-10">
          <svg className='h-6 w-6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M3.9 54.9C10.5 40.9 24.5 32 40 32l432 0c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9 320 448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6l0-79.1L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"/></svg>
          </div>
          <div class="flex-grow pl-4">
            <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">STEP 3</h2>
            <h2 class=" title-font text-xl text-[#11a9b5] font-bold mb-1 tracking-wider">Filter Jobs and Apply</h2>
            <p class="leading-relaxed text-justify"> Filter jobs as per your desired profile, salary, location, etc. and start applying.</p>
          </div>
        </div>
        
        
      </div>
      
      
    </div>
  </div>
</section>
  )
}

export default Ytsection