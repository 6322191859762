import React, { useState } from "react";
import Select from "react-select";
import VirtualizedMenuList from "../../Utils/VirtualizedMenuList";
import { Designation, Location,StateLocation,customClassNames,customStyles } from './Options';

function FilterCandidate({onChange}) {
    const { options } = Location();
    const { options3 } = Designation();
    const {stateOptions} = StateLocation()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [designation, setDesignation] = useState(null);
  const [gender, setgender] = useState(null);
  const [location, setLocation] = useState(null);
  const [state_name, setstate] = useState(null);

  const workModeOptions = [
    { value: "1", label: "female" },
    { value: "0", label: "male" },
 
  ];
  console.log(state_name)

  const applyFilters = () => {
    // Only pass the selected values (or null) to the parent
    onChange({
      designation: designation ? designation : null,
      location: location ? location : null,
      gender:gender?gender.value :null,
      state_name:state_name?state_name.value :null,
     
    });
  };
  const handleFilterReset = () => {
    // Clear the selected filters
    setDesignation(null);
    setLocation(null);
    setgender(null);
  

    // Send the updated state (empty) to the parent
    onChange({
      designation: null,
      location: null,
      gender: null,
    });
    localStorage.removeItem('filters');
  };
  return (
    <>
    <div onClick={()=>setIsModalOpen(!isModalOpen)} class="w-full mx-8 md:hidden relative">
    <button 
        className="inline-flex items-center justify-center px-3 py-2 border text-white border-transparent text-sm font-medium bg-[#0f3057]  rounded-3xl hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2"
      >
         <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
        filter 
      </button>
    </div>
    {isModalOpen && (

<div className="fixed  inset-0 z-50 flex justify-center items-center w-full h-full bg-gray-900 bg-opacity-50">
<div className="relative p-4 bg-white rounded-lg shadow  overflow-y-auto max-h-[80vh]"> 
    <div className="max-w-sm bg-gray-100 p-4 rounded-lg">
      <div className="flex justify-end  items-center">

  
    <button 
                  onClick={()=>setIsModalOpen(false)}
                  type="button" 
                  className="text-gray-800 bg-transparent mb-4  hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8    "
                >
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13"/>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                </div>
              
                <div className="space-y-1">
      {/* Designation Filter */}
      <Select
        className=" text-gray-800"
        options={options3}
        styles={customStyles}
        
        classNamePrefix="react-select"
        classNames={customClassNames}
        placeholder={"Designation"}
        // value={options3.find(option => option.value === designation)} // Ensure correct display
        // onChange={(option) => setDesignation(option?.value || null)}
        value={designation} // Ensure the entire object is passed here
        onChange={(option) => setDesignation(option)} // Store the entire option object // Handle clearable case
        isClearable
        components={{ MenuList: VirtualizedMenuList }}
      />

      {/* gender Filter */}
      <Select
        id="search-select"
        styles={customStyles}
        
        classNamePrefix="react-select"
        classNames={customClassNames}
        options={workModeOptions} // Use dynamic location options
        // value={options.find(option => option.value === location)} // Ensure correct display
        // onChange={(option) => setLocation(option?.value || null)} // Handle clearable case
        value={gender} // Ensure the entire object is passed here
        onChange={(option) => setgender(option)} // Store the entire option object
        placeholder=" select gender"
        components={{ MenuList: VirtualizedMenuList }}
        isClearable
      />
      {/* state filter */}
      <Select
        id="search-select"
        styles={customStyles}
        
        classNamePrefix="react-select"
        classNames={customClassNames}
        options={stateOptions} // Use dynamic location options
        // value={options.find(option => option.value === location)} // Ensure correct display
        // onChange={(option) => setLocation(option?.value || null)} // Handle clearable case
        value={state_name} // Ensure the entire object is passed here
        onChange={(option) => setstate(option)} // Store the entire option object
        placeholder=" Filter with State"
        components={{ MenuList: VirtualizedMenuList }}
        isClearable
      />
    
      <Select
        id="search-select"
        styles={customStyles}
        
        classNamePrefix="react-select"
        classNames={customClassNames}
        options={options} // Use dynamic location options
        // value={options.find(option => option.value === location)} // Ensure correct display
        // onChange={(option) => setLocation(option?.value || null)} // Handle clearable case
        value={location} // Ensure the entire object is passed here
        onChange={(option) => setLocation(option)} // Store the entire option object
        placeholder="Search and select location"
        components={{ MenuList: VirtualizedMenuList }}
        isClearable
      />
      
    

      {/* Apply Filter Button */}
      <button
        onClick={applyFilters} // Apply filters and send data to the parent
        className="w-full mt-4 py-2 px-4 bg-[#11a9b5] text-white font-semibold rounded-md hover:bg-[#0f3057]"
      >
        Apply Filter
      </button>
      <button
        onClick={handleFilterReset}
        className="w-full mt-4 py-2 px-4 bg-gray-500 text-white font-semibold rounded-md hover:bg-gray-700"
      >
        Remove Filter
      </button>
    </div>
    </div>
    </div>
    </div>
    )}
   
    <div className="space-y-1 hidden sm:block">
      {/* Designation Filter */}
      <Select
        className=" text-gray-800"
        options={options3}
        styles={customStyles}
        
        classNamePrefix="react-select"
        classNames={customClassNames}
        placeholder={"Designation"}
        // value={options3.find(option => option.value === designation)} // Ensure correct display
        // onChange={(option) => setDesignation(option?.value || null)}
        value={designation} // Ensure the entire object is passed here
        onChange={(option) => setDesignation(option)} // Store the entire option object // Handle clearable case
        isClearable
        components={{ MenuList: VirtualizedMenuList }}
      />

      {/* Location Filter */}
      <Select
        id="search-select"
        styles={customStyles}
        
        classNamePrefix="react-select"
        classNames={customClassNames}
        options={workModeOptions} // Use dynamic location options
        // value={options.find(option => option.value === location)} // Ensure correct display
        // onChange={(option) => setLocation(option?.value || null)} // Handle clearable case
        value={gender} // Ensure the entire object is passed here
        onChange={(option) => setgender(option)} // Store the entire option object
        placeholder=" select gender"
        components={{ MenuList: VirtualizedMenuList }}
        isClearable
      />
      <Select
        id="search-select"
        styles={customStyles}
        
        classNamePrefix="react-select"
        classNames={customClassNames}
        options={stateOptions} // Use dynamic location options
        // value={options.find(option => option.value === location)} // Ensure correct display
        // onChange={(option) => setLocation(option?.value || null)} // Handle clearable case
        value={state_name} // Ensure the entire object is passed here
        onChange={(option) => setstate(option)} // Store the entire option object
        placeholder=" Filter with State"
        components={{ MenuList: VirtualizedMenuList }}
        isClearable
      />
      <Select
        id="search-select"
        styles={customStyles}
        
        classNamePrefix="react-select"
        classNames={customClassNames}
        options={options} // Use dynamic location options
        // value={options.find(option => option.value === location)} // Ensure correct display
        // onChange={(option) => setLocation(option?.value || null)} // Handle clearable case
        value={location} // Ensure the entire object is passed here
        onChange={(option) => setLocation(option)} // Store the entire option object
        placeholder="Search and select location"
        components={{ MenuList: VirtualizedMenuList }}
        isClearable
      />
      
    

      {/* Apply Filter Button */}
      <button
        onClick={applyFilters} // Apply filters and send data to the parent
        className="w-full mt-4 py-2 px-4 bg-[#11a9b5] text-white font-semibold rounded-md hover:bg-[#0f3057]"
      >
        Apply Filter
      </button>
      <button
        onClick={handleFilterReset}
        className="w-full mt-4 py-2 px-4 bg-gray-500 text-white font-semibold rounded-md hover:bg-gray-700"
      >
        Remove Filter
      </button>
    </div>
    </>
  )
}

export default FilterCandidate