import React from 'react';

const SkeletonCard = () => {
  return (
    <div className="animate-pulse flex flex-col space-y-4 p-4 ">
      {/* Search bar */}
      <div className="h-10 bg-gray-300 rounded-md"></div>

      {/* Filters */}
      <div className='grid sm:grid-cols-2   gap-2'>

 
      <div className=" grid-cols-2 space-y-4 md:grid-cols-1 hidden md:block max-w-sm">
        <div className="h-10 bg-gray-300 rounded-md"></div>
        <div className="h-10 bg-gray-300 rounded-md"></div>
        <div className="h-10 bg-gray-300 rounded-md"></div>
        <div className="h-10 bg-gray-300 rounded-md"></div>
        <div className="h-10 bg-gray-300 rounded-md"></div>
        <div className="h-10 bg-gray-300 rounded-md"></div>
      <div className="h-12 bg-teal-400 rounded-md max-w-sm"></div>
      </div>

      {/* Find candidate button */}

      {/* Cards (Skeletons for candidates) */}
      <div className="space-y-4 grid-cols-1 md:grid-cols-1 max-w-2xl ">
        {/* Card */}
        <div className="p-6 bg-gray-200 rounded-md space-y-4">
          <div className="h-5 w-32 bg-gray-300 rounded-md"></div>
          <div className="h-4 w-20 bg-gray-300 rounded-md"></div>
          <div className="h-4 w-48 bg-gray-300 rounded-md"></div>
          <div className="h-4 w-40 bg-gray-300 rounded-md"></div>
          <div className="h-4 w-full bg-gray-300 rounded-md"></div>

          <div className="flex justify-between mt-4">
            <div className="h-10 w-24 bg-gray-300 rounded-md"></div>
            <div className="h-10 w-24 bg-gray-300 rounded-md"></div>
            <div className="h-10 w-24 bg-gray-300 rounded-md"></div>
          </div>
        </div>

        {/* Another Card */}
        <div className="p-6 bg-gray-200 rounded-md space-y-4">
          <div className="h-5 w-32 bg-gray-300 rounded-md"></div>
          <div className="h-4 w-20 bg-gray-300 rounded-md"></div>
          <div className="h-4 w-48 bg-gray-300 rounded-md"></div>
          <div className="h-4 w-40 bg-gray-300 rounded-md"></div>
          <div className="h-4 w-full bg-gray-300 rounded-md"></div>

          <div className="flex justify-between mt-4">
            <div className="h-10 w-24 bg-gray-300 rounded-md"></div>
            <div className="h-10 w-24 bg-gray-300 rounded-md"></div>
            <div className="h-10 w-24 bg-gray-300 rounded-md"></div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default SkeletonCard;