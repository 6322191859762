import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/last.png'
import { useAuth } from '../Utils/Context';
function Sidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isactive, setactive] = useState("dash");

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
//   const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
const {logout} = useAuth()
  return (
    <>
      
      <div className="flex pl-[300px] md:pl-6 fixed p-2 mt-3 ms-3  text-sm z-50  lg:hidden">
            <button
               onClick={toggleSidebar}
              className="inline-flex items-center justify-center p-2 rounded-md text-white   "
            >
              <span className="sr-only">Open main menu</span>
              {isSidebarOpen ? (
                <svg className="block h-6 md:ml-[170px]  w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="blockh-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </button>
          </div>

      <aside
        id="sidebar-multi-level-sidebar"
        className={`fixed top-0 left-0  z-40 w-64 h-screen transition-transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0`}
        aria-label="Sidebar"
      >
        
        <div className="h-full px-0 py-[0px] shadow-2xl overflow-y-auto bg-[#0f3057]  ">
        <img
            className="    " 
            src={logo}
            alt="Logo"
            loading="lazy"
          />
          <ul className="space-y-2 px-4 border-t-2 -mt-4 font-medium">
            <li>
              <Link
              onClick={()=>{setactive("dash");setIsSidebarOpen(false)}}
                to="main"
                className={`${isactive === "dash"?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}
              >
                <svg className="w-5 h-5 text-[#11a9b5] transition duration-75  group-hover:text-gray-100 :group-hover:text-white" aria-hidden="true" fill="currentColor" viewBox="0 0 22 21">
                  <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                  <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                </svg>
                <span className="ms-3">Dashboard</span>
                <span className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full :bg-gray-700 :text-gray-300">4</span>
              </Link>
            </li>
            <li>
              <Link
              onClick={()=>{setactive("Master");setIsSidebarOpen(false)}}
                to="master"
                className={`${isactive === "Master"?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}
              >
                <svg className="w-5 h-5 text-[#11a9b5] transition duration-75  group-hover:text-gray-100 :group-hover:text-white" aria-hidden="true" fill="currentColor" viewBox="0 0 22 21">
                  <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                  <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                </svg>
                <span className="ms-3">Master's</span>
                <span className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full :bg-gray-700 :text-gray-300">4</span>
              </Link>
            </li>
            {/* <li>
              <Link
              onClick={()=>setactive("Manage")}
                to="manage"
                className={`${isactive === "Manage"?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}
              >
                <svg className="w-5 h-5 text-[#11a9b5] transition duration-75  group-hover:text-gray-100 :group-hover:text-white" aria-hidden="true" fill="currentColor" viewBox="0 0 22 21">
                  <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                  <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                </svg>
                <span className="ms-3">Manage App</span>
                
              </Link>
            </li> */}
            {/* <hr /> */}
            {/* <li>
              <button
                type="button"
                onClick={toggleDropdown}
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 :text-white :hover:bg-gray-700"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 group-hover:text-gray-900 :text-gray-400 :group-hover:text-white" aria-hidden="true" fill="currentColor" viewBox="0 0 18 21">
                  <path d="M15 12a1 1 0 0 0 .962-.726l2-7A1 1 0 0 0 17 3H3.77L3.175.745A1 1 0 0 0 2.208 0H1a1 1 0 0 0 0 2h.438l.6 2.255v.019l2 7 .746 2.986A3 3 0 1 0 9 17a2.966 2.966 0 0 0-.184-1h2.368c-.118.32-.18.659-.184 1a3 3 0 1 0 3-3H6.78l-.5-2H15Z" />
                </svg>
                <span className="flex-1 ms-3 text-left whitespace-nowrap">E-commerce</span>
                <svg className="w-3 h-3" aria-hidden="true" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                </svg>
              </button>
              {isDropdownOpen && (
                <ul id="dropdown-example" className="py-2 space-y-2">
                  <li>
                    <Link href="#" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 :text-white :hover:bg-gray-700">
                      Products
                     </Link>
                  </li>
                  <li>
                    <Link href="#" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 :text-white :hover:bg-gray-700">
                      Billing
                     </Link>
                  </li>
                  <li>
                    <Link href="#" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 :text-white :hover:bg-gray-700">
                      Invoice
                     </Link>
                  </li>
                </ul>
              )}
            </li> */}  
             {/* <li>
                <Link  onClick={()=>setactive("job")}  to="notification"  className={`${isactive === "job" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                    <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Notification Generate</span>
                 
                </Link>
              </li>
              <li>
                <Link onClick={()=>setactive("save")}  to="totalpayment"  className={`${isactive === "save"?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Total Payment</span>
                 
                </Link>
              </li>
              <li>
                <Link onClick={()=>setactive("charts")}  to="cancelation" className={`${isactive === "charts" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                    <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Cancelation Requests</span>
                
                </Link>
              </li>
             
             
              <li>
                <Link href="#" to="appusers" className="flex items-center p-2 text-white rounded-lg :text-white hover:bg-[#11a9b5] :hover:bg-gray-700 group">
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">App Users</span>
                 </Link>
              </li>
              <li>
                <Link onClick={()=>setactive("profile")} to="postedjob"  className={`${isactive === "profile" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Posted Job's in App</span>
                 </Link>
              </li>
             
              <li>
                <Link onClick={()=>setactive("Faq")} to="acc_del" className={`${isactive === "Faq"?'bg-[#11a9b5]  sm:flex hidden items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'sm:flex hidden items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Account Deletion</span>
                 </Link>
              </li> */}
              <hr />
              <br />
              <li>
                <Link onClick={()=>{setactive("help");setIsSidebarOpen(false)}} to="Subscription"  className={`${isactive === "help" ?'bg-[#11a9b5]  hidden sm:flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'sm:flex hidden items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Subscription</span>
                 </Link>
              </li>
              {/* <li>
                <Link onClick={()=>setactive("Enquriy")} to="Job_enquiry"  className={`${isactive === "Enquriy" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex  items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Job Enquriy</span>
                 </Link>
              </li>
              <li>
                <Link onClick={()=>setactive("User")} to="user_import"  className={`${isactive === "User" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex  items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">User Import</span>
                 </Link>
              </li>
              <li>
                <Link onClick={()=>setactive("Signup")} to="G_singup"  className={`${isactive === "Signup" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex  items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Generate Signup Url</span>
                 </Link>
              </li> */}
              <li>
                <Link onClick={()=>{setactive("Candidate");setIsSidebarOpen(false)}} to="Candidate"  className={`${isactive === "Candidate" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex  items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Candidate</span>
                 </Link>
              </li>
              <li>
                <Link onClick={()=>{setactive("Add");setIsSidebarOpen(false)}} to="add_emp"  className={`${isactive === "Add" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex  items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Add Employer</span>
                 </Link>
              </li>
              <li>
                <Link onClick={()=>{setactive("Employer");setIsSidebarOpen(false)}} to="employer"  className={`${isactive === "Employer" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex  items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap"> Employer</span>
                 </Link>
              </li>
              <li>
                <Link onClick={()=>{setactive("Posted");setIsSidebarOpen(false)}} to="posted_job"  className={`${isactive === "Posted" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex  items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap"> Posted Job</span>
                 </Link>
              </li>
               <li>
                <Link onClick={()=>{setactive("Report");setIsSidebarOpen(false)}} to="Jobapply_report"  className={`${isactive === "Report" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex  items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap"> Job Applied Report</span>
                 </Link>
              </li>
              
              <li>
                <Link onClick={()=>setactive("demo")} to="ComingSoon"  className={`${isactive === "demo" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex  items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap"> Book demo</span>
                 </Link>
              </li>
              <li onClick={logout}>
                <hr />
                <Link onClick={()=>setactive("logout")} href="#"  className={`${isactive === "logout" ?'bg-[#11a9b5]   flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex  items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap"> Logout</span>
                 </Link>
              </li>
              <br />
          </ul>
        </div>
      </aside>
    </>
  );
}

export default Sidebar;
