import React, { useState } from "react";
import Jobfilter from "../components/Jobfilter";
import { useQuery } from "@tanstack/react-query";
import Empjobs from "../components/EmpJobs";
import Loading from "../../Utils/Loading";
import { Fetchjobs } from "../components/Function";
import { useAuth } from "../../Utils/Context";
import axios from "axios";
import Jobpostmodal from "../components/Jobpostmodal";
function Jobposting() {
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [jobData, setJobData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const api = useAuth()
  const [filters, setFilters] = useState({
    designation: null,
    education: null,
    experience: 0,
    workMode: null,
    specilization: null,
    location: null,
  });
  const handleEditClick = async (jobId) => {
    setSelectedJobId(jobId);
    setIsModalOpen(true);

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`https://info.myschoolnaukri.com/api/editjob?jobId=${jobId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add Bearer token to the request
        },
      });
      
      setJobData(response.data); // Assuming 'Success' contains the school data
    }
     catch (error) {
      console.error("Error fetching job data:", error);
    }
  };

 
  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedJobId(null); // Clear selected job ID
    setJobData(null); // Clear job data
  };
  const handleFilterChange = (filters) => {
    // Update filter parameters when the Apply button is clicked
    setFilters(filters);
  };
  const {
    data: jobs = [],
    isLoading,
    error,
    isError,
  } = useQuery({
    queryKey: ["jobs", {  filters }],
    queryFn: () => Fetchjobs(filters),

    keepPreviousData: true, // Keep the previous data while loading new data
  });

 
  if (isLoading) return <Loading />;
  // if (isError) return <div>Error: {error.message}</div>;
  if (isError) return <div className="text-center text-2xl text-red-500">
  Unable to Load data,Please try again later
</div>;
  
  return (
    <>
      <div class="mb-4     sm:bg-slate-50 flex flex-wrap border-gray-600 ">


        <div className="grid sm:grid-cols-3 sm:gap-20 sm:mt-12  mb-4">
          <div className="col-span-1     mt-[5px] w-full sm:px-0 px-14 ml-[0px] sm:ml:-[230px] sm:ml-0 sm:mt-0 sm:static fixed sm:z-0 z-30">
            <Jobfilter onChange={handleFilterChange}/>
          </div>
          {jobs.data?.length > 0 ? (
          <div className="space-y-4 sm:mx-8 mx-3 sm:mt-0 mt-20 col-span-2">
            {jobs?.data.map((job) => (
              <Empjobs
                key={job.id}
                id={job.id}
                state_name={job.state}
                name={job.designation}
                school_name={job.school_name}
                city_name={job.location}
                board={job.board}
                job_description={job.job_description}
                salarymax={job.salarymax}
                salarymin={job.salarymin}
                date={job.max_created_at}
                applicant={job.count}
                employment_type={job.employment_type}
                onEdit={handleEditClick} 
              />
            ))}
            {/* <Pageination data ={jobs} currentPage={currentPage} setCurrentPage={setCurrentPage} jobsPerPage={jobsPerPage}/> */}
          </div>
          ): (
            <p class="lg:text-lg  text-red-500  font-semibold">
              No jobs found, Apply different filter
            </p>
          )}
        </div>
        
      </div>
      <div className="hidden sm:flex gap-x-3 px-4 sm:items-center">

      {isModalOpen && jobData && (
        <Jobpostmodal jobData={jobData} closeModal={closeModal} />
      )}
      </div>
    </>
  );
}

export default Jobposting;
