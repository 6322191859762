import React from "react";
import { useNavigate ,Link} from "react-router-dom";
import { updateCandidateStatus } from "./Function";
function Jobcard({
  state_name,
  name,
  gender,
  city_name,
  id,
  mobile,
  email,
  total_exp_yr,
  total_exp_mn,
  education,
  notice_period
}) {
const navigate = useNavigate();
const handleCardClick = (jobId) => {
  navigate(`/dashboard/candidate/profile/${jobId}`);
};
const message = 'Hello, I would like to chat!';
const openWhatsApp = () => {
  // Construct the WhatsApp URL
  const whatsappURL = `https://wa.me/${mobile}?text=${encodeURIComponent(message)}`;
  
  // Open WhatsApp in a new tab
  window.open(whatsappURL, '_blank');
};

const initiateCall = () => {
  // Use the tel: scheme to initiate a call
  window.location.href = `tel:${mobile}`;
};

const handleButtonClick = async (candidateId,status) => {
  

  try {
    await updateCandidateStatus(candidateId, status);
   
  } catch (error) {
    // Show error toast
    throw error;
  }
};
  return (
    <div key={id} class="lg:max-w-2xl max-w-xl px-0  lg:px-8 lg:py-4 py-1 bg-[#d9f1f3] rounded-lg shadow-md hover:scale-105 transition-transform ">
      <div class="mt-2 px-4">
        <div className="flex justify-between  ">


     
       <div>
        <p
          href="/"
          class="lg:text-xl text-sm sm:font-bold  font-semibold text-[#0f3057]  hover:text-gray-600  hover:underline"
          tabindex="0"
          role="link"
        >
          {name}
        </p>
        <p
          href="/"
          class="lg:text-md text-sm sm:font-bold mt-1 mb-1 font-semibold text-[#0f3057]  hover:text-gray-600  hover:underline"
          tabindex="0"
          role="link"
        >
          Gender: {(gender==="0" ? "male" :"female")}
          
        </p>
        </div>
        <div className="flex sm:gap-2  gap-1 py-2">
            <button  onClick={()=>handleButtonClick(id,3)} className="px-2 sm:px-3  sm:py-0 lg:text-sm text-[10px] font-semibold mb-2 text-gray-100 transition-colors duration-300 transform bg-red-500 rounded cursor-pointer hover:bg-red-500"> Reject </button>
            <button onClick={()=>handleButtonClick(id,2)} className="px-2 sm:px-3  sm:py-1 lg:text-sm text-[10px]  font-semibold mb-2 text-gray-100 transition-colors duration-300 transform bg-[#0f3057] rounded cursor-pointer hover:bg-green-500">Shortlist  </button>
            <button onClick={()=>handleButtonClick(id,1)} className="px-2 sm:px-3  sm:py-1 lg:text-sm text-[10px]  font-semibold mb-2 text-gray-100 transition-colors duration-300 transform bg-[#0f3057] rounded cursor-pointer hover:bg-gray-500"> Save </button>
        </div>
        </div>
        <div className="flex-col items-center flex-wrap">
          <div class="flex items-center   mt-1 text-[#11a9b5] ">
            <svg
              aria-label="suitcase icon"
              class="sm:w-6 sm:h-6 h-4 w-4   fill-current"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M14 11H10V13H14V11Z" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7 5V4C7 2.89545 7.89539 2 9 2H15C16.1046 2 17 2.89545 17 4V5H20C21.6569 5 23 6.34314 23 8V18C23 19.6569 21.6569 21 20 21H4C2.34314 21 1 19.6569 1 18V8C1 6.34314 2.34314 5 4 5H7ZM9 4H15V5H9V4ZM4 7C3.44775 7 3 7.44769 3 8V14H21V8C21 7.44769 20.5522 7 20 7H4ZM3 18V16H21V18C21 18.5523 20.5522 19 20 19H4C3.44775 19 3 18.5523 3 18Z"
              />
            </svg>

            <h1 class="sm:px-2 px-1 hidden sm:block sm:text-sm text-[14px] text-gray-600">
              Education: {education ? education : "no data"}
            </h1>
            <h1 class="sm:px-2 px-1 sm:hidden  sm:text-sm text-[14px] text-gray-600">
            {education ? education : "no data"}
            </h1>
          <div class="flex items-center ml-2 mt-1 text-[#11a9b5] ">
            <svg
              aria-label="location pin icon"
              class="sm:w-6 sm:h-6 h-4 w-4  fill-current"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.2721 10.2721C16.2721 12.4813 14.4813 14.2721 12.2721 14.2721C10.063 14.2721 8.27214 12.4813 8.27214 10.2721C8.27214 8.063 10.063 6.27214 12.2721 6.27214C14.4813 6.27214 16.2721 8.063 16.2721 10.2721ZM14.2721 10.2721C14.2721 11.3767 13.3767 12.2721 12.2721 12.2721C11.1676 12.2721 10.2721 11.3767 10.2721 10.2721C10.2721 9.16757 11.1676 8.27214 12.2721 8.27214C13.3767 8.27214 14.2721 9.16757 14.2721 10.2721Z"
                />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.79417 16.5183C2.19424 13.0909 2.05438 7.3941 5.48178 3.79418C8.90918 0.194258 14.6059 0.0543983 18.2059 3.48179C21.8058 6.90919 21.9457 12.606 18.5183 16.2059L12.3124 22.7241L5.79417 16.5183ZM17.0698 14.8268L12.243 19.8965L7.17324 15.0698C4.3733 12.404 4.26452 7.9732 6.93028 5.17326C9.59603 2.37332 14.0268 2.26454 16.8268 4.93029C19.6267 7.59604 19.7355 12.0269 17.0698 14.8268Z"
                />
            </svg>

            <h1 class="sm:px-2 px-1  sm:text-sm text-[14px] text-gray-600">
              {state_name} , {city_name}{" "}
            </h1>
          </div>
                </div>
          <div class="flex items-center mt-3  text-[#11a9b5] ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="sm:w-6 sm:h-6 h-3 w-3   fill-current"
            >
              {" "}
              <path
                fill="#11a9b5"
                d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-224c0-35.3-28.7-64-64-64L80 128c-8.8 0-16-7.2-16-16s7.2-16 16-16l368 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L64 32zM416 272a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
              />
            </svg>

            <h1 class="px-2 sm:text-sm text-[14px] text-gray-800">
      
            <span className="sm:font-bold text-black">Experience: </span> {total_exp_yr? total_exp_yr: "0"} Yrs {total_exp_mn? total_exp_mn: "0" } Mn
            </h1>
            <h1 class="px-2 sm:text-sm text-[14px] text-gray-900">
            
             <span className="sm:font-bold text-black">Notice Period: </span>  {notice_period? notice_period: "0"} 
            </h1>
          </div>
        </div>
      
      </div>
      <div className="px-4 ">
        <div class="flex items-center   border-t-2 border-[#0f3057] justify-between  mt-4">
         <div className="flex gap-8 cursor-pointer">
         <div onClick={initiateCall} class="flex mt-3 items-center">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-8  text-gray-100 transition-colors duration-300  hover:text-gray-100 "><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
          </div>
          <div onClick={openWhatsApp} class="flex mt-3 items-center">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"   class="w-6 h-8  text-gray-100 transition-colors duration-300  hover:text-gray-100 "><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>
          </div>
          <div class="flex mt-3 items-center">
            <Link to={`mailto:${email}`}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-8  text-gray-100 transition-colors duration-300  hover:text-gray-100 "><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
            </Link>
          </div>
         </div>

          <div class="flex mt-3 items-center">
            <p
              class="px-4 sm:px-6 sm:py-2 py-1 lg:text-sm text-[14px] sm:font-bold font-semibold mb-2 text-gray-100 transition-colors duration-300 transform bg-[#0f3057] rounded cursor-pointer hover:bg-gray-500"
              tabindex="0"
              role="button"
              onClick={()=>handleCardClick(id)}
            >
              View profile
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jobcard;
