import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from '../../Utils/Context';
import { toast } from "react-toastify";
const UploadCard = ({
  title,
  isUpdateMode,
  setUpdateMode,
  currentFile,
  fileType,
  onFileUpload,
}) => {
  const [loading,setLoading] = useState(false)

  const {api} = useAuth()
  const [videourl , setvideourl] = useState(null)
  const uploadFileOrVideo = async ({ file_type, uploaded_file, video_url }) => {
    const formData = new FormData();
  
    // Set the file type: 1 for resume, 0 for video
    formData.append("file_type", file_type);
  
    // Conditionally append the file or video URL
    if (file_type === 1 && uploaded_file) {
      // Resume upload
      formData.append("uploaded_file", uploaded_file);
    } else if (file_type === 0 && video_url) {
      // Video upload
      formData.append("video_url", video_url);
    } else {
      toast.error("Invalid. Please provide the correct file or video URL.");
      return;
    }
    console.log(formData)
  
    try {
      setLoading(true)
      const response = await api.post("/api/candidate/save_resume_video", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    console.log(response)
      if (response.status === 200) {
        
        toast.success("Upload successful!");
      } else {
        throw new Error(response.data?.msg || "Upload failed.");
      }
  
      console.log("Upload response:", response.data);
    } catch (error) {
      console.error("Error uploading file/video:", error);
      toast.error("Failed to upload. Please try again.");
    }finally{
      setLoading(false)
      setUpdateMode(false)
    }
  };
  
  return (
    <div className="col-span-1">
      <div className="bg-white p-5 rounded-lg shadow mt-5">
        <div className="flex justify-between w-full">
          <p className="font-bold text-lg mb-2 w-full text-[#11a9b5]">{title}</p>
          {loading && (<div role="status" className=' '>
    <svg aria-hidden="true" class="w-6 h-6 mx-2 text-gray-300 animate-spin  fill-blue-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>)} 

          <button
            className="w-7 h-7"
            onClick={() => setUpdateMode(!isUpdateMode)}
          >
            {isUpdateMode ? (
          
              <svg xmlns="http://www.w3.org/2000/svg"  className="w-7 mx-auto h-7 text-[#11a9b5] transition duration-75"
                 fill="currentcolor" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
            ) : (
              <svg
                className="w-7 mx-auto h-7 text-[#11a9b5] transition duration-75"
                fill="currentcolor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 125.7-86.8 86.8c-10.3 10.3-17.5 23.1-21 37.2l-18.7 74.9c-2.3 9.2-1.8 18.8 1.3 27.5L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z" />
              </svg>
            )}
          </button>
        </div>
        
        <div className="flex flex-wrap gap-2">
          <p className="bg-gray-200  text-gray-800 px-3 py-1">
            Current {fileType==="1"?"resume":"video"}:{" "}
            {fileType === "1" ? (
              <Link to={`/${currentFile}`}>{currentFile?.slice(7)}</Link>
            ) : (
              <Link to={`${currentFile}`}>{currentFile} </Link> || "N/A"
            )}
          </p>

          {isUpdateMode && (
              fileType === "1" ? (

           
            <div className="flex items-center justify-center w-full">
              <label
                className="flex flex-col items-center justify-center w-full h-28 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500">
                    PDF, PNG, JPG, JPEG (MAX. 800x400px)
                  </p>
                </div>
               
                  <input
                  id={`${fileType}-upload`}
                  type="file"
                  className="hidden"
                  onChange={(e) => uploadFileOrVideo({ file_type: 1, uploaded_file: e.target.files[0] , video_url: null })}/>
                
                
                  </label>
                  </div>
                  ): (
                    <>
                    <div class="relative w-full ">
  <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
    <svg class="w-4 h-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
        <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
        <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
    </svg>
  </div>
                    
  <input type="text"  onChange={(e) => setvideourl(e.target.value)} id="input-group-1" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  " placeholder="Enter Video Url"/>
</div>
   <button onClick={()=>uploadFileOrVideo({ file_type: 0, uploaded_file: null , video_url: videourl })} className="bg-blue-900 font-semibold text-white px-2 rounded-lg py-1 mt-2">Sumbit</button>
                    </>

                  ) )}
        </div>
      </div>
    </div>
  );
};

export default UploadCard;
