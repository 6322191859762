import React, { useState ,useEffect } from 'react';
import logo from '../../assets/flogo1.jpeg'
import { Link } from 'react-router-dom';
function SubscriptionModal({ isOpen, onClose }) {
    const [highlightStep, setHighlightStep] = useState(1);

  useEffect(() => {
    if (isOpen) {
      // Cycle through the steps automatically
      const timer = setInterval(() => {
        setHighlightStep((prevStep) => (prevStep<4? prevStep+1: 1));
      }, 3000); // Change step every 2 seconds
      return () => clearInterval(timer);
    }
  }, [isOpen]);

  if (!isOpen) return null; 

  // Close modal and reset step to 1
  const closeModal = () => {
    onClose();
  };

  return (
    
    <div className="fixed  inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
    {/* Modal Background with Spotlight Effect */}
    <div
      className={`relative bg-white sm:h-auto h-[420px] rounded-lg shadow-lg p-6 max-w-md w-full transform transition-transform duration-300 ${
        highlightStep ? 'opacity-100 bg-gray-800' : 'opacity-100'
      }`}
    >
      {/* Highlighted Background */}
      <div className="absolute inset-0 bg-white opacity-90 rounded-lg pointer-events-none"></div>

      {/* Spotlight Effect on Step */}
      <div className="relative z-10">
        {/* Modal Header */}
        <div className="text-center">
        <img
className=" h-8 mb-2 mx-auto sm:h-[40px]"
src={logo}
alt="Logo"
/>
          <p className="text-gray-600 font-semibold sm:mb-4 text-lg sm:text-2xl">Welcome to MySchoolNaukri</p>
        </div>
        <div className="text-center">
          <h2 className="sm:text-xl text-md font-semibold text-gray-700 mb-2">Get Started</h2>
          <h6 className="text-gray-600 text-sm sm:text-lg">Follow these steps to set up your account</h6>
        </div>

        {/* Steps List with Spotlight Effect */}
        <div className="sm:mt-6 mt-2 sm:h-auto h-[200px] sm:space-y-4 space-y-1">
          <div
            className={`sm:p-4 p-2 rounded-lg border-2 transition duration-500 ${
              highlightStep === 1 ? 'border-[#11a9b5]  bg-blue-50 opacity-100 scale-105' : 'border-gray-200 opacity-30'
            }`}
          >
            <h3 className="font-semibold text-sm  sm:text-lg text-gray-700">1. Add Your School Information</h3>
            <p className="text-gray-600 ps-3 text-sm  sm:text-md">Enter details like school name and address.</p>
          </div>
          <div
            className={`p-4 rounded-lg border-2 transition duration-500 ${
              highlightStep === 2 ? 'border-[#11a9b5]  bg-blue-50 opacity-100 scale-105' : 'border-gray-200 opacity-30'
            }`}
          >
            <h3 className="font-semibold text-sm  sm:text-lg text-gray-700">2. Activate Subscription</h3>
            <p className="text-gray-600 ps-3 text-sm  sm:text-md">Complete your subscription activation for premium access.</p>
          </div>
          <div
            className={`p-4 rounded-lg border-2 transition duration-500 ${
              highlightStep === 3 ? 'border-[#11a9b5]  bg-blue-50 opacity-100 scale-105' : 'border-gray-200 opacity-30'
            }`}
          >
            <h3 className="font-semibold text-sm  sm:text-lg text-gray-700">3 Post jobs</h3>
            <p className="text-gray-600 ps-3 text-sm  sm:text-md">You are ready to post jobs and hire talents.</p>
          </div>
        </div>

        {/* Footer Button */}
        <div className="mt-10 flex justify-end">
             
          <Link to="/dashboard/school" onClick={closeModal}
            className="bg-blue-900 text-white  sm:py-2 px-4 rounded-md hover:bg-blue-600 transition">
            Done
          </Link>
        </div>
      </div>
    </div>
  </div>
  )
}
export default SubscriptionModal;

// Keyframe animation for modal fade-in
