import React from 'react'

function Statsprofile() {
  return (
<div className="flex w-full  gap-4 mb-4 jus ">
        <div class="w-full p-6 text-center   bg-white border border-gray-200 rounded-lg shadow :bg-gray-800 :border-gray-700">
          <svg
            className="w-7 mx-auto h-7 text-[#11a9b5] transition duration-75  group-hover:text-gray-100 :group-hover:text-white"
            fill="currentcolor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z" />
          </svg>

          <p class=" font-semibold text-gray-900 :text-gray-400">35</p>
          <p class=" font-semibold text-gray-900 :text-gray-400">Saved jobs</p>
        </div>
        <div class="w-full p-6 text-center bg-white border border-gray-200 rounded-lg shadow :bg-gray-800 :border-gray-700">
          <svg
            className="w-7 mx-auto h-7 text-[#11a9b5] transition duration-75  group-hover:text-gray-100 :group-hover:text-white"
            fill="currentcolor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path d="M249.6 471.5c10.8 3.8 22.4-4.1 22.4-15.5l0-377.4c0-4.2-1.6-8.4-5-11C247.4 52 202.4 32 144 32C93.5 32 46.3 45.3 18.1 56.1C6.8 60.5 0 71.7 0 83.8L0 454.1c0 11.9 12.8 20.2 24.1 16.5C55.6 460.1 105.5 448 144 448c33.9 0 79 14 105.6 23.5zm76.8 0C353 462 398.1 448 432 448c38.5 0 88.4 12.1 119.9 22.6c11.3 3.8 24.1-4.6 24.1-16.5l0-370.3c0-12.1-6.8-23.3-18.1-27.6C529.7 45.3 482.5 32 432 32c-58.4 0-103.4 20-123 35.6c-3.3 2.6-5 6.8-5 11L304 456c0 11.4 11.7 19.3 22.4 15.5z" />
          </svg>

          <p class=" font-semibold text-gray-500 :text-gray-400">55</p>
          <p class=" font-semibold text-gray-500 :text-gray-400">
            Applied Jobs
          </p>
        </div>
        <div class="w-full p-6 text-center bg-white border border-gray-200 rounded-lg shadow :bg-gray-800 :border-gray-700">
          <svg
            className="flex-shrink-0 mx-auto w-7 h-7 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z" />
            <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z" />
            <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z" />
          </svg>

          <p class=" font-semibold text-gray-500 :text-gray-400">3</p>
          <p class=" font-semibold text-gray-500 :text-gray-400">
            Invited Jobs
          </p>
        </div>
      </div>  )
}

export default Statsprofile