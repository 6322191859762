import React, { useState } from "react";

import logo from "../assets/flogo1.jpeg";
import { Link } from "react-router-dom";
// import Jobpostmodal from "./components/Jobpostmodal";
function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <nav className="bg-[#11a9b5] shadow-sm fixed w-full z-40 py-2 ">
      <div className=" mx-auto px-4 sm:px-4 lg:px-0">
        <div className="flex sm:justify-between  h-16">
          <button className="relative sm:hidden  flex mx-4 z-50 items-center px-2 py-2 border  text-white border-transparent text-sm font-medium   rounded-3xl hover:text-black   ">
            <img
              class="object-cover w-12 h-12 rounded-full"
              src={logo}
              alt=""
              loading="lazy"
            />
          </button>
          <h2 className="text-white text-xl mt-4 font-bold sm:ml-[19rem]">
            Admin Dashboard
          </h2>

          <div className="hidden sm:flex gap-x-3 px-4 sm:items-center">
            {/* <Jobpostmodal /> */}
            {/* Dropdown toggle button */}
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="relative flex z-50 items-center px-2 py-2 border text-white border-transparent text-sm font-medium   rounded-3xl hover:text-black   "
            >
              <img
                class="object-cover w-fit h-10 rounded-full"
                src={logo}
                alt=""
                loading="lazy"
              />
              <svg
                className="w-5 h-5 mx-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z"
                  fill="currentColor"
                />
              </svg>
            </button>

            {/* Dropdown menu */}
            {isOpen && (
              <div
                className="absolute right-0 z-60 sm:w-56 py-2 mt-2 sm:top-20 bg-white rounded-md shadow-xl "
                onClick={() => setIsOpen(false)}
              >
              

                <a
                  href="#"
                  className="flex items-center p-3 text-sm text-gray-800 transition-colors duration-300 transform  hover:bg-gray-300 "
                >
                  <svg
                    className="w-5 h-5 mx-1"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 19H3C1.89543 19 1 18.1046 1 17V16H3V7C3 5.89543 3.89543 5 5 5H19C20.1046 5 21 5.89543 21 7V16H23V17C23 18.1046 22.1046 19 21 19ZM5 7V16H19V7H5Z"
                      fill="currentColor"
                    />
                  </svg>
                  <span className="mx-1">Log Out</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
