import React from 'react'
import ViewallCard from '../components/ViewallCard'

function ViewallCourses() {
  return (
<section class="bg-white :bg-gray-900 px-4">
    <div class="container px-2 sm:px-6 py-10 mx-auto">
    <h1 class=" text-3xl font-bold tracking-tight leading-none text-blue-950 "><span className='text-[#11a9b5]'>All  </span>  Courses</h1>

        <div class="grid grid-cols-1 gap-6 mt-8 md:mt-16 md:grid-cols-1">
            
       {[...Array(8)].map((_,i)=>(       
            <div key={i}>

               <ViewallCard/>
           </div>
       ))}
           


        </div>
    </div>



</section>  )
}

export default ViewallCourses