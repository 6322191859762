import React, { useState,useEffect } from 'react'
import { useAuth } from '../../Utils/Context';
import { toast } from "react-toastify";
import VirtualizedMenuList from '../../Utils/VirtualizedMenuList';
import Select from "react-select";
import { Designation } from '../../dashboard/components/Options';
function Experiencedetails({expdata}) {
    const [employmentStatus, setEmploymentStatus] = useState("");
    const [loading,setLoading] = useState(false)

    const{options3, isLoading3} = Designation()
const [isEditing2,setIsEditing2] = useState(false)
const {api} = useAuth();
const [Experience, setexperience] = useState({
  EmployementID: "",
  currentEmployement: "",
  totalExpYr: null,
  currentOrg: "",
  currentDesignation: "",
  noticePeriod: "",
});

useEffect(() => {
  if (expdata) {
    setexperience({
      EmployementID: expdata.employment_details_id || "",
      currentEmployement: expdata.current_employment || "",
      totalExpYr: expdata.total_exp_yr || null,
      currentOrg: expdata.current_org || "",
      currentDesignation: expdata.current_designation || "",
      noticePeriod: expdata.notice_period || "",
    });
  }
}, [expdata]); // Runs whenever expdata changes
const handleInputChange = (e) => {
    const { name, value } = e.target;
    setexperience((prev) => ({ ...prev, [name]: value }));
};
  // Update candidate location when a select value is chosen
  const handleLocationChange = (option) => {
    setexperience((prev) => ({ ...prev, currentDesignation: option ? option.value : null }));
  };
  const ExperienceDetails = async () => {
    setLoading(true)
    try {
      const response = await api.post('/api/candidate/save_experience_details', Experience);
      if (response.data.msg === "Success") {
        toast.success("Experience details Updated Successfully");
 
        // Since the API doesn't return the updated data, manually set the state as saved
       window.location.reload()
      } else {
        throw new Error("Failed to update profile");
      }
  
      console.log("Education saved successfully:", response);
    } catch (error) {
      console.error("Error saving education data:", error);
      toast.error('Something went wrong!')

    }
    finally{
        setIsEditing2(false)
        setLoading(false)
    }
  };
  return (
<div className="bg-white p-5 rounded-lg shadow mb-5">
              <div className="flex justify-between  w-full">
                <span className=" font-bold text-lg mb-2 text-[#11a9b5]">
                  Experience Details:
                </span>
                {loading && (<div role="status" className=' '>
    <svg aria-hidden="true" class="w-6 h-6 mx-2 text-gray-300 animate-spin  fill-blue-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>)} 
                <button
                  className="w-7 h-7"
                  onClick={() => setIsEditing2(true)}
                >
                  {isEditing2 ? (
                    <svg
                      className="w-7 mx-auto h-7 text-[#11a9b5] transition duration-75  "
                      fill="currentcolor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      onClick={ExperienceDetails}
                    >
                      <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z" />
                    </svg>
                  ) : (
                    <svg
                      className="w-7 mx-auto h-7 text-[#11a9b5] transition duration-75  "
                      fill="currentcolor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 125.7-86.8 86.8c-10.3 10.3-17.5 23.1-21 37.2l-18.7 74.9c-2.3 9.2-1.8 18.8 1.3 27.5L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z" />
                    </svg>
                  )}
                </button>
              </div>

              <div className="mb-3">
                {isEditing2 && (
                  <div className="mb-6">
                    <label class="block mb-2 text-md text-[#0f3057] font-semibold  ">
                      Employment Status{" "}
                      <span className="text-red-600"> * </span>
                    </label>
                    <select
                      name="currentEmployement"
                      value={Experience?.currentEmployement}
                      onChange={handleInputChange}
                      className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg 0 focus:border-[#11a9b5]  focus:ring-[#11a9b5] focus:outline-none focus:ring focus:ring-opacity-40"
                    >
                      <option
                        value={
                            Experience?.currentEmployement === 1
                            ? "experienced"
                            : "fresher"
                        }
                      >
                        Select Employment Status
                      </option>
                      <option value="0">Fresher</option>
                      <option value="1">Experienced</option>
                    </select>
                  </div>
                )}
                {expdata?.current_employment === 1 ? (
                  <div className="space-y-2">
                    <p className="text-gray-800">
                      <span className="font-medium text-[#11a9b5]">
                        current org:
                      </span>
                      {isEditing2 ? (
                        <input
                          type="text"
                          name="currentOrg"
                          value={Experience?.currentOrg}
                          onChange={handleInputChange}
                          className="border border-gray-300 rounded mx-2 px-2 py-1"
                          placeholder="Preferred Job Location"
                        />
                      ) : (
                        <span className="text-gray-800 font-normal px-3">
                          {expdata?.current_org || "N/A"}{" "}
                        </span>
                      )}
                    </p>

                    <p className="text-gray-800">
                      <span className="font-medium text-[#11a9b5]">
                        job profile:
                      </span>
                      {isEditing2 ? (
                       <Select
                       className="py-2 text-gray-800 w-full"
                       options={options3}
                       name="education"
                       isLoading={isLoading3}
                       value={
                        options3.find((option) => option.value === Experience?.currentDesignation) || null
                         }
                         onChange={handleLocationChange}
                       placeholder="select Job Profile"
                       components={{ MenuList: VirtualizedMenuList }}
                       isClearable
                     />
                      ) : (
                        <span className="text-gray-800 font-normal px-3">
                          {expdata?.name || "N/A"}{" "}
                        </span>
                      )}
                    </p>
                    <p className="text-gray-800">
                      <span className="font-medium text-[#11a9b5]">
                        total exp yr :
                      </span>
                      {isEditing2 ? (
                        <input
                          type="text"
                          name="totalExpYr"
                          value={Experience?.totalExpYr}
                          onChange={handleInputChange}

                          className="border border-gray-300 rounded mx-2 px-2 py-1"
                          placeholder="Preferred Job Location"
                        />
                      ) : (
                        <span className="text-gray-800 font-normal px-3">
                          {expdata?.total_exp_yr || "N/A"}{" "}
                        </span>
                      )}
                    </p>

                    <p className="text-gray-800">
                      <span className="font-medium text-[#11a9b5]">
                        notice period :
                      </span>
                      {isEditing2 ? (
                        <input
                          type="text"
                          name="noticePeriod"
                          value={Experience?.noticePeriod}
                          onChange={handleInputChange}

                          className="border border-gray-300 rounded mx-2 px-2 py-1"
                          placeholder="Preferred Job Location"
                        />
                      ) : (
                        <span className="text-gray-800 font-normal px-3">
                          {expdata?.notice_period || "N/A"}{" "}
                        </span>
                      )}
                    </p>
                  </div>
                ) : (
                  <span className="font-medium text-[#11a9b5]">
                    Employment status :{" "}
                    <span className="text-gray-900"> Fresher </span>
                  </span>
                )}
              </div>
            </div>  )
}

export default Experiencedetails