import React, { useState } from 'react';
import backgroundImage from '../../assets/resumetemp.png'
const Videoresume = ({ videoLink, resumeLink }) => {
    const[show , setshow] = useState(false)
  // Function to render the video based on the type of link
  const renderVideo = () => {
    if (!videoLink) return <p className="text-red-500">Video not available</p>;

    // Check for YouTube link
    if (videoLink.includes('youtube.com') || videoLink.includes('youtu.be')) {
      return (
        <iframe
          className="w-full h-64 md:h-80"
          src={videoLink.replace('watch?v=', 'embed/')}
        // src="https://www.youtube.com/embed/5WxIq6M-dWk"
          title="MySchoolNaukri: A platform for the teachers, of the teachers, by the teachers" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" 
         
          allowFullScreen
        />
      );
    }

    // Check for Drive link
    if (videoLink.includes('drive.google.com')) {
      return (
        <video className="w-full h-64 md:h-80" controls>
          <source src={videoLink} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    return <p className="text-red-500">profile video not available</p>;
  };

  // Function to render the resume based on the file type
  const renderResume = (resumeLink) => {
    
    if (!resumeLink) return <p className="text-red-500">Resume not available</p>;

    // Check for image types
    if (resumeLink.endsWith('-png') || resumeLink.endsWith('-jpeg') || resumeLink.endsWith('-jpg')) {
      return <img src={`https://info.myschoolnaukri.com/images/${resumeLink}`} alt="Resume" className="w-full max-h-screen object-contain" />;
    }

    // Check for PDF type
    if (resumeLink.endsWith('.pdf')) {
      return (
        <iframe
          className="w-full h-screen"
          src={`https://info.myschoolnaukri.com/images/${resumeLink}`}
          title="PDF Resume"
          frameBorder="0"
          allowFullScreen
        />
      );
    }

    return <p className="text-red-500">Invalid resume link</p>;
  };

  return (
    <div className="container w-full gap-4 flex sm:flex-nowrap flex-wrap px-0 py-4 ">
      <div className="bg-white h-fit w-[500px] p-5 rounded-lg shadow">
        <h2 className="text-xl text-[#11a9b5]  font-bold  mb-3">Candidate Video</h2>
        {renderVideo()}
      </div>
      <div className="bg-white p-5 w-[700px] rounded-lg shadow">
        <div className='flex justify-between'>

        <h2 className="text-xl text-[#11a9b5]  font-bold  mb-3">Candidate Resume</h2>
        {show && (

     
        <button onClick={()=>setshow(!show)} class="sm:text-2xl text-xl  title-font mb-2 text-white">
              <span  className="text-black px-3 rounded-lg">x</span>{" "}
            </button>
               )}
        </div>
        {show?renderResume(resumeLink):
        
        <div
        class="w-full sm:bg-cover  bg-cover h-[20rem] "
        style={{
            backgroundImage: `url(${backgroundImage})`, // Use the imported image
          }}
      >
        <div class="flex items-center justify-center w-full h-full bg-gray-900/70">
          <div class="flex flex-wrap w-full mb-4 sm:mb-10 flex-col items-center text-center">
            <button onClick={()=>setshow(!show)} class="sm:text-xl text-xl font-bold title-font mb-2 text-white">
              <span  className="bg-[#11a9b5] px-3 py-1 rounded-lg">View Resume</span>{" "}
            </button>
          
          </div>
        </div>
      </div>}
        
      </div>
    </div>
  );
};

export default Videoresume;
