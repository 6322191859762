import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const Fetchdata = async (url) => {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};


const fetchData = async (url) => {
  const response = await axios.get(url);
  return response.data;
};
const postData = async (url) => {
  const response = await axios.post(url);
  return response.data;
};

export const useFetchData = (url, queryKey) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: () => fetchData(url),
    retry: 3,
    retryDelay: 1000,
    onError: (error) => {
      console.error("Error fetching data:", error);
    }
  });
};
export const usePostData = (url, queryKey) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: () => postData(url),
    retry: 3,
    retryDelay: 1000,
    onError: (error) => {
      console.error("Error fetching data:", error);
    }
  });
};