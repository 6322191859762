import React from 'react'

function Fraud() {
  return (
    <div className="container mx-auto px-4 py-10">
      <h1 className="text-4xl font-bold text-center mb-10"><span className='text-[#11a9b5]'> Fraud</span> Alert</h1>
      <div className="prose mx-auto max-w-4xl text-justify">
      <h2 className="text-2xl font-semibold mb-4">Welcome to <span className='text-[#11a9b5]'> MySchoolNaukri</span>  Fraud & Safety</h2>
      <p>We value the trust that you place in MySchoolNaukri and are working the best to keep your job search safe and fraud free. However, in wake of increasing instances of scam and fraud we recommend you to be cautious of fraudulent emails or calls</p>
      
      <h2 className="text-2xl font-semibold mb-4 mt-4">6 signs to  <span className='text-[#11a9b5]'> identify </span>fake job offers:</h2>
      <p>Asking for money (mostly refundable).  <br/>
Looking for confidential information ( Credit Card / OTP / Aadhar / PAN, etc). <br/>
Badly written job ads (incorrect grammar). <br/>
Offering easy hiring criteria (on the spot offer, no interview). <br/>
Luring with salary beyond expectation <br/></p>

      <h2 className="text-2xl font-semibold mb-4 mt-4">4 ways to  <span className='text-[#11a9b5]'> protect</span> yourself against job frauds:</h2>
      <p>Never respond without verifying the legitimacy of the job offer.  <br/>
Do not share personal/bank account details with anyone. <br/>
Refuse to make any payment for job offers. <br/>
Verify online presence and reviews of the recruitment firm <br/>.</p>

      </div>

    </div>
  )
}

export default Fraud