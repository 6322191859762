import React ,{useEffect, useState} from 'react'
import Searchbarmaster from './Searchbarmaster'
import axios from 'axios';
import Loading from '../../Utils/Loading';
function Mastertable({ data: initialData }) {
  const [data, setData] = useState(initialData); // Store original data
  const [filteredData, setFilteredData] = useState(initialData); // Store filtered data
  

  // Sync the state when props change (for dynamic data passed from parent)
  useEffect(() => {
    setData(initialData);
    setFilteredData(initialData);
  }, [initialData]); // Re-run whenever initialData changes


    
  const handleSearch = (searchQuery) => {
    const filteredData = data.filter((item) => {
      return item.label.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredData(filteredData);
  };

  const handleDelete = async (id) => {
    // Immediately remove the row from the UI by filtering it out of the filteredData
     // Set the ID of the row being deleted to trigger animation

    

    try {
      // Send DELETE request to the API
     const response =  await axios.get(`https://info.myschoolnaukri.com/api/master/delete_master?id=${id}`);

      // Once the API call is successful, update the data state to remove the row
      if(response){
        const updatedFilteredData = filteredData.filter((item) => item.value !== id);
        setFilteredData(updatedFilteredData);
    
      const updatedData = data.filter((item) => item.value !== id);
      setData(updatedData);

      // Reset the deletingRow state after deletion is complete
      alert('data added Successfully')
     
    }
    } catch (error) {
      console.error('Error deleting item:', error);
      
    }
  };

if(!data) return <Loading/>
// console.log(data)
  return (
   <>
   <div className='flex justify-end -mt-[50px]'>

  <Searchbarmaster  onSearch={handleSearch}/>
   </div>
    <div className="overflow-y-auto max-h-96">
            <table class=" mx-auto w-full text-sm text-left rtl:text-right text-gray-500 ">
              <thead class="text-xs text-gray-700 uppercase bg-gray-200 ">
                <tr>
                  <th scope="col" class="p-4 ">
                    S.No.
                  </th>
                  <th scope="col" class=" px-6 py-3">
                    name
                  </th>
                  <th scope="col" class=" px-6 py-3">
                    Edit
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((label, index) => (
                  <tr key={label.value}   className="bg-white border-b hover:bg-gray-200"
                >
                    <td class="w-4 py-1 px-6">{index + 1}</td>
                    <th
                      scope="row"
                      class="px-6 py-1  border-b font-medium text-gray-900 whitespace-nowrap "
                    >
                      {label.label} 
                    </th>
                    <th
                      scope="row"
                      class="px-6 py-1  border-b font-medium text-white whitespace-nowrap "
                    >
                      <button class="font-medium text-white bg-[#11a9b5] mb-4 px-3 py-1 mt-4 rounded-lg ">
                        Edit
                      </button>
                    </th>
                    <th
                      scope="row"
                      class="px-6 py-1   border-b font-medium text-white whitespace-nowrap "
                    >
                    <button
                  onClick={() => handleDelete(label.value)}
                  className="font-medium text-white bg-red-500 mb-4 px-3 py-1 mt-4 rounded-lg"
                >
                  Delete
                </button>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          </>
  )
}

export default Mastertable