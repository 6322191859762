import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const DeleteAccount = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const onSubmit = (data) => {
    setConfirmationMessage('Account deletion request received. We’ll contact you shortly.');
  };

  return (
    <div className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-md mt-10">
      <h2 className="text-2xl font-semibold text-center text-red-600 mb-6">Delete Account</h2>
      <p className="text-center text-gray-600 mb-8">To proceed, please confirm your email address associated with this account.</p>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <label htmlFor="email" className="block text-gray-700 font-medium mb-2">Email Address</label>
          <input
            type="email"
            id="email"
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Please enter a valid email address'
              }
            })}
            className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${
              errors.email ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-blue-500'
            }`}
            placeholder="you@example.com"
          />
          {errors.email && (
            <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
          )}
        </div>

        <button
          type="submit"
          className="w-full bg-red-600 hover:bg-red-700 text-white font-semibold py-2 rounded-lg transition-colors"
        >
          Confirm Deletion
        </button>
      </form>

      {confirmationMessage && (
        <div className="mt-6 p-4 bg-blue-50 border border-blue-200 text-blue-700 rounded-lg">
          <p>{confirmationMessage}</p>
        </div>
      )}
    </div>
  );
};

export default DeleteAccount;
