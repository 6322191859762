import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export const fetchCandidates = async (page, filters) => {
  // Start with the base URL
  // let url = `https://info.myschoolnaukri.com/api/candidate/job_list?page=${page}`;

  // Build the query parameters string based on the filter values
  const { designation, location, gender,status,state_name } = filters;

  // Create a URLSearchParams object to append query parameters
  const queryParams = new URLSearchParams();

  // Append filters as query parameters (if they exist)
  if (filters.designation)
    queryParams.append("designation", filters.designation);
  if (filters.location) queryParams.append("location", filters.location);
  if (filters.gender) queryParams.append("gender", filters.gender);
  if (filters.state_name) queryParams.append("state_name", filters.state_name);
  if (filters.status) queryParams.append("status", filters.status);

  // Optionally, you can also append experience filters to the query string

  // Add pagination to query string
  queryParams.append("page", page);

  // Prepare the body (if needed by the API)
  const body = {
    location: location || null,
    designation: designation || null,
    gender: gender || null,
    state_name: state_name || null,
    status: status|| null,

    page,
  };
  console.log(body)
  console.log(queryParams.toString())
  const token = localStorage.getItem("token");
  const response = await fetch(
    `https://info.myschoolnaukri.com/api/employer_candidate_list?${queryParams.toString()}`,
    {
      method: "POST",

      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Include the Bearer token
      },
      body: JSON.stringify(body),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch candidates");
  }

  return response.json();
};

export const Fetchjobs = async (filters) => {
  


  // Build the query parameters string based on the filter values
  const {
    designation,
    location,
    experience,
    education,
    workMode,
    specilization,
  } = filters;

  // Create a URLSearchParams object to append query parameters
  const queryParams = new URLSearchParams();

  // Append filters as query parameters (if they exist)
  if (filters.designation)
    queryParams.append("designation", filters.designation);
  if (filters.location) queryParams.append("location", filters.location);
  if (filters.experience) queryParams.append("experience", filters.experience);
  if (filters.education) queryParams.append("education", filters.education);
  if (filters.workMode) queryParams.append("workMode", filters.workMode);
  if (filters.specilization)
    queryParams.append("specilization", filters.specilization);

  // Optionally, you can also append experience filters to the query string

  // Prepare the body (if needed by the API)
  const body = {
    location: location || null,
    designation: designation || null,
    experience: experience || null,
    education: education || null,
    workMode: workMode || null,
    specilization: specilization || null,
  };
  
  const token = localStorage.getItem("token");
  const response = await fetch(
    `https://info.myschoolnaukri.com/api/candidate/job_list?employer=1?${queryParams.toString()}`,
    {
      method: "POST",

      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Include the Bearer token
      },
      body: JSON.stringify(body),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch jobs");
  }

  return response.json();
};

// Function to send data to API with query parameters and a bearer token
export const updateCandidateStatus = async (candidateId, status) => {
  const token = localStorage.getItem("token"); // Replace with the actual bearer token

  try {
    const response = await axios.post(
      "https://info.myschoolnaukri.com/api/employer/candidate_selection_status",
      {
        candidate_id: candidateId,
        status: status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Authorization header with the token
        },
      }
    );

    if (status === 1)
      return toast.success("Candidate has been saved successfully!");
    if (status === 2)
      return toast.success("Candidate has been Shortlisted successfully!");
    if (status === 3)
      return toast.info("Candidate has been rejected successfully!");

    return response.data;
  } catch (error) {
    // Show error toast
    if (status) {
      toast.error("Failed to update candidate status. Please try again.");
    }
    throw error;
  }
};
export const ShowCandidateStatus = async (status) => {
  const token = localStorage.getItem("token"); // Replace with the actual bearer token

  try {
    const response = await axios.post(
      "https://info.myschoolnaukri.com/api/employer_candidate_list",
      {
        status: status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Authorization header with the token
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (error) {
    // Show error toast

    toast.error("Failed to update candidate status. Please try again.");

    throw error;
  }
};
