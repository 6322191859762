import React from 'react'

function Userimport() {
  return (
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg px-4 py-10">
    <h2 className='mb-4 font-bold text-xl px-2'>User Import</h2>
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 :text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-200 :bg-gray-700 :text-gray-400">
                <tr>
                    <th scope="col" class="p-4">
                      S.No.
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Name
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Mobile
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Email
                    </th>
                    <th scope="col" class="px-6 py-3">
                    Gender
                    </th>
                    <th scope="col" class="px-6 py-3">
                       Login Status
                    </th>
                    <th scope="col" class="px-6 py-3">
                    Login Date
                    </th>
                </tr>
            </thead>
            <tbody>
            <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-gray-200 :hover:bg-gray-600">
                    <td class="w-4 p-4">
                        2
                    </td>
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white">
                    Dhanashree Argade
                    </th>
                    <td class="px-6 py-4">
                    9923914336
                    </td>
                    <td class="px-6 py-4">
                    dhanashreeargade8_kek@indeedemail.com
                    </td>
                    
                    <td class="px-6 py-4">
                    Female
                    </td>
                    <td class="px-6 py-4">
                    Pending
                    </td>
                    <td class="px-6 py-4">
                    12/34/5220
                    </td>
                   
                </tr>
            <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-gray-200 :hover:bg-gray-600">
                    <td class="w-4 p-4">
                        2
                    </td>
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white">
                    Dhanashree Argade
                    </th>
                    <td class="px-6 py-4">
                    9923914336
                    </td>
                    <td class="px-6 py-4">
                    dhanashreeargade8_kek@indeedemail.com
                    </td>
                    
                    <td class="px-6 py-4">
                    Female
                    </td>
                    <td class="px-6 py-4">
                    Pending
                    </td>
                    <td class="px-6 py-4">
                    12/34/5220
                    </td>
                   
                </tr>
            <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-gray-200 :hover:bg-gray-600">
                    <td class="w-4 p-4">
                        2
                    </td>
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white">
                    Dhanashree Argade
                    </th>
                    <td class="px-6 py-4">
                    9923914336
                    </td>
                    <td class="px-6 py-4">
                    dhanashreeargade8_kek@indeedemail.com
                    </td>
                    
                    <td class="px-6 py-4">
                    Female
                    </td>
                    <td class="px-6 py-4">
                    Pending
                    </td>
                    <td class="px-6 py-4">
                    12/34/5220
                    </td>
                   
                </tr>
            <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-gray-200 :hover:bg-gray-600">
                    <td class="w-4 p-4">
                        2
                    </td>
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white">
                    Dhanashree Argade
                    </th>
                    <td class="px-6 py-4">
                    9923914336
                    </td>
                    <td class="px-6 py-4">
                    dhanashreeargade8_kek@indeedemail.com
                    </td>
                    
                    <td class="px-6 py-4">
                    Female
                    </td>
                    <td class="px-6 py-4">
                    Pending
                    </td>
                    <td class="px-6 py-4">
                    12/34/5220
                    </td>
                   
                </tr>
            <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-gray-200 :hover:bg-gray-600">
                    <td class="w-4 p-4">
                        2
                    </td>
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white">
                    Dhanashree Argade
                    </th>
                    <td class="px-6 py-4">
                    9923914336
                    </td>
                    <td class="px-6 py-4">
                    dhanashreeargade8_kek@indeedemail.com
                    </td>
                    
                    <td class="px-6 py-4">
                    Female
                    </td>
                    <td class="px-6 py-4">
                    Pending
                    </td>
                    <td class="px-6 py-4">
                    12/34/5220
                    </td>
                   
                </tr>
            <tr class="bg-white border-b :bg-gray-800 :border-gray-700 hover:bg-gray-200 :hover:bg-gray-600">
                    <td class="w-4 p-4">
                        2
                    </td>
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap :text-white">
                    Dhanashree Argade
                    </th>
                    <td class="px-6 py-4">
                    9923914336
                    </td>
                    <td class="px-6 py-4">
                    dhanashreeargade8_kek@indeedemail.com
                    </td>
                    
                    <td class="px-6 py-4">
                    Female
                    </td>
                    <td class="px-6 py-4">
                    Pending
                    </td>
                    <td class="px-6 py-4">
                    12/34/5220
                    </td>
                   
                </tr>
            </tbody>
        </table>
        <nav class="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4" aria-label="Table navigation">
            <span class="text-sm font-normal text-gray-500 :text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">Showing <span class="font-semibold text-gray-900 :text-white">1-10</span> of <span class="font-semibold text-gray-900 :text-white">1000</span></span>
            <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                <li>
                    <a href="#" class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-200 hover:text-gray-700 :bg-gray-800 :border-gray-700 :text-gray-400 :hover:bg-gray-700 :hover:text-white">Previous</a>
                </li>
                <li>
                    <a href="#" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-200 hover:text-gray-700 :bg-gray-800 :border-gray-700 :text-gray-400 :hover:bg-gray-700 :hover:text-white">1</a>
                </li>
                <li>
                    <a href="#" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-200 hover:text-gray-700 :bg-gray-800 :border-gray-700 :text-gray-400 :hover:bg-gray-700 :hover:text-white">2</a>
                </li>
                <li>
                    <a href="#" aria-current="page" class="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 :border-gray-700 :bg-gray-700 :text-white">3</a>
                </li>
                <li>
                    <a href="#" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-200 hover:text-gray-700 :bg-gray-800 :border-gray-700 :text-gray-400 :hover:bg-gray-700 :hover:text-white">4</a>
                </li>
                <li>
                    <a href="#" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-200 hover:text-gray-700 :bg-gray-800 :border-gray-700 :text-gray-400 :hover:bg-gray-700 :hover:text-white">5</a>
                </li>
                <li>
            <a href="#" class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-200 hover:text-gray-700 :bg-gray-800 :border-gray-700 :text-gray-400 :hover:bg-gray-700 :hover:text-white">Next</a>
                </li>
            </ul>
        </nav>
    </div>
  )
}

export default Userimport