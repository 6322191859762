import React, { useState } from "react";

function Pageination({ data, currentPage, setCurrentPage, jobsPerPage,totalpage,firstpage ,lastpage , Links })  {
  // Calculate total pages
  const totalPages = Math.ceil(totalpage / jobsPerPage);
  

 
  // Handle pagination button clicks
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling
    });
  };

  const getPageNumbers = () => {
    let pageNumbers = [];
  
    if (totalPages <= 5) {
      // Show all pages if total pages is less than or equal to 5
      pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
    } 
    else if (currentPage <= 3) {
      // Show pages 1, 2, 3, 4, and the last page
      pageNumbers = [1, 2, 3, 4, '...', totalPages];
    } 
    else if (currentPage >= totalPages - 2) {
      // Show pages 1, and the last 4 pages if close to the end
      pageNumbers = [1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    } 
    else {
      // Show pages 1, and current page with its neighbors
      pageNumbers = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
    }
  
    return pageNumbers;
  };

  return (
    <div className="flex justify-center sm:w-[600px] sm:mr-10 sm:gap-6 sm:mx-10 mt-4">
      <button
        onClick={handlePrevPage}
        className="text-white bg-[#0f3057] border-0 sm:py-2 sm:px-8 px-2 py-1 focus:outline-none text-md hover:bg-[#3a3d41] rounded sm:text-lg"
        disabled={currentPage === 1}
      >
        Prev
      </button>

      {/* Display page numbers */}
      {getPageNumbers().map((number, index) => (
        <button
          key={index}
          onClick={() => handlePageClick(number)}
          className={
            number === currentPage
              ? 'active text-white font-medium bg-black py-1 px-4 rounded-full'
              : 'text-gray-700 hover:bg-gray-200 py-1 px-4 rounded'
          }
        >
          {number}
        </button>
      ))}
      {/* {Links.map((number, index) => (
        <button
          key={index}
          onClick={() => handlePageClick(number.label)}
          className={
            number.active === true
              ? 'active text-white font-medium bg-black py-1 px-4 rounded-full'
              : 'text-gray-700 hover:bg-gray-200 py-1 px-4 rounded'
          }
        >
          {number.label}
        </button>
      ))} */}

      <button
        onClick={handleNextPage}
        className="text-white bg-[#0f3057] border-0 sm:py-2 sm:px-8 px-2 py-1 focus:outline-none text-md hover:bg-[#3a3d41] rounded sm:text-lg"
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
}

export default Pageination;
