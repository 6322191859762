import React ,{useEffect,useState} from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../../Utils/Context';
import axios from 'axios';
import Jobcardc from '../components/Jobcardc';
import Candifilter from '../components/Candifilter';
import SkeletonCard from '../../dashboard/components/Skeleton';
import Savedfilter from '../components/Savedfilter';
import { useNavigate } from 'react-router-dom';
function SavedC() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 const{api} = useAuth()
 const navigate = useNavigate()
//  const token = localStorage.getItem('token');
//  console.log(token)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token'); // Replace with your actual token

        const response = await api.get('/api/candidate/save_job_list', {
          headers: {
            Authorization: `Bearer ${token}`, // Include the Bearer token
            'Content-Type': 'application/json',
          },
        });

        setData(response.data); // Set the response data to state
        setLoading(false); // Set loading to false once data is fetched
      } catch (err) {
        setError(err);
        setLoading(false); // Stop loading on error
      }
    };

    fetchData();
  }, []); // Empty dependency array to run only once when the component mounts
 
  if (loading) {
    return <SkeletonCard/>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  
console.log(data)
  const handleApplyFilters = (filters) => {
    // Assuming `filters` is an object like { designation: 'Engineer', experience: '3' }
    const queryParams = new URLSearchParams(filters).toString();
    navigate(`/candidatedashboard/jobs?${queryParams}`);
  };
 
  const saveArray = Array.isArray(data) ? data : data?.jobs || [];

  if (!Array.isArray(saveArray) || saveArray.length === 0) {
    return <div className='text-center text-red-400'>No save jobs found.</div>;
  }
  return (
    <div>
     
      <div className="mb-4 mt-2  justify-center   sm:bg-slate-50 flex flex-wrap border-gray-600 ">
        <h2 className='text-2xl font-semibold mt-2'>Saved Jobs </h2>
        <div className="grid sm:grid-cols-3 sm:gap-20 mt-0 sm:mt-8  mb-4">
          <div className="col-span-1     mt-[0px] w-full sm:px-0 px-12 -ml-[40px] sm:ml:-[230px] sm:ml-0 sm:mt-0 sm:block hidden sm:z-0 z-30">
            <Savedfilter onApplyFilters={handleApplyFilters} />
          </div>
          <div className="space-y-4 mx- sm:mx-0 sm:mt-0 mt-10 col-span-2">
            {saveArray.map((job) => (
              <Jobcardc key={job.id}
                id={job.id}
                state_name={job.state_name}
                name={job.name}
                school_name={job.school_name}
                city_name={job.city_name}
                job_description={job.job_description}
                salarymax={job.salarymax}
                salarymin={job.salarymin}
                date={job.max_created_at}
                applicant={job.count}
              />
            ))
            }
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default SavedC