import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { validationSchema } from "../Schemas/ContactSchema";
import { Link } from "react-router-dom";
import Header from "../Utils/Header";
function Contact() {
  const initialValues = {
    register:"",
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  // On form submit
  const handleSubmit = (values) => {
    console.log(values);
    alert("Form submitted successfully!");
  };
  return (
    <section class="bg-white :bg-gray-900">
      <Header title={"Contact "} highlight={"Us"} subtitile={"We’d love to hear from you"}/>
      <div class="lg:w-5/6 px-6 py-12 mx-auto">
        
        <div className="grid sm:grid-cols-2 grid-col-1 gap-12">
          <div class="p-4 py-6 lg:max-w-2xl rounded-lg bg-[#d9f1f3]  md:p-8 shadow-lg">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isValid, dirty }) => (
                <Form>
                  <div className="mb-4">
                    <label className="block mb-2 text-sm text-gray-600">
                      Enquiry as
                    </label>
                    <Field
                      as="select"
                      name="register"
                      className="block w-full px-5 mb-2 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    >
                      <option disabled value="">Select an option</option>
                      <option value="school">School</option>
                      <option value="teacher">Teacher</option>
                    </Field>
                    <ErrorMessage
                      name="register"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                    <label className="block mb-2 text-sm text-gray-600">
                      Name
                    </label>
                    <Field
                      type="text"
                      name="name"
                      placeholder="John"
                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="block mb-2 text-sm text-gray-600">
                      Email Address
                    </label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="johndoe@example.com"
                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="block mb-2 text-sm text-gray-600">
                      Subject
                    </label>
                    <Field
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <ErrorMessage
                      name="subject"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="block mb-2 text-sm text-gray-600">
                      Message
                    </label>
                    <Field
                      as="textarea"
                      name="message"
                      placeholder="Message"
                      className="block w-full h-32 px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <button
                    type="submit"
                    className={`w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#0f3057] rounded-lg hover:bg-[#11a9b5] focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50 ${
                      !(dirty && isValid) ? "cursor-not-allowed" : ""
                    }`}
                    disabled={!(dirty && isValid)}
                  >
                    Send Message
                  </button>
                </Form>
              )}
            </Formik>
            <p className="p-4 text-justify">
              By providing your contact details, you have expressly authorised
              MySchoolNaukri (owned by ERJ Technologies) to contact you in
              future through calls/SMS/emails and inform you about our
              products/services.
            </p>
          </div>

          <div class="grid grid-cols-1 gap-12  lg:grid-cols-1 sm:grid-cols-1 ">
            <div class="p-4 rounded-lg  border-2 shadow-lg bg-white md:p-6 :bg-gray-800">
              <span class="inline-block p-3 bg-[#0f3057] rounded-lg bg-blue-100/80 :bg-gray-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
              </span>

              <h2 class="mt-4 text-base font-medium text-gray-800 :text-white">
                Speak to our friendly team
              </h2>
              <p class="mt-2 text-sm text-blue-500 :text-blue-400">
               <Link to="mailto:siddhant.tiwari@myschoolnaukri.com">siddhant.tiwari@myschoolnaukri.com, </Link>  <br/>
               <Link to="mailto:schoolsupport@myschoolnaukri.com">schoolsupport@myschoolnaukri.com </Link>  
              </p>
            </div>

            <div class="p-4 rounded-lg border-2 shadow-lg bg-white md:p-6 ">
              <span class="inline-block p-3 bg-[#0f3057] rounded-lg bg-blue-100/80 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>
              </span>

              <div className='flex-col'>

<span class="pl-2 text-slate-900 ">Registered Address: </span>  <p class="mt-1 mb-2 pl-2 text-slate-400 ">Plot No 40, Anand Nagar, Junwani, Durg, Bhilai, Chhattisgarh 490020, India</p>
<span class="pl-2 text-slate-900 ">Corporate    Address: </span> <p class="mt-1 pl-2 text-slate-400 ">2nd Floor, A-10, 
Sector-1, Avanti Vihar, 
Raipur, Chhattisgarh</p>
</div>
            </div>

            <div class="p-4 rounded-lg  border-2 shadow-lg bg-white md:p-6 :bg-gray-800">
              <span class="inline-block p-3 bg-[#0f3057] rounded-lg bg-blue-100/80 :bg-gray-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                  />
                </svg>
              </span>

              <h2 class="mt-4 text-base font-medium text-gray-800 :text-white">
                Call us
              </h2>

              <p class="mt-2 text-sm text-blue-500 :text-blue-400">
                +91 8948940687 
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
