import React, { useState } from "react";

const JobPopupModal = ({jobData}) => {
  const [isOpen, setIsOpen] = useState(false);

//   const jobData = {
//     id: "01155c74-574c-4aef-a22e-9d853b133e06",
//     user_id: "55d83650-3bdd-495b-a0f1-189c5397a841",
//     school_name: "MSN Jobs ",
//     slug: null,
//     board: "CBSE",
//     category: null,
//     subject: null,
//     specilization: null,
//     designation: "TGT Science",
//     job_description: "All applicants must be suitably qualified with relevant experience.",
//     experience: 1,
//     salarymin: "25000",
//     salarymax: "50000",
//     employment_type: "Full Time",
//     qualifications: "B.Ed",
//     key_skills: "1b86b2ed-5402-4d1c-a140-c82d3cc0077d,80f898d9-8a67-4c80-b50e-ca0d9d21b3d1",
//     location: "Surat",
//     joining_date: null,
//     is_draft: 0,
//     openings: 1,
//     status: 1,
//     ipaddress: null,
//     created_at: "2023-12-07T11:22:03.000000Z",
//     updated_at: "2023-12-07T11:22:03.000000Z",
//     school_logo: null,
//     state: "Gujarat",
//   };

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div className="p-4">
      <button
        onClick={openModal}
        className="bg-[#11a9b5] text-white px-3 py-1 rounded-lg transition duration-300"
      >
        View 
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white w-full max-w-2xl mx-4 p-6 rounded-lg shadow-lg relative">
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-2xl text-blue-500 font-bold hover:text-red-700 focus:outline-none"
            >
              &times;
            </button>

            <h2 className="text-2xl font-bold mb-6 text-gray-800 border-b pb-4">Job Details</h2>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-gray-700 font-semibold">School Name:</p>
                <p className="text-gray-600">{jobData.school_name}</p>
              </div>
              <div>
                <p className="text-gray-700 font-semibold">Board:</p>
                <p className="text-gray-600">{jobData.board}</p>
              </div>
              <div>
                <p className="text-gray-700 font-semibold">Designation:</p>
                <p className="text-gray-600">{jobData.designation}</p>
              </div>
              <div>
                <p className="text-gray-700 font-semibold">Experience:</p>
                <p className="text-gray-600">{jobData.experience} year(s)</p>
              </div>
              <div>
                <p className="text-gray-700 font-semibold">Salary Range:</p>
                <p className="text-gray-600">
                  {jobData.salarymin*12} - {jobData.salarymax*12} (PA)
                </p>
              </div>
              <div>
                <p className="text-gray-700 font-semibold">Employment Type:</p>
                <p className="text-gray-600">{jobData.employment_type}</p>
              </div>
              <div className="col-span-2">
                <p className="text-gray-700 font-semibold">Job Description:</p>
                <p className="text-gray-600">{jobData.job_description}</p>
              </div>
              <div className="col-span-2">
                <p className="text-gray-700 font-semibold">Qualifications:</p>
                <p className="text-gray-600">{jobData.qualifications}</p>
              </div>
              <div>
                <p className="text-gray-700 font-semibold">Location:</p>
                <p className="text-gray-600">
                  {jobData.location}, {jobData.state}
                </p>
              </div>
              <div>
                <p className="text-gray-700 font-semibold">Openings:</p>
                <p className="text-gray-600">{jobData.openings}</p>
              </div>
              <div>
                <p className="text-gray-700 font-semibold">Status:</p>
                <p className="text-gray-600">
                  {jobData.status === 1 ? "Active" : "Inactive"}
                </p>
              </div>
              <div>
                <p className="text-gray-700 font-semibold">Created At:</p>
                <p className="text-gray-600">
                  {new Date(jobData.created_at).toLocaleString()}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobPopupModal;