import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate ,Link } from 'react-router-dom';

const SearchPage = () => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();

  // Fetch jobs when the component mounts
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('https://info.myschoolnaukri.com/api/jobs_list');
        setJobs(response.data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };
    
    fetchJobs();
  }, []);

  // Filter jobs based on the search query
  useEffect(() => {
    if (query.trim()) {
      const filtered = jobs.filter((job) =>
        job.name.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filtered);
    } else {
      setSuggestions([]);
    }
  }, [query, jobs]);

  // Handle search button click
  const handleSearch = () => {
    if (query.trim()) {
      // Navigate to the search results page with the query as a parameter
      navigate(`/search?query=${encodeURIComponent(query)}`);
    }
  };
  const scrollToTop = () => {
   
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // smooth scrolling
    });
  };
  return (
    <div className='mb-14'>
      
      <div class="relative lg:-mt-10 -mt-12 border-2 mx-auto lg:max-w-2xl md:max-w-md z-0 w-5/6 shadow-lg rounded-xl   ">
        <span class="absolute inset-y-0 left-0 flex items-center pl-3">
          <svg class="w-5 h-5 text-gray-400" viewBox="0 0 24 24" fill="none">
            <path
              d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </span>

        <input
          type="text"
          class="w-full  pl-10 pr-4 text-gray-700  px-4 py-4 border-0 rounded-xl bg-white   focus:border-[#9eb6d3]  focus:ring-[#0f3057] focus:ring-opacity-20 focus:outline-none focus:ring"
          placeholder="Search for a Job"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <button  onClick={handleSearch} className="absolute inset-y-1 md:w-24 text-white font-medium md:text-sm  text-xs lg:text-lg rounded-xl  right-1 pr-2 bg-[#0f3057] px-8 py-2 pl-2">
          Search
        </button>
       
      
      </div>
      {/* <ul className='relative  border-2 mx-auto lg:max-w-2xl md:max-w-md z-0 w-5/6 shadow-lg rounded-xl '>
      {isLoading && <p>Loading...</p>} 
        {jobs.slice(0,7).map((job) => (
          <li key={job.id} onClick={() => handleJobClick(job.id)}>
            {job.name}
          </li>
        ))}
      </ul> */}
      {suggestions.length > 0 && (
        <ul className="relative z-30 bg-slate-200 border-2 mx-auto lg:max-w-2xl md:max-w-md  w-5/6 shadow-lg rounded-xl">
          {suggestions.slice(0, 5).map((job) => (
            <li key={job.job_id} onClick={scrollToTop} className="p-2 border-b hover:bg-gray-100">
              <Link  to={`/search?query=${encodeURIComponent(job.name)}`} className="block text-black ">
                {job.name} 
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
    
  );
};

export default SearchPage;
