// VirtualizedMenuList.js
import React from 'react';
import { FixedSizeList as List } from 'react-window';

// Custom MenuList component for react-select using react-window
const VirtualizedMenuList = (props) => {
  const { children, maxHeight, getValue } = props;
  const [value] = getValue();
  const height = children.length > 8 ? maxHeight : children.length * 35; // Adjust height based on item count

  return (
    <List
      height={height}
      itemCount={children.length}
      itemSize={40} // Height of each item
      width="100%"
    >
      {({ index, style }) => (
        <div style={style}>
          {children[index]}
        </div>
      )}
    </List>
  );
};

export default VirtualizedMenuList;
