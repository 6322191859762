import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import bg from '../assets/login-pana.png'
import { toast,ToastContainer } from 'react-toastify';
import logo1 from '../assets/flogo1.jpeg'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Utils/Context';
// Validation schema using Yup
const validationSchema = Yup.object({
  firstname: Yup.string()
    .max(255, 'The firstname must not exceed 255 characters.')
    .required('The firstname field is required.'),
  
  lastname: Yup.string()
    .max(255, 'The lastname must not exceed 255 characters.')
    .required('The lastname field is required.'),

  organizationname: Yup.string()
    .max(255, 'The organization name must not exceed 255 characters.')
    .required('The organization name field is required.'),

  email: Yup.string()
    .email('Invalid email format.')
    .max(255, 'The email must not exceed 255 characters.')
    .required('The email field is required.'),

  password: Yup.string()
    .min(8, 'Password must be at least 8 characters long.')
    .required('The password field is required.')
    .oneOf([Yup.ref('confirm_password'), null], 'The password and confirm password must match.'),

  confirm_password: Yup.string()
    .required('The confirm password field is required.'),

  contact: Yup.string()
    .matches(/^[0-9]{10}$/, 'The contact field must be exactly 10 digits.')
    .required('The contact field is required.'),
});

const MyForm = () => {
    const navigate = useNavigate();
    const[active , setactive] = useState(true);
    const[activet , settactive] = useState(false);
    const[loading , setloading] = useState(false);
    const handleRedirect = () => {
        navigate('/Candidate_onboarding1');
        settactive(!active)
      };
    const {api} = useAuth()
  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirm_password: '',
      contact: '',
      organizationname:''
    },
    validationSchema,
    onSubmit: (values) => {
      console.log('Form data before submitting:', values);
      setloading(true)
      handleSubmit(values); // Replace with your actual submit function
    },
  });

  const handleSubmit = async (data) => {
    try {
      const response = await api.post('/api/employer_signup',data, {
        headers: {
          "Content-Type": "application/json",
        }});

      // if (!response.ok) {
      //   const errorData = await response.data;
      //   console.error('Error submitting form:', errorData);
      //   toast.error(respomsgnse.data.)
      //   setloading(false)
      //  } else {
      // }
        const result = await response.data;
                console.log(' submitting form:', result);
        toast.success(response.data.msg)
        setloading(false)
        navigate('/onboarding_thanku')
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.response.data.msg)
      // toast.error("something went wrong")
      setloading(false)

    }
  };

  return (
    <>
     <section class="bg-white :bg-gray-900">
      <ToastContainer autoClose={false}/>
    <div class="flex justify-center ">
       

        <div class="flex items-center w-full max-w-3xl p-8 mx-auto lg:px-12 lg:w-3/5">
            <div class="w-full">
                <div className="flex justify-between">

                <h1 class="text-2xl font-semibold tracking-wider text-gray-800 capitalize :text-white">
                Welcome to MySchoolNaukri
                </h1>
                <img
              className=" h-12 sm:h-[50px]"
              src={logo1}
              alt="Logo"
              />
              </div>
                <p class="mt-4 text-gray-500 :text-gray-400">
                Register as a
                </p>
                <div className="flex  mt-2 space-x-4">
                <button className={`px-4 border-2 w-full rounded-lg py-1 hover:bg-[#11a9b5] transition-colors hover:text-white hover:scale-110 ${activet?"bg-[#11a9b5]  text-white":""} `}  onClick={handleRedirect}>Teacher</button>
                <button className={`px-4 border-2  w-full rounded-lg py-1  hover:scale-110 transition-colors hover:text-white hover:bg-[#11a9b5] ${active?"bg-[#11a9b5]  text-white":""} `}  onClick={()=>setactive(!active)}>School</button>
              
                </div>

                

                {loading && (

<div className="fixed inset-0 bg-gray-500 bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50">
<div role="status">
    <svg aria-hidden="true" class="w-14 h-14 text-gray-200 animate-spin  fill-blue-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>
</div> )}
    
      <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-1 gap-6 mb-4 mt-8 md:mb-6 md:grid-cols-2">
     
     <div className="mb-4">
       <label className="block text-sm font-medium text-gray-700">First Name</label>
       <input
         type="text"
         name="firstname"
         onChange={formik.handleChange}
         onBlur={formik.handleBlur}
         value={formik.values.firstname}
         className={`mt-1 block w-full border border-gray-300 rounded-md p-2 ${
           formik.touched.firstname && formik.errors.firstname ? 'border-red-500' : ''
         }`}
       />
       {formik.touched.firstname && formik.errors.firstname && (
         <div className="text-red-500 text-sm">{formik.errors.firstname}</div>
       )}
     </div>

     <div className="mb-4">
       <label className="block text-sm font-medium text-gray-700">Last Name</label>
       <input
         type="text"
         name="lastname"
         onChange={formik.handleChange}
         onBlur={formik.handleBlur}
         value={formik.values.lastname}
         className={`mt-1 block w-full border border-gray-300 rounded-md p-2 ${
           formik.touched.lastname && formik.errors.lastname ? 'border-red-500' : ''
         }`}
       />
       {formik.touched.lastname && formik.errors.lastname && (
         <div className="text-red-500 text-sm">{formik.errors.lastname}</div>
       )}
     </div>
     <div className="mb-4 md:col-span-2">
       <label className="block text-sm font-medium text-gray-700">Organization Name</label>
       <input
         type="text"
         name="organizationname"
         onChange={formik.handleChange}
         onBlur={formik.handleBlur}
         value={formik.values.organizationname}
         className={`mt-1 block w-full border border-gray-300 rounded-md p-2 ${
           formik.touched.organizationname && formik.errors.organizationname ? 'border-red-500' : ''
         }`}
       />
       {formik.touched.organizationname && formik.errors.organizationname && (
         <div className="text-red-500 text-sm">{formik.errors.organizationname}</div>
       )}
     </div>

     <div className="mb-4">
       <label className="block text-sm font-medium text-gray-700">Email</label>
       <input
         type="email"
         name="email"
         onChange={formik.handleChange}
         onBlur={formik.handleBlur}
         value={formik.values.email}
         className={`mt-1 block w-full border border-gray-300 rounded-md p-2 ${
           formik.touched.email && formik.errors.email ? 'border-red-500' : ''
         }`}
       />
       {formik.touched.email && formik.errors.email && (
         <div className="text-red-500 text-sm">{formik.errors.email}</div>
       )}
     </div>
     <div className="mb-4">
       <label className="block text-sm font-medium text-gray-700">Contact </label>
        <div class="relative border-r-2">
        <div class="absolute inset-y-0 start-0 top-6  flex items-center ps-3.5 pointer-events-none">
            +91
        </div>
        </div>
       <input
         type="text"
         name="contact"
         onChange={formik.handleChange}
         onBlur={formik.handleBlur}
         value={formik.values.contact}
         className={`mt-1 block w-full ps-12 border border-gray-300 rounded-md p-2 ${
           formik.touched.contact && formik.errors.contact ? 'border-red-500' : ''
         }`}
       />
       {formik.touched.contact && formik.errors.contact && (
         <div className="text-red-500 text-sm">{formik.errors.contact}</div>
       )}
     </div>
     <div className="mb-4">
       <label className="block text-sm font-medium text-gray-700">Password</label>
       <input
         type="password"
         name="password"
         onChange={formik.handleChange}
         onBlur={formik.handleBlur}
         value={formik.values.password}
         className={`mt-1 block w-full border border-gray-300 rounded-md p-2 ${
           formik.touched.password && formik.errors.password ? 'border-red-500' : ''
         }`}
       />
       {formik.touched.password && formik.errors.password && (
         <div className="text-red-500 text-sm">{formik.errors.password}</div>
       )}
     </div>

     <div className="mb-4">
       <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
       <input
         type="password"
         name="confirm_password"
         onChange={formik.handleChange}
         onBlur={formik.handleBlur}
         value={formik.values.confirm_password}
         className={`mt-1 block w-full border border-gray-300 rounded-md p-2 ${
           formik.touched.confirm_password && formik.errors.confirm_password ? 'border-red-500' : ''
         }`}
       />
       {formik.touched.confirm_password && formik.errors.confirm_password && (
         <div className="text-red-500 text-sm">{formik.errors.confirm_password}</div>
       )}
     </div>

     
     </div>
    
     <button
          type="submit"
          disabled={!formik.isValid || formik.isSubmitting}
          className='flex items-center justify-between w-full px-6 py-3 text-sm tracking-wide text-white capitalize transition-colors duration-300 bg-[#11a9b5] rounded-lg hover:bg-slate-200 hover:text-black '
            
         
        >
          <span>Sign Up</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 rtl:-scale-x-100"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
     {/* <button
       type="submit"
       className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded"
     >
       Submit
     </button> */}
        
   </form>
     

            </div>
        </div>
        <div class="lg:max-w-lg lg:mt-28 lg:w-full md:hidden lg:block hidden">
      <img class="object-cover object-center rounded" alt="hero" src={bg}/>
    </div>
    </div>
</section>

  
    </>
  );
};

export default MyForm;
