import React from "react";


import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Sidebar from "./Sidebar";
import Navbar from "./Navbar";



function LayoutAdmin() {
  return (
    <>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <Navbar />

        <Sidebar />

        {/* Main content */}
        <div className="sm:p-4  py-10 lg:ml-64">
          <div className="sm:p-4 px-1 py-2 border-2 border-gray-200 border-dashed rounded-lg  mt-14">
            <Outlet />
        <ToastContainer/>
          </div>
        </div>

        {/* Bottom navbar for mobile */}
       
      </div>
    </>
  );
}

export default LayoutAdmin;
