import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../Utils/Context";
import Loading from "../../Utils/Loading";
import { Link } from "react-router-dom";
const gradients = [
  'bg-gradient-to-r from-purple-400 via-pink-500 to-red-500',
  'bg-gradient-to-r from-blue-500 via-blue-400 to-indigo-500',
  'bg-gradient-to-r from-green-400 via-teal-500 to-cyan-500',
  'bg-gradient-to-r from-yellow-500  to-red-500',
  'bg-gradient-to-r from-red-400 via-pink-500 to-purple-500',
  'bg-gradient-to-r from-teal-400 to-lime-500',
  'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500',
  'bg-gradient-to-r from-rose-400  to-purple-500',
  'bg-gradient-to-r from-yellow-300 via-orange-400 to-pink-400',
  'bg-gradient-to-r from-blue-400 to-teal-500',
];

// Array of statistics data
const getRandomGradient = () => {
  return gradients[Math.floor(Math.random() * gradients.length)];
};
function Dash() {
  const {api} = useAuth()
  
  const token = localStorage.getItem("token");
  console.log(token)
  const fetchstats = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await api.get(
          `/api/admin/dashboard`,
          {}, // Post body, if required
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
    
        return response.data; // Return the data property from Axios response
      } catch (error) {
        console.error("Failed to fetch data:", error);
        throw new Error("Failed to fetch data");
      }
    };
    
// Fetch data with React Query and pass page as a dependency
const { data, isLoading, isError  , error} = useQuery({queryKey:["stats"], queryFn:() => fetchstats(), 
  keepPreviousData: true, // Retain previous page data while loading new page
});

// Handle loading and error states
if (isLoading) return <Loading/>;
if (isError) return <div className='text-center text-2xl text-red-500'>Error: {error.message}</div>;

const updatedData = data?.map((item, index) => {
  let link = '';

  // Set different links based on the index
  if (index === 0 || index === 1) {
    link = '/admin/candidate';
  } else if (index === 2) {
    link = '/admin/posted_job';
  } else if (index === 3) {
    link = '/admin/applyjob';
  }

  return {
    ...item,
    link, // Add the link property
  };
});
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
      {updatedData.map((stat, index) => (
        <div
          key={index}
          className={`max-w-sm p-6 border border-gray-200 rounded-lg shadow ${getRandomGradient()} text-white`} // Apply random gradient here
        >
          <p>
            <h5 className="mb-2 text-3xl font-bold tracking-tight">{stat.value}</h5>
          </p>
          <p className="mb-3 text-xl font-normal">{stat.label}</p>
         
          <Link
            to={`${stat.link}`}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center bg-white text-gray-900 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            More info
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </Link>
        </div>
      ))}
      
      
    </div>
  )
}

export default Dash