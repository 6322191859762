import React from "react";

const ComingSoon = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-blue-900 to-[#11a9b5] text-white p-4">
      <div className="text-center">
        <h1 className="text-4xl md:text-6xl font-bold mb-4">Coming Soon</h1>
        <p className="text-lg md:text-2xl mb-8">
          Our new feature is on its way! Stay tuned for updates.
        </p>
        
      </div>
      <footer className="absolute text-center bottom-4 text-sm">
        © {new Date().getFullYear()} . All rights reserved | myschoolnaukri Portal is Owned by ERJ Technology (OPC) Private Limited.
      </footer>
    </div>
  );
};

export default ComingSoon;
