import React, { useState } from 'react';

const Resumebuilder = () => {
  const [selectedTemplate, setSelectedTemplate] = useState('template1');
  const [selectedColor, setSelectedColor] = useState('bg-white');
  const [isEditing, setIsEditing] = useState({
    name: false,
    title: false,
    bio: false,
    skills: false,
    experience: false
  });

  const [teacherData, setTeacherData] = useState({
    name: "John Doe",
    title: "Mathematics Teacher",
    contact: "john.doe@email.com",
    bio: "An experienced teacher specializing in Mathematics for high school students with over 10 years of teaching experience.",
    skills: ["Algebra", "Geometry", "Statistics", "Calculus"],
    experience: [
      {
        role: "Math Teacher",
        institution: "XYZ High School",
        period: "2015 - Present",
        details: "Teaching math curriculum to students from grade 9-12."
      },
      {
        role: "Math Instructor",
        institution: "ABC Academy",
        period: "2010 - 2015",
        details: "Conducted evening classes for competitive exam preparation."
      }
    ]
  });

  const handleInputChange = (e, field, index = null, subField = null) => {
    const value = e.target.value;
    if (field === 'skills') {
      const newSkills = [...teacherData.skills];
      newSkills[index] = value;
      setTeacherData({ ...teacherData, skills: newSkills });
    } else if (field === 'experience') {
      const newExperience = [...teacherData.experience];
      newExperience[index][subField] = value;
      setTeacherData({ ...teacherData, experience: newExperience });
    } else {
      setTeacherData({ ...teacherData, [field]: value });
    }
  };

  const toggleEdit = (field) => {
    setIsEditing({ ...isEditing, [field]: !isEditing[field] });
  };

  return (
    <div className="min-h-screen p-6">
      <h1 className="text-3xl font-bold text-center mb-8">Resume Builder for Teachers</h1>

      {/* Template and Color Selection */}
      <div className="flex justify-center gap-4 mb-6">
        <button
          className={`px-4 py-2 rounded ${selectedTemplate === 'template1' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setSelectedTemplate('template1')}
        >
          Template 1
        </button>
        <button
          className={`px-4 py-2 rounded ${selectedTemplate === 'template2' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setSelectedTemplate('template2')}
        >
          Template 2
        </button>
      </div>

      <div className="flex justify-center gap-4 mb-6">
        <button
          className="px-4 py-2 rounded bg-red-500 text-white"
          onClick={() => setSelectedColor('bg-red-100')}
        >
          Red
        </button>
        <button
          className="px-4 py-2 rounded bg-green-500 text-white"
          onClick={() => setSelectedColor('bg-green-100')}
        >
          Green
        </button>
        <button
          className="px-4 py-2 rounded bg-blue-500 text-white"
          onClick={() => setSelectedColor('bg-blue-100')}
        >
          Blue
        </button>
      </div>

      {/* Resume Display */}
      <div className={`max-w-2xl mx-auto p-6 shadow-lg rounded ${selectedColor} transition-all duration-300`}>
        {selectedTemplate === 'template1' && <TemplateOne data={teacherData} isEditing={isEditing} toggleEdit={toggleEdit} handleInputChange={handleInputChange} />}
        {selectedTemplate === 'template2' && <TemplateTwo data={teacherData} isEditing={isEditing} toggleEdit={toggleEdit} handleInputChange={handleInputChange} />}
      </div>
    </div>
  );
};

// Template 1 Component
const TemplateOne = ({ data, isEditing, toggleEdit, handleInputChange }) => {
  return (
    <div>
      <div className="flex justify-between">
        {isEditing.name ? (
          <input
            className="border p-1"
            value={data.name}
            onChange={(e) => handleInputChange(e, 'name')}
          />
        ) : (
          <h2 className="text-2xl font-bold">{data.name}</h2>
        )}
        <button className="text-sm text-blue-500" onClick={() => toggleEdit('name')}>{isEditing.name ? 'Save' : 'Edit'}</button>
      </div>

      <div className="flex justify-between">
        {isEditing.title ? (
          <input
            className="border p-1"
            value={data.title}
            onChange={(e) => handleInputChange(e, 'title')}
          />
        ) : (
          <h3 className="text-xl">{data.title}</h3>
        )}
        <button className="text-sm text-blue-500" onClick={() => toggleEdit('title')}>{isEditing.title ? 'Save' : 'Edit'}</button>
      </div>

      <div className="flex justify-between">
        {isEditing.bio ? (
          <textarea
            className="border p-1"
            value={data.bio}
            onChange={(e) => handleInputChange(e, 'bio')}
          />
        ) : (
          <p>{data.bio}</p>
        )}
        <button className="text-sm text-blue-500" onClick={() => toggleEdit('bio')}>{isEditing.bio ? 'Save' : 'Edit'}</button>
      </div>

      <h4 className="text-lg font-bold mt-4 flex justify-between">Skills
        <button className="text-sm text-blue-500" onClick={() => toggleEdit('skills')}>{isEditing.skills ? 'Save' : 'Edit'}</button>
      </h4>
      <ul className="list-disc ml-5">
        {data.skills.map((skill, index) => (
          <li key={index}>
            {isEditing.skills ? (
              <input
                className="border p-1"
                value={skill}
                onChange={(e) => handleInputChange(e, 'skills', index)}
              />
            ) : (
              skill
            )}
          </li>
        ))}
      </ul>

      <h4 className="text-lg font-bold mt-4">Experience</h4>
      {data.experience.map((exp, index) => (
        <div key={index} className="mb-2 border p-2 rounded">
          <div className="flex justify-between">
            {isEditing.experience ? (
              <input
                className="border p-1"
                value={exp.role}
                onChange={(e) => handleInputChange(e, 'experience', index, 'role')}
              />
            ) : (
              <p className="font-bold">{exp.role}</p>
            )}
            <button className="text-sm text-blue-500" onClick={() => toggleEdit('experience')}>{isEditing.experience ? 'Save' : 'Edit'}</button>
          </div>
          <p>{exp.institution} ({exp.period})</p>
          <p>{exp.details}</p>
        </div>
      ))}
    </div>
  );
};

// Template 2 Component
const TemplateTwo = ({ data, isEditing, toggleEdit, handleInputChange }) => {
  return (
    <div>
      {/* Similar structure for Template 2, following the same inline-editing pattern */}
      <TemplateOne data={data} isEditing={isEditing} toggleEdit={toggleEdit} handleInputChange={handleInputChange} />
    </div>
  );
};

export default Resumebuilder;
