import React from 'react';
import { useFetchData } from '../../Utils/Fetchdata';
import axios from 'axios';
export const Location = ()=>{
    const { data, isLoading, error } = useFetchData("https://info.myschoolnaukri.com/api/location_list", ['location']);

  const options = React.useMemo(() => {
    if (!data) return [];
    return data.map(item => ({
      value: item.id, // Adjust this based on your actual data structure
      label: item.state_name + " - " +item.city_name, // Adjust this based on your actual data structure
    }));
  }, [data]);

  if (isLoading) return <div>Loading options...</div>;
  if (error) return <div>Error loading options: {error.message}</div>;

  return {options,isLoading}
}
export const Locationnew = ()=>{
    const { data, isLoading, error } = useFetchData("https://info.myschoolnaukri.com/api/master/location", ['location']);
  const options = React.useMemo(() => {
    if (!data) return [];
    return data.locations?.map(item => ({
      value: item.id, // Adjust this based on your actual data structure
      state: item.state_name,
      city: item.city_name ,
      stateid: item.state_id// Adjust this based on your actual data structure
    }));
  }, [data]);

  if (isLoading) return <div>Loading options...</div>;
  if (error) return <div>Error loading options: {error.message}</div>;

  return {options,isLoading}
}
export const Locationpost = ()=>{
    const { data, isLoading, error } = useFetchData("https://info.myschoolnaukri.com/api/master/location", ['location']);

  const options = React.useMemo(() => {
    if (!data) return [];
    return data?.map(item => ({
      value: item.id, // Adjust this based on your actual data structure
      state: item.state_name, // Adjust this based on your actual data structure
    }));
  }, [data]);

  if (isLoading) return <div>Loading options...</div>;
  if (error) return <div>Error loading options: {error.message}</div>;

  return {options,isLoading}
}
export const StateLocation = ()=>{
  const { data: state = [], isLoading4 } = useFetchData(
    "https://info.myschoolnaukri.com/api/master/state",
    ["State"]
  );
  const stateOptions = state.map((state) => ({
    value: state.state_name,
    label: state.state_name.charAt(0).toUpperCase() + state.state_name.slice(1), // Capitalize for display
  }));
  return {stateOptions,isLoading4}
}
export const Specilization = ()=>{
    const { data, isLoading2, error } = useFetchData("https://info.myschoolnaukri.com/api/master/specilization", ['Specilization']);

  const options2 = React.useMemo(() => {
    if (!data) return [];
    return data.map(item => ({
      value: item.id, // Adjust this based on your actual data structure
      label: item.name, // Adjust this based on your actual data structure
    }));
  }, [data]);

  if (isLoading2) return <div>Loading options...</div>;
  if (error) return <div>Error loading options: {error.message}</div>;

  return {options2,isLoading2}
}
export const Designation = ()=>{
    const { data, isLoading3, error } = useFetchData("https://info.myschoolnaukri.com/api/master/designation", ['Designation']);

  const options3 = React.useMemo(() => {
    if (!data) return [];
    return data.map(item => ({
      value: item.id, // Adjust this based on your actual data structure
      label: item.name,
      type:item.type // Adjust this based on your actual data structure
    }));
  }, [data]);

  if (isLoading3) return <div>Loading options...</div>;
  if (error) return <div>Error loading options: {error.message}</div>;

  return {options3,isLoading3}
}
export const Education = ()=>{
    const { data, isLoading4, error } = useFetchData("https://info.myschoolnaukri.com/api/master/education", ['Education']);

  const options4 = React.useMemo(() => {
    if (!data) return [];
    return data.map(item => ({
      value: item.id, // Adjust this based on your actual data structure
      label: item.name, // Adjust this based on your actual data structure
    }));
  }, [data]);

  if (isLoading4) return <div>Loading options...</div>;
  if (error) return <div>Error loading options: {error.message}</div>;

  return {options4,isLoading4}
}
export const Skills = ()=>{
    const { data, isLoading5, error } = useFetchData("https://info.myschoolnaukri.com/api/master/skill_master", ['Skills']);

  const options5 = React.useMemo(() => {
    if (!data) return [];
    return data.map(item => ({
      value: item.id, // Adjust this based on your actual data structure
      label: item.name, // Adjust this based on your actual data structure
    }));
  }, [data]);
  if (isLoading5) return <div>Loading options...</div>;
  if (error) return <div>Error loading options: {error.message}</div>;

  return {options5,isLoading5}
}
export const fetchschooldata = async(url)=>{
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`, // Include the Bearer token
      },});
      // Extract id and school_name from the single object
      
      const school = response.data;
      const schoolOption = {
        value: school?.id,  // id will be used as value
        label: school?.school_name,  // school_name will be used as label
      };
      if (!schoolOption) return [];
      // Return as an array (even if there's only one object)
      return [schoolOption];
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
}


 export const customClassNames = {
    control: (state) => 
      `w-full  py-2  bg-white border rounded-md shadow-sm mb-4
      ${state.isFocused ? 'border-blue-500 ring-1 ring-blue-500' : 'border-gray-300'}
      ${state.isDisabled ? 'bg-gray-100' : ''} 
      hover:border-gray-400 transition-colors`,
    option: (state) => 
      `px-3 py-2 text-sm cursor-pointer
      ${state.isSelected ? 'bg-blue-500 text-white' : 'text-gray-700'}
      ${state.isFocused ? 'bg-blue-100' : 'bg-white'}
      hover:bg-blue-100`,
    menu: () => 'mt-1 bg-white border border-gray-300 rounded-md shadow-lg',
    placeholder: () => 'text-gray-700',
    singleValue: () => 'text-gray-700',
    input: () => 'text-gray-700',
    valueContainer: () => 'p-2',
    indicatorSeparator: () => 'bg-gray-300',
    dropdownIndicator: () => 'text-gray-700 p-2 hover:text-gray-600',
  };
 export const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '42px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };