import React from 'react'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Jobshare({ jobTitle, id ,setShowTooltip1,showTooltip1}) {
    const handleShare = async () => {
        if (navigator.share) {
          try {
            // Call the Web Share API
            await navigator.share({
              title: jobTitle,
              text: `Check out this job post: ${jobTitle}`,
              url: `https://www.myschoolnaukri.com/job/${id}`
            });
            toast.success("Job post shared successfully!");
          } catch (error) {
            console.error("Error sharing the job post:", error);
            toast.error("Failed to share the job post.");
          }
        } else {
          // Fallback if Web Share API is not supported
          toast.error("Sharing is not supported in your browser.");
        }
      };
  return (
    <p
              class="px-4 sm:px-4 sm:py-2 py-1 lg:text-sm text-[14px] sm:font-bold font-semibold mb-2 text-gray-100 transition-colors duration-300 transform bg-[#0f3057] rounded cursor-pointer hover:bg-gray-500"
              tabindex="0"
              role="button"
              onClick={handleShare}
              onMouseEnter={() => setShowTooltip1(true)}
              onMouseLeave={() => setShowTooltip1(false)}
            >
             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 h-5  text-gray-100 transition-colors duration-300  hover:text-gray-100 "><path fill="#ffffff" d="M307 34.8c-11.5 5.1-19 16.6-19 29.2l0 64-112 0C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96l96 0 0 64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z"/></svg>
             {showTooltip1 && (
        <div className="absolute bottom-full right-0 -left-3 mb-2 w-max bg-gray-700 text-white text-xs rounded py-1 px-4">
          Share job
         
        </div>
      )}
            </p>
  )
}

export default Jobshare