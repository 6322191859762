import React, { useState,useEffect } from 'react'
import { Education } from '../../dashboard/components/Options';
import { useAuth } from '../../Utils/Context';
import VirtualizedMenuList from '../../Utils/VirtualizedMenuList';
import Select from "react-select";
import { toast } from "react-toastify";

function EducationDetails({educationdata}) {
const { options4, isLoading4 } = Education();
const [loading,setLoading] = useState(false)
const [isEditing,setIsEditing] = useState(false)
const {api} = useAuth();
const [education, seteducation] = useState({
  EducationDetailsID: "",
  education: "",
  school_collage: "",
  passing_year: null,
});

useEffect(() => {
  if (educationdata) {
    seteducation({
      EducationDetailsID: educationdata.education_details_id || "",
      education: educationdata.education || "",
      school_collage: educationdata.school_collage || "",
      passing_year: educationdata.passing_year || null,
    });
  }
}, [educationdata]); // Effect runs whenever educationdata changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    seteducation((prev) => ({ ...prev, [name]: value }));
  };
// console.log(educationdata)
  // Update candidate location when a select value is chosen
  const handleLocationChange = (option) => {
    seteducation((prev) => ({ ...prev, education: option ? option.value : null }));
  };
  const EducationDetails = async () => {
    setLoading(true)
    try {
      const response = await api.post('api/candidate/save_education_details', education);

      if (response.data.msg === "Success") {
        toast.success("Profile Updated Successfully");
 
        // Since the API doesn't return the updated data, manually set the state as saved
       window.location.reload()
      } else {
        throw new Error("Failed to update profile");
      }
  
      console.log("Education saved successfully:", response);
    } catch (error) {
      console.error("Error saving education data:", error);
      toast.error('Something went wrong!')

    }
    finally{
        setIsEditing(false)
        setLoading(false)
    }
  };
  return (
<div className="bg-white p-5 rounded-lg shadow">
            <div className="flex justify-between  w-full">
              <span className=" font-bold text-lg mb-2 text-[#11a9b5]">
                Education Details:
              </span>
                            {loading && (<div role="status" className=' '>
    <svg aria-hidden="true" class="w-6 h-6 mx-2 text-gray-300 animate-spin  fill-blue-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>)} 
              <button
                  className="w-7 h-7"
                  onClick={() => setIsEditing(true)}
                  >
                  {isEditing ? (
                    <svg
                    className="w-7 mx-auto h-7 text-[#11a9b5] transition duration-75  "
                    fill="currentcolor"
                    onClick={EducationDetails}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    >
                      <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z" />
                    </svg>
                  ) : (
                    <svg
                    className="w-7 mx-auto h-7 text-[#11a9b5] transition duration-75  "
                    fill="currentcolor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    >
                      <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 125.7-86.8 86.8c-10.3 10.3-17.5 23.1-21 37.2l-18.7 74.9c-2.3 9.2-1.8 18.8 1.3 27.5L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z" />
                    </svg>
                  )}
                </button>
                </div>
              <div className="gap-y-4 mb-4 mt-4 sm:mb-0 space-y-3">
                <div className="space-y-2">
                  <p className="text-gray-800">
                    <span className="font-medium text-[#11a9b5]">
                      Education:
                    </span>
                    {isEditing ? (
                        <Select
                        className="py-2 text-gray-800 w-full"
                        options={options4}
                        name="education"
                        isLoading={isLoading4}
                        value={
                            options4.find((option) => option.value === education.education) || null
                          }
                          onChange={handleLocationChange}
                        placeholder="select education"
                        components={{ MenuList: VirtualizedMenuList }}
                        isClearable
                      />

                    ) : (
                      <span className="text-gray-800 font-normal px-3">
                        {educationdata?.name || "Not specified"}{" "}
                      </span>
                    )}
                  </p>
               
                </div>

                
                  <div className="space-y-2"  >
                    <p className="text-gray-800">
                      <span className="font-medium text-[#11a9b5]">
                        School/College:
                      </span>
                      {isEditing ? (
                        <input
                          type="text"
                          name="school_collage"
                          value={education?.school_collage || ""}
                         onChange={handleInputChange}
                          className="border border-gray-300 rounded mx-2 px-2 py-1"
                          placeholder="School/college"
                          />
                        ) : (
                          <span className="text-gray-800 font-normal px-3">
                          {educationdata?.school_collage || "N/A"}{" "}
                        </span>
                      )}
                    </p>
                   
                    
                    <p className="text-gray-800">
                      <span className="font-medium text-[#11a9b5]">
                        passing year:
                      </span>
                      {isEditing ? (
                        <input
                        type="text"
                          name="passing_year"
                          value={education?.passing_year}
                         onChange={handleInputChange}
                          className="border border-gray-300 rounded mx-2 px-2 py-1"
                          placeholder="enter passing year"
                        />
                      ) : (
                        <span className="text-gray-800 font-normal px-3">
                          {educationdata?.passing_year || "N/A"}{" "}
                        </span>
                      )}
                    </p>
                  </div>
                
                </div>
            </div>  )
}

export default EducationDetails