import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Autoplay } from 'swiper/modules';
import Coursescard from '../../pages/Coursescard'
import { Link } from 'react-router-dom';

function Coursescandi() {
  return (
<section class="bg-white py-4 px-4">
    <div class="container sm:px-6 py-10 mx-auto">
    <h1 class=" text-3xl font-bold tracking-tight leading-none text-blue-950 "><span className='text-[#11a9b5]'>Your </span>  Courses</h1>

        <div class="grid grid-cols-1 gap-8 mt-8 md:mt-16 md:grid-cols-1">
            <div class="lg:flex w-full cursor-pointer border shadow-lg rounded-lg">
                {/* <img class="object-cover w-full h-56 rounded-lg lg:w-64" src="https://images.unsplash.com/photo-1515378960530-7c0da6231fb1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt=""/> */}
                <img class="p-8 rounded-lg" src="https://online.sttar.in/wp-content/uploads/2023/05/developing-critical-thinking-curriulumn-pre-primary.jpg" alt="product" />

                <div class="flex flex-col justify-between sm:px-0 px-3  space-y-2 sm:py-6 lg:mx-6">
                    <p  class="sm:text-xl text-lg font-semibold text-gray-800 hover:underline :text-white ">
                        How to use sticky note for problem solving 
                    </p>
                    
                    <span class="sm:text-sm text-xs text-justify text-gray-900 :text-gray-300">Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ab itaque minima voluptatibus sequi asperiores est consequatur pariatur, possimus quasi similique debitis, molestias molestiae?</span>
                    <span class="text-sm text-gray-500 :text-gray-300">Author: Prateksha vohara</span>
                    <div className='flex justify-between items-center py-2  sm:px-0'>

                    <span class="text-sm text-[#11a9b5] ">Completed: 40%</span>
                    <Link to='/candidatedashboard/course/134234' class="text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  px-1  sm:px-5 py-2.5 text-center :bg-blue-600 :hover:bg-blue-700 :focus:ring-blue-800">Watch now</Link>

</div>
                </div>
            </div>

            <div class="lg:flex w-full cursor-pointer shadow-lg border rounded-lg">
            <img class="p-8 rounded-lg" src="https://online.sttar.in/wp-content/uploads/2023/05/developing-critical-thinking-curriulumn-pre-primary.jpg" alt="product" />

                <div class="flex flex-col justify-between sm:px-0 px-3  space-y-2 sm:py-6  lg:mx-6">
                    <p class="text-xl font-semibold text-gray-800 hover:underline :text-white ">
                        How to use sticky note for problem solving
                    </p>

                    <span class="sm:text-sm text-xs text-justify text-gray-900 :text-gray-300">Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ab itaque minima voluptatibus sequi asperiores est consequatur pariatur, possimus quasi similique debitis, molestias molestiae?</span>
                    <span class="text-sm text-gray-500 :text-gray-300">Author: Prateksha vohara</span>
                    <div className='flex justify-between items-center py-2 sm:px-0'>

<span class="text-sm text-[#11a9b5] :text-gray-300">Completed: 40%</span>
<Link to='/candidatedashboard/course/134234' class="text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg px-1 text-sm sm:px-5 py-2.5 text-center">Watch now</Link>

</div>                       
                             </div>
            </div>


        </div>
    </div>
<div className='flex justify-between mb-4 items-center'>
    <h1 class=" text-3xl font-bold  tracking-tight leading-none text-blue-950 "><span className='text-[#11a9b5]'>Recommened </span>  Courses</h1>
    <Link to='/candidatedashboard/viewall' class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center :bg-blue-600 :hover:bg-blue-700 :focus:ring-blue-800">View all</Link>
  
</div>

    <Swiper
        slidesPerView={3}
        spaceBetween={0}
        navigation
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        loop={true}
        modules={[Navigation, Autoplay]}
         breakpoints={{
          320: { slidesPerView: 1, spaceBetween: 10 }, // On mobile, show 1 card
          640: { slidesPerView: 2, spaceBetween: 15 }, // On small screens, show 2 cards
          768: { slidesPerView: 3, spaceBetween: 20 }, // On tablets, show 3 cards
          1024: { slidesPerView: 3  , spaceBetween: 20 }, // On desktop, show 4 cards
        }}
        className="w-full max-w-7xl mb-20"
      >
      

        {[...Array(8)].map((_, i) => (
            <div className='px-10'>
          <SwiperSlide key={i}>

           <Coursescard/>
          </SwiperSlide>
            </div>
        ))}
      </Swiper>
</section>
  )
}

export default Coursescandi