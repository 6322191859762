
import React, { useState } from 'react'
import logo1 from '../assets/flogo1.jpeg'
function Confirmpass() {
    const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formErrors, setFormErrors] = useState({ email: '', password: '', confirmPassword: '' });

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

    const validatePasswords = () => {
        let isValid = true;
        if (password.length < 6) {
          setFormErrors((prev) => ({ ...prev, password: 'Password should be at least 6 characters' }));
          isValid = false;
        } else {
          setFormErrors((prev) => ({ ...prev, password: '' }));
        }
        if (password !== confirmPassword) {
          setFormErrors((prev) => ({ ...prev, confirmPassword: 'Passwords do not match' }));
          isValid = false;
        } else {
          setFormErrors((prev) => ({ ...prev, confirmPassword: '' }));
        }
        return isValid;
      };
      const handleSubmit = (e) => {
        e.preventDefault();
        if (validatePasswords()) {
          // Submit the form (perform password reset logic)
          alert('Password successfully changed!');
        }
      };
  return (
    <section class="bg-white :bg-gray-900">
    <div class="container flex flex-col items-center justify-center min-h-screen px-6 mx-auto">
    <div className="flex justify-center mx-auto">
          <img className="h-6 sm:h-[50px]" src={logo1} alt="Logo" />
        </div>

        <h3 className="mt-3 text-xl font-medium text-center text-gray-600">
          Welcome to MySchoolNaukri
        </h3>

       

        <div class="w-full max-w-md mx-auto mt-6">
            <form onSubmit={handleSubmit} >
            <div className="mt-4">
            <label className="block mb-2 text-sm text-gray-600">Password</label>
            <input
              className={`block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border ${
                formErrors.password ? 'border-red-600' : 'border-gray-200'
              } rounded-lg focus:border-[#11a9b5] focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-[#11a9b5]`}
              type="password"
              name="password"
              placeholder="Enter new password"
              value={password}
              onChange={handlePasswordChange}
              aria-label="Password"
            />
            {formErrors.password && <div className="text-red-600 text-sm mt-1">{formErrors.password}</div>}
          </div>

          <div className="mt-4">
            <label className="block mb-2 text-sm text-gray-600">Confirm Password</label>
            <input
              className={`block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border ${
                formErrors.confirmPassword ? 'border-red-600' : 'border-gray-200'
              } rounded-lg focus:border-[#11a9b5] focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-[#11a9b5]`}
              type="password"
              name="confirmPassword"
              placeholder="Confirm new password"
            
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              aria-label="Confirm Password"
            />
            {formErrors.confirmPassword && <div className="text-red-600 text-sm mt-1">{formErrors.confirmPassword}</div>}
          </div>
           
                

                <button type='sumbit' class="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#11a9b5] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                    Continue
                </button>

                <div class="mt-6 text-center">
                    <a href="#" class="text-sm text-blue-900 hover:underline :text-blue-400">
                        Don’t have an account yet? Sign up
                    </a>
                </div>

               
            </form>
        </div>
    </div>
</section>
  )
}

export default Confirmpass