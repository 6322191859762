import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChalkboardTeacher, faVideo, faBullseye } from "@fortawesome/free-solid-svg-icons";

function Bannercourses() {
  return (
    <section className="text-gray-600 bg-[#0f3057] shadow-2xl body-font">
      <div className="container px-5 py-12 mb-14 mx-auto">
        <h2 className="title-font text-center mb-10 font-medium sm:text-4xl px-4 text-3xl text-gray-100">
          What you'll get
        </h2>
        
        <div className="flex flex-wrap justify-center -m-4 text-center">
          
          {/* Instructions from Global Experts */}
          <div className="p-4 sm:w-1/4 w-full">
            <div className="flex items-center justify-center">
              <div className="flex flex-col">
              <FontAwesomeIcon icon={faChalkboardTeacher} className="text-4xl text-[#11a9b5] mb-4" />
                <h2 className="title-font font-medium mb-2 sm:text-xl px-4 text-xl text-gray-100">
                  Instructions from Global Experts
                </h2>
                <p className="leading-relaxed sm:text-md text-md px-4 text-justify text-[#11a9b5]">
                  You learn from education stalwarts and leading teaching experts from all over the world.
                </p>
              </div>
            </div>
          </div>
          
          {/* Over 30+ Hours of Videos */}
          <div className="p-4 sm:w-1/4 w-full">
            <div className="flex items-center justify-center">
              <div className="flex flex-col">
              <FontAwesomeIcon icon={faVideo} className="text-4xl text-[#11a9b5] mb-4" />
                <h2 className="title-font font-medium mb-2 sm:text-xl px-4 text-xl text-gray-100">
                  Over 30+ Hours of Videos
                </h2>
                <p className="leading-relaxed sm:text-md text-md px-4 text-justify text-[#11a9b5]">
                  You get access to multiple training videos that are spread out into 7+ courses.
                </p>
              </div>
            </div>
          </div>
          
          {/* Goal-Oriented Teaching */}
          <div className="p-4 sm:w-1/4 w-full">
            <div className="flex items-center justify-center">
              <div className="flex flex-col">
              <FontAwesomeIcon icon={faBullseye} className="text-4xl text-[#11a9b5] mb-4" />
                <h2 className="title-font font-medium mb-2 sm:text-xl px-4 text-xl text-gray-100">
                  Goal-Oriented Teaching
                </h2>
                <p className="leading-relaxed sm:text-md text-md px-4 text-justify text-[#11a9b5]">
                  The training programs are goal-oriented to ensure that you get the results you want.
                </p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
}

export default Bannercourses;
