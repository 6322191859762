import React from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

// Data for each chart
const job_posted_data = [
  { name: 'Used', value: 0 }, // Example: 35 jobs posted
  { name: 'Remaining', value: 50 - 0 },
];

const resume_downloads_data = [
  { name: 'Used', value: 0 }, // Example: 20 resumes downloaded
  { name: 'Remaining', value: 50 - 0 },
];

const email_sent_data = [
  { name: 'Used', value: 0 }, // Example: 45 emails sent
  { name: 'Remaining', value: 50 - 0 },
];

// Colors for the charts
const COLORS = ['#11a9b5', '#0f3057'];

const render_label = ({  value }) => ` ${value}`;

const PieChartExample = () => {
  return (
    <div className="flex flex-wrap justify-center space-y-10 md:space-y-0 md:space-x-20">
      {/* Job Posted Pie Chart */}
      <div className="flex flex-col border-2 shadow-lg items-center">
        <h3 className="text-2xl mt-2  font-semibold text-gray-700 mb-3">Job Posted</h3>
        <PieChart width={250} height={250}>
          <Pie
            data={job_posted_data}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            isAnimationActive={true }
            paddingAngle={5}
            dataKey="value"
            label={render_label}
          >
            {job_posted_data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </div>

      {/* Resume Downloads Pie Chart */}
      <div className="flex flex-col border-2 shadow-lg items-center">
        <h3 className="text-2xl mt-2  font-semibold text-gray-700  mb-3">Resume Downloads</h3>
        <PieChart width={250} height={250}>
          <Pie
            data={resume_downloads_data}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#82ca9d"
            paddingAngle={5}
            dataKey="value"
            label={render_label}
          >
            {resume_downloads_data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </div>

      {/* Email Sent Pie Chart */}
      <div className="flex flex-col border-2 shadow-lg items-center">
        <h3 className="text-2xl mt-2 font-semibold text-gray-700  mb-3">Email Sent</h3>
        <PieChart width={250} height={250}>
          <Pie
            data={email_sent_data}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#ffc658"
            paddingAngle={5}
            dataKey="value"
            label={render_label}
          >
            {email_sent_data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </div>
    </div>
  );
};

export default PieChartExample;
