import React, { useState ,useMemo } from 'react'
import { usePostData } from '../../Utils/Fetchdata'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function SearchCandi() {

    const navigate = useNavigate()

    const { data:candidate=[] , isLoading, error } = usePostData("https://info.myschoolnaukri.com/api/candidates_list", ['candidate']);
    const[searchterm , setsearchterm] = useState("")

    const Searchresults = useMemo(()=>{
        if(!candidate) return [];
        return candidate.filter((job)=> job.name.toLowerCase().includes(searchterm.toLowerCase()))
    },[candidate, searchterm])
 

      // Handle search button click
  const handleSearch = () => {
    if (searchterm.trim()) {
      // Navigate to the search results page with the query as a parameter
    //   navigate(`/candidatedashboard/jobs/search?query=${encodeURIComponent(searchterm)}`);
    }
   
  };

  const scrollToTop = () => {
   
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // smooth scrolling
    });
  };
  return (
    <>
    
      
     
      <div className="hidden gap-3 sm:flex sm:ml-40">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              value={searchterm}
          onChange={(e) => setsearchterm(e.target.value)}
              className="block w-full p-2 pl-10 text-sm text-black border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
              placeholder="Search candidate..."
            />
          </div>
          <button onClick={handleSearch} className="px-4  sm:px-6  py-2 lg:text-sm text-[14px]  font-semibold mb-2 text-gray-100 transition-colors duration-300 transform bg-[#0f3057] rounded cursor-pointer hover:bg-gray-500">
            {" "}
            Search{" "}
          </button>
        </div>
     
      

   

   
    {searchterm.length > 0 &&  (
        <ul className="   inset-x-[50px] absolute z-30 bg-slate-100 border-2 mx-auto lg:max-w-2xl md:max-w-md  w-5/6 shadow-lg rounded-xl">
          {Searchresults.slice(0, 5).map((job) => (
            <li key={job.job_id} onClick={scrollToTop} className="p-2 border border-gray-200 hover:bg-gray-100">
              <Link  to={`/dashboard/candidate/profile/${job.id}`} className="block text-black ">
                {job.name} 
              </Link>
            </li>
          ))}
        </ul>
      )}
      
      </>
  )
}

export default SearchCandi