import * as Yup from 'yup';

export const validationSchema = Yup.object({
  register: Yup.string()
  .oneOf(['school', 'teacher'], 'Please select either School or Teacher')
  .required('This field is required'),
    name: Yup.string()
      .min(4, 'Name must be at least 3 characters')
      .required('Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    subject: Yup.string()
      
      .required('Subject is required'),
    message: Yup.string()
      
      .required('Message is required'),
  });
export const validationSchemahelp = Yup.object({
    name: Yup.string()
      .min(4, 'Name must be at least 3 characters')
      .required('Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    subject: Yup.string()
      
      .required('Subject is required'),
    message: Yup.string()
      
      .required('Message is required'),
  });