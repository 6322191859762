import React from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import { validationSchemahelp } from '../../Schemas/ContactSchema';

function Help() {
    const initialValues = {

        name: "",
        email: "",
        subject: "",
        message: "",
      };
    
      // On form submit
      const handleSubmit = ( values,{ resetForm }) => {
        resetForm();
        alert("Form submitted successfully!");
      };
  return (
    <div>      
         <div class="text-center mb-8">
          <p class="font-bold  text-3xl">
            Contact <span className="text-[#11a9b5] ">Us </span>{" "}
          </p>

          <h1 class="mt-2 text-2xl font-semibold text-gray-800 md:text-3xl :text-white">
            We’d love to hear from you
          </h1>
        </div>
       <div class="p-4 py-6 mx-auto lg:max-w-2xl rounded-lg bg-[#d9f1f3]  md:p-8 shadow-lg">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemahelp}
              onSubmit={handleSubmit}
            >
              {({ isValid, dirty }) => (
                <Form>
                  <div className="mb-4">
                   
                    <label className="block mb-2 text-sm text-gray-600">
                      Name
                    </label>
                    <Field
                      type="text"
                      name="name"
                      placeholder="John"
                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="block mb-2 text-sm text-gray-600">
                      Email Address
                    </label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="johndoe@example.com"
                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="block mb-2 text-sm text-gray-600">
                      Subject
                    </label>
                    <Field
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <ErrorMessage
                      name="subject"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label className="block mb-2 text-sm text-gray-600">
                      Message
                    </label>
                    <Field
                      as="textarea"
                      name="message"
                      placeholder="Message"
                      className="block w-full h-32 px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <button
                    type="submit"
                    className={`w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#0f3057] rounded-lg hover:bg-[#11a9b5] focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50 ${
                      !(dirty && isValid) ? "cursor-not-allowed" : ""
                    }`}
                    disabled={!(dirty && isValid)}
                  >
                    Send Message
                  </button>
                </Form>
              )}
            </Formik>
            <p className="p-4 text-justify">
              By providing your contact details, you have expressly authorised
              MySchoolNaukri (owned by ERJ Technologies) to contact you in
              future through calls/SMS/emails and inform you about our
              products/services.
            </p>
          </div>


    </div>
  )
}

export default Help