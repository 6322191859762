import React from 'react'

function Loading() {
  return (
    <div className="flex  sm:flex-row flex-col justify-evenly">

    
    <div class="w-full max-w-md mx-auto animate-pulse p-9">
    <p class="h-2 bg-gray-300 rounded-lg w-52 "></p>

    <p class="w-48 h-2 mt-6 bg-gray-200 rounded-lg "></p>
    <p class="w-full h-2 mt-4 bg-gray-200 rounded-lg "></p>
    <p class="w-64 h-2 mt-4 bg-gray-200 rounded-lg "></p>
    <p class="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg "></p>
</div>
    <div class="w-full max-w-md mx-auto animate-pulse p-9">
    <p class="h-2 bg-gray-300 rounded-lg w-52 "></p>

    <p class="w-48 h-2 mt-6 bg-gray-200 rounded-lg "></p>
    <p class="w-full h-2 mt-4 bg-gray-200 rounded-lg "></p>
    <p class="w-64 h-2 mt-4 bg-gray-200 rounded-lg "></p>
    <p class="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg "></p>
</div>
</div>
  )
}

export default Loading