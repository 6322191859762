import React, { useState } from 'react';

const DateRangePicker = ({setSelectedgender,setFromdate,setTodate}) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  return (
    <div id="date-range-picker" className="flex gap-1 items-center">
      <div className="relative">
    
        <input
          id="datepicker-range-start"
          name="start"
          type="date"
          onChange={(e) => setFromdate(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5"
          placeholder="Select date start"
        />
      </div>
      <span className="mx-1 text-gray-500">to</span>
      <div className="relative">
 
        <input
          id="datepicker-range-end"
          name="end"
          type="date"
          onChange={(e) => setTodate(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5"
          placeholder="Select date end"
        />
      </div>
      
<form class="max-w-sm  mx-3">
  <select id="countries" onChange={(e) => setSelectedgender(e.target.value)} placeholder='choose gender' class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500">
    <option selected>Choose Gender</option>
    <option value="0">Male</option>
    <option value="1">Female</option>
  </select>
</form>

    </div>
  );
};

export default DateRangePicker;
