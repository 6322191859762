import React, { useEffect, useState } from 'react';
import axios from 'axios';


function Tablemaster({data}) {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(data);

    const handleDelete = async (id) => {
      try {
        // Send DELETE request to your API with the item's id
        await axios.get(`https://info.myschoolnaukri.com/api/master/delete_location?id=${id}`);
  
        // Filter out the deleted item from the data array in state
        const updatedData = filteredData.filter((item) => item.value !== id);
        setFilteredData(updatedData);
  
        // Optionally, show a success message or handle additional logic
        alert(`Item  deleted successfully`);
      } catch (error) {
        // Handle errors
        console.error('Error deleting item:', error);
      }
    };
   
   useEffect(()=>{
   if (searchQuery){
        const filteredData = data.filter((item) => {
          return item.state.toLowerCase().includes(searchQuery.toLowerCase());
        });
        setFilteredData(filteredData);
      };
   },[searchQuery,data])
  return (
    <>
     <div className='flex justify-end -mt-[50px]'>
     <div class="relative mt-1 mb-4">
              <div class="absolute  inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  class="w-4 h-4 text-gray-500 :text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="table-search"
                class="block py-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-200 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
                placeholder="Search for location"
                value={searchQuery}
                onChange={(e)=>setSearchQuery(e.target.value)}
              />
            </div>
     </div>
   
    <div className="overflow-y-auto max-h-96">
    <table class=" mx-auto w-full text-sm text-left rtl:text-right text-gray-500 ">
      <thead class="text-xs text-gray-700 uppercase bg-gray-200 ">
        <tr>
          <th scope="col" class="p-4 ">
            S.No.
          </th>
          <th scope="col" class=" px-6 py-3">
            State name
          </th>
          <th scope="col" class=" px-6 py-3">
            City name
          </th>
          <th scope="col" class=" px-6 py-3">
            Edit
          </th>
          <th scope="col" class="px-6 py-3">
            Delete
          </th>
        </tr>
      </thead>
      <tbody>
        {filteredData.map((label, index) => (
          <tr key={label.value} class="bg-white :bg-gray-800 border-b    hover:bg-gray-200 ">
            <td class="w-4 px-6 py-1">{index + 1}</td>
            <th
              scope="row"
              class="px-6 py-1  border-b font-medium text-gray-900 whitespace-nowrap "
            >
              {label.state} 
            </th>
            <th
              scope="row"
              class="px-6 py-1  border-b font-medium text-gray-900 whitespace-nowrap "
            >
              {label.city}
            </th>
            <th
              scope="row"
              class="px-6 py-1  border-b font-medium text-white whitespace-nowrap "
            >
              <button class="font-medium text-white bg-[#11a9b5] mb-4 px-3 py-1 mt-4 rounded-lg ">
                Edit
              </button>
            </th>
            <th
              scope="row"
              class="px-6 py-1   border-b font-medium text-white whitespace-nowrap "
            >
              <button onClick={() => handleDelete( label.value)} class="font-medium text-white bg-red-500 mb-4 px-3 py-1 mt-4 rounded-lg ">
                delete
              </button>
            </th>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  </>
  )
}

export default Tablemaster