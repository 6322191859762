import React from "react";

const Paginationa = ({ totalPages, setPage,totalCandidates,page }) => {
  // Helper to generate page numbers with ellipsis
  const getPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 4;

    for (let i = 1; i <= totalPages; i++) {
      if (i <= maxVisiblePages || i === totalPages || Math.abs(page - i) < 2) {
        pages.push(i);
      } else if (i < page && !pages.includes("...")) {
       
      } else if (i > page && !pages.includes("...", totalPages)) {
       
      }
    }
    return pages;
  };

  return (
    <nav className="flex items-center justify-between pt-4" aria-label="Table navigation">
    <span className="text-sm font-normal text-gray-500">
      Showing <span className="font-semibold">{(page - 1) * 10 + 1}</span> to{" "}
      <span className="font-semibold">{Math.min(page * 10, totalCandidates)}</span> of{" "}
      <span className="font-semibold">{totalCandidates}</span>
    </span>
    <ul className="inline-flex -space-x-px text-sm h-8">
      <li>
        <button
          onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
          disabled={page === 1}
          className={`px-3 h-8 text-gray-500 bg-white border border-gray-300 rounded-s-lg ${
            page === 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-200"
          }`}
        >
          Previous
        </button>
      </li>

      {getPageNumbers().map((pg, idx) => (
        <li key={idx}>
          {pg >=4 && pg <=5 ? (
            <span className="px-3 h-8 leading-tight text-gray-500">...</span>
          ) : (
            <>
           
            <button
              onClick={() => setPage(pg)}
              className={`px-3 h-8 ${
                page === pg
                  ? "text-blue-600 bg-blue-50"
                  : "text-gray-500 bg-white hover:bg-gray-200"
              } border border-gray-300`}
            >
              {pg}
            </button>
            </>
          )}
        </li>
      ))}

      <li>
        <button
          onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={page === totalPages}
          className={`px-3 h-8 text-gray-500 bg-white border border-gray-300 rounded-e-lg ${
            page === totalPages ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-200"
          }`}
        >
          Next
        </button>
      </li>
    </ul>
  </nav>
  );
};

export default Paginationa;