import React from 'react'
import logo1 from '../assets/flogo1.jpeg'
import { Link } from 'react-router-dom'
function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // smooth scrolling
    });
  };
  return (
    <footer class="text-gray-600 body-font border">
  <div class="lg:w-full px-5 py-20 mx-auto flex md:items-center lg:items-center justify-evenly md:flex-row md:flex-nowrap flex-wrap flex-col">
    <div class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left lg:justify-end">
      <a href="/" class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
     
      <img
              className=" h-6 sm:h-[50px]"
              src={logo1}
              alt="Logo"
              />
         
        <span class="ml-3   font-bold text-xl">My <span className='text-[#11a9b5]'> School</span> Naukri</span>
      </a>
      <p class="mt-2 text-sm font-bold  text-gray-500">Find Schools <span className='text-[#11a9b5]'> Jobs</span>  All Over India</p>
    </div>
    <div class=" flex flex-wrap  md:pl-20 -mb-10 md:mt-0 mt-10 lg:justify-between md:text-left text-center">
    <div class="flex md:justify-between lg:justify-be">
      <div class="lg:w-[65%] md:w-1/2 w-full px-4 ">
        <nav class="list-none text-start  mb-10 lg:space-y-4 space-y-2">
        <li>
            
            <Link to="/Comingsoon" onClick={scrollToTop} class="text-gray-600 hover:text-[#11a9b5] hover:cursor-pointer">Courses</Link>

          </li>
        <li>
            
            <Link to="/about" onClick={scrollToTop} class="text-gray-600 hover:text-[#11a9b5] hover:cursor-pointer">About us</Link>

          </li>
          <li>
            
            <Link to="/Candidate_onboarding1"  onClick={scrollToTop}   class="text-gray-600 hover:text-[#11a9b5] hover:cursor-pointer">Candidate Signup</Link>
          </li>
          <li>
            
            <Link to="/signup" onClick={scrollToTop}   class="text-gray-600 hover:text-[#11a9b5] hover:cursor-pointer">Employer Signup</Link>
          </li>
          <li>
           
            <Link to="/contact"  onClick={scrollToTop}  class="text-gray-600 hover:text-[#11a9b5] hover:cursor-pointer">Contact us</Link>
          </li>
         
        </nav>
      </div>
      <div class="lg:w-[250px]   md:w-1/2  w-full px-4">
        
        <nav class="list-none mb-6  text-start lg:space-y-4 space-y-2">
        <li>
           
           <Link to="/JobSearchPage"  onClick={scrollToTop}  class="text-gray-600 hover:text-[#11a9b5] hover:cursor-pointer">Search Jobs</Link>
         </li>
          <li>
           

          <Link to="/fraud" onClick={scrollToTop}   class="text-gray-600 hover:text-[#11a9b5] hover:cursor-pointer">Fraud alert</Link>


          </li>
          <li>
          <Link to="/terms&conditions" onClick={scrollToTop}   class="text-gray-600   hover:text-[#11a9b5] hover:cursor-pointer">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/privacy"  onClick={scrollToTop}  class="text-gray-600 hover:text-[#11a9b5] hover:cursor-pointer">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/pricing" onClick={scrollToTop}   class="text-gray-600 hover:text-[#11a9b5] hover:cursor-pointer">Pricing Plan</Link>
            
          </li>
        </nav>
      </div>
      </div>
      
      <div class="lg:w-1/2   md:w-[50%] w-full  lg:px-18">
        
        <div className='flex  '>
            <div>

                <span class="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                    </svg>
                </span>
                            </div>

               <div className='flex-col'>

               <span class="pl-2 text-slate-900 ">Registered Address: </span>  <p class="mt-1 mb-2 pl-2 text-slate-400 ">Plot No 40, Anand Nagar, Junwani, Durg, Bhilai, Chhattisgarh 490020, India</p>
               <span class="pl-2 text-slate-900 ">Corporate    Address: </span> <p class="mt-1 pl-2 text-slate-400 ">2nd Floor, A-10, 
Sector-1, Avanti Vihar, 
Raipur, Chhattisgarh</p>
               </div>
            </div>
               

            <div className='flex  mt-4'>
                <div>

                <span class="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                    </svg>
                </span>
                            </div>

                
             
                <p class="mt-2 sm:pl-2 pl-10  text-slate-400"> <span class="pl-2 text-slate-900 "> Contact: </span>  +91 8948940687  </p>
            </div>
      </div>
    </div>
  </div>
  <div class="bg-gray-100">
    <div class="container  py-4 px-5 flex flex-wrap flex-col text-center">
      <p class="text-gray-500 text-sm text-center">Copyright © 2024 All rights reserved | myschoolnaukri Portal is Owned by ERJ Technology (OPC) Private Limited.
       
      </p>
      
    </div>
  </div>
</footer>
  )
}

export default Footer