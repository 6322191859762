import React from 'react'
import { Link } from 'react-router-dom'

export default function ViewallCard() {
  return (
    <div class="lg:flex w-full cursor-pointer shadow-lg border rounded-lg">
    <img class="p-8 rounded-lg" src="https://online.sttar.in/wp-content/uploads/2023/05/developing-critical-thinking-curriulumn-pre-primary.jpg" alt="product image" />

        <div class="flex flex-col justify-between py-0 sm:py-6 lg:mx-6">
            <p class="sm:text-xl mb-2 sm:mb-0 text-lg px-4 sm:px-0 font-semibold text-gray-800 hover:underline :text-white ">
                How to use sticky note for problem solving
            </p>

            <span class="sm:text-sm text-xs text-justify sm:px-0 px-4  text-gray-900 :text-gray-300">Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ab itaque minima voluptatibus sequi asperiores est consequatur pariatur, possimus quasi similique debitis, molestias molestiae?</span>
            <div class="sm:flex mt-2  px-4 sm:px-0 mb-2 sm:mb-4">
          <span class="flex items-center">
          <span class="text-gray-600 mr-2">4.5</span>

          <svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
    </svg>
    <svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
    </svg>
    <svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
    </svg>
    <svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
    </svg>
    <svg class="w-4 h-4 text-gray-300 me-1 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
    </svg>  
            <span class="text-gray-600 ml-3">4 Reviews</span>
          </span>
          <span class="flex sm:ml-3 sm:pl-3 sm:py-2  py-0 sm:border-l-2 border-gray-200 space-x-2s">
            <p class="text-gray-500">
              Created by - naman Mathur
            </p>
            
          </span>
        </div>

            <div className='flex justify-between items-center py-2 px-4 sm:px-0'>

            <span class="text-lg text-[#11a9b5] font-semibold">Price: ₹4000</span>
            <Link to='/courses/134234' class="text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">View Course</Link>

            </div>
                        </div>
    </div>  )
}
