import React, { useState } from "react";
import Select from "react-select";
import VirtualizedMenuList from "../../Utils/VirtualizedMenuList";
import { Designation, Education, Location, Specilization ,customClassNames,customStyles} from "../../dashboard/components/Options";

function JobfilterC({ filters, onChange }) {
  const { options } = Location();
  const { options3 } = Designation();
  const {options2} = Specilization();
  const {options4} = Education();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [designation, setDesignation] = useState(null);
  const [specilization, setspecilization] = useState(null);
  const [location, setLocation] = useState(null);
  const [education, seteducation] = useState(null);
  const [experience, setExperience] = useState(0);
  const [workMode, setWorkMode] = useState(null);
  const workModeOptions = [
    { value: "fulltime", label: "Full-time" },
    { value: "parttime", label: "Part-time" },
    { value: "workfromhome", label: "Work from Home" }
  ];
  const applyFilters = () => {
    // Only pass the selected values (or null) to the parent
    onChange({
      designation: designation ? designation : null,
      location: location ? location.value : null,
      experience:experience?experience:null,
      workMode:workMode?workMode:null,
      education:education?education:null,
      specilization:specilization?specilization:null

    });
  };
  const handleFilterReset = () => {
    // Clear the selected filters
    setDesignation(null);
    setLocation(null);
    setWorkMode(null);
    setExperience(0);
    setspecilization(null);
    seteducation(null)

    // Send the updated state (empty) to the parent
    onChange({
      designation: null,
      location: null,
      experience: 0,
      workMode: null,
      specilization:null,
      education:null
    });
    localStorage.removeItem("filters");
  };

  return (
    <>
   <div onClick={()=>setIsModalOpen(!isModalOpen)} class="w-full mx-8 md:hidden relative bg-slate-200">
        <div class="absolute inset-y-0 end-4 flex  items-center ps-3  pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 :text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input type="search" id="default-search" class="block w-full p-4 ps-6 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500" placeholder="Search Jobs" required />
        
    </div>
    {isModalOpen && (

<div className="fixed  inset-0 z-50 flex justify-center items-center w-full h-full bg-gray-900 bg-opacity-50">
<div className="relative p-4 bg-white rounded-lg shadow  overflow-y-auto max-h-[80vh]"> 
    <div className="max-w-sm bg-gray-100 p-4 rounded-lg">
      <div className="flex justify-end  items-center">

  
    <button 
                  onClick={()=>setIsModalOpen(false)}
                  type="button" 
                  className="text-gray-800 bg-transparent mb-4  hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8    "
                >
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13"/>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                </div>
              
                <div className="space-y-1  ">
      {/* Designation Filter */}
      <Select
        className=" text-gray-800"
        options={options3}
        styles={customStyles}
        
        classNamePrefix="react-select"
        classNames={customClassNames}
        placeholder={"Designation"}
        // value={options3.find(option => option.value === designation)} // Ensure correct display
        // onChange={(option) => setDesignation(option?.value || null)}
        value={designation} // Ensure the entire object is passed here
        onChange={(option) => setDesignation(option)} // Store the entire option object // Handle clearable case
        isClearable
        components={{ MenuList: VirtualizedMenuList }}
      />

      {/* Location Filter */}
      <Select
        id="search-select"
        styles={customStyles}
        
        classNamePrefix="react-select"
        classNames={customClassNames}
        options={options4} // Use dynamic location options
        // value={options.find(option => option.value === location)} // Ensure correct display
        // onChange={(option) => setLocation(option?.value || null)} // Handle clearable case
        value={education} // Ensure the entire object is passed here
        onChange={(option) => seteducation(option)} // Store the entire option object
        placeholder="Search and select education"
        components={{ MenuList: VirtualizedMenuList }}
        isClearable
      />
      <Select
        id="search-select"
        styles={customStyles}
        
        classNamePrefix="react-select"
        classNames={customClassNames}
        options={options} // Use dynamic location options
        // value={options.find(option => option.value === location)} // Ensure correct display
        // onChange={(option) => setLocation(option?.value || null)} // Handle clearable case
        value={location} // Ensure the entire object is passed here
        onChange={(option) => setLocation(option)} // Store the entire option object
        placeholder="Search and select location"
        components={{ MenuList: VirtualizedMenuList }}
        isClearable
      />
      <Select
        id="search-select"
        styles={customStyles}
        
        classNamePrefix="react-select"
        classNames={customClassNames}
        options={options2} // Use dynamic location options
        // value={options.find(option => option.value === location)} // Ensure correct display
        // onChange={(option) => setLocation(option?.value || null)} // Handle clearable case
        value={specilization} // Ensure the entire object is passed here
        onChange={(option) => setspecilization(option)} // Store the entire option object
        placeholder="Search and select specilization"
        components={{ MenuList: VirtualizedMenuList }}
        isClearable
      />
      
      <Select
        className=" text-gray-800"
        styles={customStyles}
        
           classNamePrefix="react-select"
           classNames={customClassNames}
        options={workModeOptions}
        placeholder="Work Mode"
        value={workMode}
        onChange={(option) => setWorkMode(option)}
        isClearable
      />
      <div className="space-y-2 mt-2 border-2 p-2">
        <div className="flex-col justify-between">
          <label className="font-semibold text-gray-700">Experience (Min): {experience} years</label>
          <input
          type="range"
          min="0"
          max="20"
          value={experience}
          onChange={(e) => setExperience(e.target.value)}
          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
        />
        </div>
        </div>

      {/* Apply Filter Button */}
      <button
        onClick={applyFilters} // Apply filters and send data to the parent
        className="w-full mt-4 py-2 px-4 bg-[#11a9b5] text-white font-semibold rounded-md hover:bg-[#0f3057]"
      >
        Apply Filter
      </button>
      <button
        onClick={handleFilterReset}
        className="w-full mt-4 py-2 px-4 bg-gray-500 text-white font-semibold rounded-md hover:bg-gray-700"
      >
        Remove Filter
      </button>
    </div>
    </div>
    </div>
    </div>
    )}
    <div className="space-y-1 hidden sm:block">
      {/* Designation Filter */}
      <Select
        className=" text-gray-800"
        options={options3}
        styles={customStyles}
        
        classNamePrefix="react-select"
        classNames={customClassNames}
        placeholder={"Designation"}
        // value={options3.find(option => option.value === designation)} // Ensure correct display
        // onChange={(option) => setDesignation(option?.value || null)}
        value={designation} // Ensure the entire object is passed here
        onChange={(option) => setDesignation(option)} // Store the entire option object // Handle clearable case
        isClearable
        components={{ MenuList: VirtualizedMenuList }}
      />

      {/* Location Filter */}
      <Select
        id="search-select"
        styles={customStyles}
        
        classNamePrefix="react-select"
        classNames={customClassNames}
        options={options4} // Use dynamic location options
        // value={options.find(option => option.value === location)} // Ensure correct display
        // onChange={(option) => setLocation(option?.value || null)} // Handle clearable case
        value={education} // Ensure the entire object is passed here
        onChange={(option) => seteducation(option)} // Store the entire option object
        placeholder="Search and select education"
        components={{ MenuList: VirtualizedMenuList }}
        isClearable
      />
      <Select
        id="search-select"
        styles={customStyles}
        
        classNamePrefix="react-select"
        classNames={customClassNames}
        options={options} // Use dynamic location options
        // value={options.find(option => option.value === location)} // Ensure correct display
        // onChange={(option) => setLocation(option?.value || null)} // Handle clearable case
        value={location} // Ensure the entire object is passed here
        onChange={(option) => setLocation(option)} // Store the entire option object
        placeholder="Search and select location"
        components={{ MenuList: VirtualizedMenuList }}
        isClearable
      />
      <Select
        id="search-select"
        styles={customStyles}
        
        classNamePrefix="react-select"
        classNames={customClassNames}
        options={options2} // Use dynamic location options
        // value={options.find(option => option.value === location)} // Ensure correct display
        // onChange={(option) => setLocation(option?.value || null)} // Handle clearable case
        value={specilization} // Ensure the entire object is passed here
        onChange={(option) => setspecilization(option)} // Store the entire option object
        placeholder="Search and select specilization"
        components={{ MenuList: VirtualizedMenuList }}
        isClearable
      />
      
      <Select
        className=" text-gray-800"
        styles={customStyles}
        
           classNamePrefix="react-select"
           classNames={customClassNames}
        options={workModeOptions}
        placeholder="Work Mode"
        value={workMode}
        onChange={(option) => setWorkMode(option)}
        isClearable
      />
      <div className="space-y-2 mt-2 border-2 p-2">
        <div className="flex-col justify-between">
          <label className="font-semibold text-gray-700">Experience (Min): {experience} years</label>
          <input
          type="range"
          min="0"
          max="20"
          value={experience}
          onChange={(e) => setExperience(e.target.value)}
          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
        />
        </div>
        </div>

      {/* Apply Filter Button */}
      <button
        onClick={applyFilters} // Apply filters and send data to the parent
        className="w-full mt-4 py-2 px-4 bg-[#11a9b5] text-white font-semibold rounded-md hover:bg-[#0f3057]"
      >
        Apply Filter
      </button>
      <button
        onClick={handleFilterReset}
        className="w-full mt-4 py-2 px-4 bg-gray-500 text-white font-semibold rounded-md hover:bg-gray-700"
      >
        Remove Filter
      </button>
    </div>
    </>
  );
}

export default JobfilterC;
