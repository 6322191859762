import React from 'react'

function Accdel() {
  return (
    <div class="relative py-4 overflow-x-auto shadow-md sm:rounded-xl">
    <h2 className='mb-4 font-bold text-xl px-2'>Account Deletion Requests</h2>
      <div class="pb-4 py-2 px-4 bg-white :bg-gray-900">
          <label for="table-search" class="sr-only">Search</label>
          <div class="relative mt-1">
              <div class="absolute  inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg class="w-4 h-4 text-gray-500 :text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                  </svg>
              </div>
              <input type="text" id="table-search" class="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-200 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500" placeholder="Search for Candidate"/>
          </div>
      </div>
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 :text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-200 :bg-gray-700 :text-gray-400">
              <tr>
                  <th scope="col" class="p-4">
                      S.No.
                  </th>
                  <th scope="col" class="px-6 py-3">
                     User  name
                  </th>
                 
                  <th scope="col" class="px-6 py-3">
                      Email
                  </th>
                  <th scope="col" class="px-6 py-3">
                     Delete Req date
                  </th>
                  <th scope="col" class="px-6 py-3">
                      App ID
                  </th>
                 
                  <th scope="col" class="px-6 py-3">
                     Approve Request
                  </th>
                 
              </tr>
          </thead>
          <tbody>
          <tr class="bg-white :bg-gray-800 border-b  hover:bg-gray-200 :hover:bg-gray-600">
                  <td class="w-4 p-4">
                     6
                  </td>
                  <th scope="row" class="px-6 py-4 border-b font-medium text-gray-900 whitespace-nowrap :text-white">
                      Apple iMac 27"
                  </th>
                  <td class="px-6 py-4 border-b ">
                      Silver
                  </td>
                  <td class="px-6 py-4 border-b ">
                      PC Desktop
                  </td>
                  <td class="px-6 py-4 border-b ">
                     235435bkhvhv5
                  </td>
                  <td class="px-6 py-4 border-b ">
                  <button  class="font-medium text-white bg-[#11a9b5] px-3 py-1 rounded-lg ">Approve Req </button>
                  </td>
                 
               
              </tr>
              <tr class="bg-white :bg-gray-800 border-b  hover:bg-gray-200 :hover:bg-gray-600">
                  <td class="w-4 p-4">
                     6
                  </td>
                  <th scope="row" class="px-6 py-4 border-b font-medium text-gray-900 whitespace-nowrap :text-white">
                      Apple iMac 27"
                  </th>
                  <td class="px-6 py-4 border-b ">
                      Silver
                  </td>
                  <td class="px-6 py-4 border-b ">
                      PC Desktop
                  </td>
                  <td class="px-6 py-4 border-b ">
                     235435bkhvhv5
                  </td>
                  <td class="px-6 py-4 border-b ">
                  <button  class="font-medium text-white bg-[#11a9b5] px-3 py-1 rounded-lg ">Approve Req </button>
                  </td>
              </tr> <tr class="bg-white :bg-gray-800 border-b  hover:bg-gray-200 :hover:bg-gray-600">
                  <td class="w-4 p-4">
                     6
                  </td>
                  <th scope="row" class="px-6 py-4 border-b font-medium text-gray-900 whitespace-nowrap :text-white">
                      Apple iMac 27"
                  </th>
                  <td class="px-6 py-4 border-b ">
                      Silver
                  </td>
                  <td class="px-6 py-4 border-b ">
                      PC Desktop
                  </td>
                  <td class="px-6 py-4 border-b ">
                     235435bkhvhv5
                  </td>
                  <td class="px-6 py-4 border-b ">
                  <button  class="font-medium text-white bg-[#11a9b5] px-3 py-1 rounded-lg ">Approve Req </button>
                  </td>
              </tr> <tr class="bg-white :bg-gray-800 border-b  hover:bg-gray-200 :hover:bg-gray-600">
                  <td class="w-4 p-4">
                     6
                  </td>
                  <th scope="row" class="px-6 py-4 border-b font-medium text-gray-900 whitespace-nowrap :text-white">
                      Apple iMac 27"
                  </th>
                  <td class="px-6 py-4 border-b ">
                      Silver
                  </td>
                  <td class="px-6 py-4 border-b ">
                      PC Desktop
                  </td>
                  <td class="px-6 py-4 border-b ">
                     235435bkhvhv5
                  </td>
                  <td class="px-6 py-4 border-b ">
                  <button  class="font-medium text-white bg-[#11a9b5] px-3 py-1 rounded-lg ">Approve Req </button>
                  </td>
              </tr> <tr class="bg-white :bg-gray-800 border-b  hover:bg-gray-200 :hover:bg-gray-600">
                  <td class="w-4 p-4">
                     6
                  </td>
                  <th scope="row" class="px-6 py-4 border-b font-medium text-gray-900 whitespace-nowrap :text-white">
                      Apple iMac 27"
                  </th>
                  <td class="px-6 py-4 border-b ">
                      Silver
                  </td>
                  <td class="px-6 py-4 border-b ">
                      PC Desktop
                  </td>
                  <td class="px-6 py-4 border-b ">
                     235435bkhvhv5
                  </td>
                  <td class="px-6 py-4 border-b ">
                  <button  class="font-medium text-white bg-[#11a9b5] px-3 py-1 rounded-lg ">Approve Req </button>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>
  )
}

export default Accdel