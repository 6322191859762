import React , {useState} from "react";

import logo1 from '../assets/flogo1.jpeg'
import Ytsection from "./Ytsection";
import RecentJob from "./RecentJob";
import Stats from "./Stats";
import Testimonial from "./Testimonial";
import SearchPage from "./Searchbar";
import LoginScholl from "../Utils/LoginScholl";
import Loginmodal from "../Utils/Loginmodal";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import JonsearchBanner from "./JonsearchBanner";

function Home() {
  const [isslogin, setIschoollogin] = useState(false); //for school login modal
  const [istlogin, setteacherlogin] = useState(false); //for teacher login model

  const studentlogin = () => setIschoollogin(true);
  const teacherlogin = () => setteacherlogin(true);
  return (
    <section className="bg-white">
      
      <div class="min-w-screen bg-gradient-to-b from-[#d9f1f3] from-10% to-blue-50 px-6 py-16 mx-auto text-center">
        <div class="max-w-2xl mx-auto">
          <h1 class="text-3xl font-bold text-gray-800  lg:text-5xl">
            Welcome to My<span className="text-[#11a9b5]">School</span>Naukri
          </h1>
          <p class="mt-6 text-lg lg:text-2xl mb-8 text-gray-800 ">
            A path towards Empowering{" "}
            <span className="text-[#11a9b5]">Teachers</span> and Enabling
            Students
          </p>
          <div className="flex flex-row justify-around mb-8   py-12  mt-4 md:mt-0">
            <button onClick={studentlogin} class="px-6 md:px-2 lg:px-8 py-2 mx-2 text-sm md:text-sm lg:text-lg font-medium tracking-wide text-white capitalize transition-colors duration-300  bg-[#0f3057]  rounded-3xl hover:bg-white hover:text-black focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
              School Login
            </button>
            <button onClick={teacherlogin} class="px-6 py-2 md:px-2 lg:px-8  lg:mx-2 font-medium md:text-sm  text-sm lg:text-lg tracking-wide text-white capitalize transition-colors duration-300  bg-[#11a9b5]  rounded-3xl hover:bg-white hover:text-black focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
              Teacher Login
            </button>
          </div>
        </div>
      </div>
      {isslogin && <LoginScholl setIschoollogin={setIschoollogin} logo1={logo1}  />}
      {istlogin && <Loginmodal setteacherlogin={setteacherlogin} logo1={logo1} />}    
      
      <SearchPage/>
      <Ytsection />
      <JonsearchBanner/>
      <RecentJob />
      <Stats />
      <Testimonial />
    
    </section>
  );
}

export default Home;
