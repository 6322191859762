import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Paginationa from "../components/Paginationa";
import { useAuth } from "../../Utils/Context";
import Loading from "../../Utils/Loading";
import Applymodal from "../components/Applymodal";
import Jobshare from "../../candidatedash/components/Jobshare";


function Jobapply() {
    const [page, setPage] = useState(1);
    const {api} = useAuth()
   const[show, setshow] = useState(false)
   const [showTooltip1, setShowTooltip1] = useState(false);

   const close = ()=>{
    setshow(false)
   }
    const fetchjobs = async (page) => {
        const token = localStorage.getItem("token");
        try {
          const response = await api.post(
            `/api/candidate/job_list?page=${page}`,
            {}, // Post body, if required
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
      
          return response.data; // Return the data property from Axios response
        } catch (error) {
          console.error("Failed to fetch jobs:", error);
          throw new Error("Failed to fetch jobs");
        }
      };
      
  // Fetch data with React Query and pass page as a dependency
  const { data, isLoading, isError  , error} = useQuery({queryKey:["jobs", page], queryFn:() => fetchjobs(page), 
    keepPreviousData: true, // Retain previous page data while loading new page
  });
  // Handle loading and error states
  if (isLoading) return <Loading/>;
  if (isError) return <div className='text-center text-2xl text-red-500'>Error: {error.message}</div>;

  const jobs = data?.data || []; // Access the candidates array
  const totaljobs = data?.total || 0;  // Total number of candidates
  const totalPages = Math.ceil(totaljobs / 10); // Assuming 10 items per page

  return (
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg px-4 py-10">
    <h2 className='mb-4 font-bold text-xl px-2'>Job Applied Report   </h2>
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 :text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-200 :bg-gray-700 :text-gray-400">
                <tr>
                    <th scope="col" class="p-4">
                      S.No.
                    </th>
                    <th scope="col" class="px-6 py-3">
                        View
                    </th>
                    <th scope="col" class="px-6 py-3">
                        School Name
                    </th>
                    <th scope="col" class="px-6 py-3">
                       Designation
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Location
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Share Job
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Share Job by School Name
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Applied Job
                    </th>
                </tr>
            </thead>
            <tbody>
            {jobs.map((job, index) => (
             <tr key={job.id} className="bg-white border-b hover:bg-gray-200">
               <td className="p-4">{(page - 1) * 10 + index + 1}</td>
               <td className="px-6 py-1">
                 <button onClick={()=>setshow(true)} className="bg-[#11a9b5] text-white px-3 py-1 rounded-lg">
                 view
                 </button>
               </td>
               <td className="px-6 py-4 font-medium text-gray-900">{job.school_name}</td>
               <td className="px-6 py-4">{job.designation}</td>
               <td className="px-6 py-4">{job.location}</td>
               <td className="px-10  py-1">
                 <Jobshare setShowTooltip1 ={setShowTooltip1}   id={job.id}/>
                 
               </td>
               <td className="px-20 py-1">
                 <Jobshare setShowTooltip1 ={setShowTooltip1} jobTitle={job.school_name}  id={job.id}/>
                
               </td>
               <td className="px-6 py-1">
                 <button className="bg-[#11a9b5] text-white px-3 py-1 rounded-lg">
                 Applied Job
                 </button>
               </td>
              
             </tr>
           ))}
            </tbody>
        </table>
        <Paginationa page={page} totalCandidates={totaljobs} setPage={setPage} totalPages={totalPages}/>
        {show && <Applymodal close={close}/>}
        {/* <Applymodal/> */}
    </div>
  )
}

export default Jobapply