import React, { useEffect, useState } from 'react'
import { Fetchdata } from '../Utils/Fetchdata'
function Stats() {
const[data, setdata] = useState(null);
const[data1, setdata1] = useState(null);
  const getData = async ()=>{
    try{

      const response = await Fetchdata("https://info.myschoolnaukri.com/api/statistics_counter")
      setdata(response.TotalUsers);
      setdata1(response.TotalPostedJobs);
    }catch(err){
      console.log('failed to fetch')
    }
     
  }
  useEffect(()=>{
 getData();
  },[])
     console.log(data)
 
 
  return (
    <section class="text-gray-600 bg-[#0f3057] shadow-2xl body-font">
      {
          data === null ? (<div class="container px-5 py-12 mb-14 mx-auto">
            <div class="flex flex-wrap justify-center -m-4 text-center">
              {/* Skeleton Item 1 */}
              <div class="p-4 sm:w-1/4 w-full">
                <div class="flex items-center justify-center">
                  <div class="sm:w-[80px] sm:h-[90px] h-[90px] w-[50px] bg-gray-300 rounded animate-pulse"></div>
                  <div class="flex flex-col ml-4">
                    <div class="h-6 sm:h-8 bg-gray-300 rounded mb-2 w-20 animate-pulse"></div>
                    <div class="h-4 sm:h-6 bg-gray-200 rounded w-32 animate-pulse"></div>
                  </div>
                </div>
              </div>
              {/* Skeleton Item 2 */}
              <div class="p-4 sm:w-1/4 w-1/2">
                <div class="flex items-center justify-center">
                  <div class="sm:w-[80px] sm:h-[90px] h-[50px] w-[50px] bg-gray-300 rounded animate-pulse"></div>
                  <div class="flex flex-col ml-4">
                    <div class="h-6 sm:h-8 bg-gray-300 rounded mb-2 w-20 animate-pulse"></div>
                    <div class="h-4 sm:h-6 bg-gray-200 rounded w-32 animate-pulse"></div>
                  </div>
                </div>
              </div>
              {/* Skeleton Item 3 */}
              <div class="p-4 sm:w-1/4 w-1/2">
                <div class="flex items-center justify-center">
                  <div class="sm:w-[80px] sm:h-[90px] h-[50px] w-[50px] bg-gray-300 rounded animate-pulse"></div>
                  <div class="flex flex-col ml-4">
                    <div class="h-6 sm:h-8 bg-gray-300 rounded mb-2 w-20 animate-pulse"></div>
                    <div class="h-4 sm:h-6 bg-gray-200 rounded w-32 animate-pulse"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          ) :
      
  <div class="container px-5 py-12 mb-14  mx-auto">
    <div class="flex flex-wrap justify-center -m-4 text-center">
      
      <div class="p-4 sm:w-1/4 w-full">

      <div className="flex items-center justify-center  ">
      
            <svg  class="sm:w-[80px] sm:h-[90px] h-[90px] w-[50px] text-white  fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M399 384.2C376.9 345.8 335.4 320 288 320l-64 0c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"/></svg>
      <div className="flex  flex-col">

        <h2 class="title-font text-start font-medium sm:text-5xl px-4 text-3xl text-gray-100">{data}+</h2>
        <p class="leading-relaxed sm:text-2xl text-md px-4 text-justify  text-[#11a9b5]">Users</p>
      </div>
      </div>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <div className="flex items-center justify-center">

      <svg aria-label="suitcase icon" class="sm:w-[80px] sm:h-[90px] h-[50px] w-[50px] text-white  fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 11H10V13H14V11Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7 5V4C7 2.89545 7.89539 2 9 2H15C16.1046 2 17 2.89545 17 4V5H20C21.6569 5 23 6.34314 23 8V18C23 19.6569 21.6569 21 20 21H4C2.34314 21 1 19.6569 1 18V8C1 6.34314 2.34314 5 4 5H7ZM9 4H15V5H9V4ZM4 7C3.44775 7 3 7.44769 3 8V14H21V8C21 7.44769 20.5522 7 20 7H4ZM3 18V16H21V18C21 18.5523 20.5522 19 20 19H4C3.44775 19 3 18.5523 3 18Z"/>
            </svg>

      
        <div className="flex flex-col">

        <h2 class="title-font font-medium sm:text-5xl px-4  text-3xl text-gray-100">{data1}+</h2>
        <p class="leading-relaxed sm:text-2xl text-md px-4 text-justify text-[#11a9b5]">Jobs</p>
        </div>
      </div>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <div className="flex items-center  justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="sm:w-[80px] sm:h-[90px] h-[50px] w-[50px] text-white  fill-current"><path fill="#ffffff" d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192l42.7 0c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0L21.3 320C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7l42.7 0C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3l-213.3 0zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352l117.3 0C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7l-330.7 0c-14.7 0-26.7-11.9-26.7-26.7z"/></svg>
      <div className="flex flex-col justify-start">

<h2 class="title-font font-medium sm:text-5xl px-4  text-3xl text-gray-100">400+</h2>
<p class="leading-relaxed sm:text-2xl text-md  text-justify px-4 text-[#11a9b5]">Employers</p>
      </div>
</div>
      </div>
    
    </div>
  </div> }
</section>
  )
}

export default Stats