import {
  BrowserRouter as Router,
  Routes,
  useLocation,
  Route,
} from "react-router-dom";

import SingupTeacher from "./components/SingupTeacher";
import Contact from "./components/Contact";
import About from "./pages/AboutUs";
import Footer from "./components/Footer";
import Home from "./components/Home";
import JobDetailsPage from "./components/JobDetails";
import AllJobsPage from "./components/Alljobspage";
import SearchResultsPage from "./components/SearchResults";
import Navbar from "./components/Navbar";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Fraud from "./pages/Fraud";
import OtpLogin from "./pages/Otplogin";
import Payment from "./pages/Payment";
import Forgotpass from "./pages/Forgotpass";
import Confirmpass from "./pages/Confirmpass";

import Layout from "./dashboard/Layout";
import Saved from "./dashboard/pages/Saved";
import Jobposting from "./dashboard/pages/Jobposting";
import Findcandiate from "./dashboard/pages/Findcandiate";
import Account from "./dashboard/pages/Account";
import School from "./dashboard/pages/School";
import Main from "./dashboard/pages/Main";
import ChartsPage from "./dashboard/pages/ChartsPage";
import Help from "./dashboard/pages/Help";

import LayoutC from "./candidatedash/LayoutC";
import SavedC from "./candidatedash/pages/SavedC";
import Applied from "./candidatedash/pages/Applied";
import Candidatejobs from "./candidatedash/pages/Candidatejobs";
import Applypage from "./candidatedash/pages/Applypage";
import Candiprofile from "./dashboard/pages/Candiprofile";
import Deleteaccount from "./pages/Delete_account";

import LayoutAdmin from "./admin/LayoutAdmin";
import Dash from "./admin/pages/Dash";
import Notifi from "./admin/pages/Notifi";
import Master from "./admin/pages/Master";
import Manage from "./admin/pages/Manage";
import Tpayment from "./admin/pages/Tpayment";
import Cancelation from "./admin/pages/Cancelation";
import Appusers from "./admin/pages/Appusers";
import PostedjobApp from "./admin/pages/PostedjobApp";
import Accdel from "./admin/pages/Accdel";
import Subcription from "./admin/pages/Subcription";
import Jobenquiry from "./admin/pages/Jobenquiry";
import Userimport from "./admin/pages/Userimport";
import Gsignup from "./admin/pages/Gsignup";
import Candidatea from "./admin/pages/Candidatea";
import Addemployer from "./admin/pages/Addemployer";
import Employer from "./admin/pages/Employer";
import Postedjob from "./admin/pages/Postedjob";
import Jobapply from "./admin/pages/Jobapply";
import Demo from "./admin/pages/Demo";


import Demovideo from "./candidatedash/pages/Demovideo";
import Emailverify from "./pages/Emailverify";
import Thankyouverify from "./pages/Thankyouverify";
import Resumebuilder from "./candidatedash/pages/Resumebuilder";
import React from "react";
import MyForm from "./components/Myform";
import Protectedroute from "./Utils/Proctectedroute";
import ProfilePage from "./candidatedash/pages/ProfilePage";
import ViewJob from "./dashboard/pages/ViewJob";
import Privacypolicy from "./pages/Privacypolicy";
import DeleteAccount from "./pages/DeleteAccount";
import Courses from "./pages/Courses";
import Coursesdetail from "./pages/Coursesdetail";
import Adminlogin from "./pages/Adminlogin";
import ConfirmOtp from "./pages/ConfirmOtp";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import FreeTrialOverPage from "./pages/Freetrial";
import SubscriptionOverPage from "./pages/Subscriptionover";
import Coursedashboard from "./pages/Coursedashboard";
import Coursewatch from "./pages/Coursewatch";
import Coursescandi from "./candidatedash/pages/Courses";
import ViewallCourses from "./candidatedash/pages/ViewallCourses";
import JobSearchPage from "./pages/Searchpage";
import ComingSoon from "./components/Comingsoon";

function App() {
  const location = useLocation();

  // Define routes where Navbar and Footer are shown
  
  const footerRoutes = [
    "/",
    "/Courses",
    "/contact",
    "/all-jobs",
    "/search",
    "/privacy",
    "/terms&conditions",
    "/fraud",
    "/pricing",
    "/about",
    '/Candidate_onboarding1',
    '/Signup',
    '/job/',
    '/courses',
    '/info/admin',
    '/confirmotp',
    '/otplogin',
    '/JobSearchPage'

     
  ];

  const shouldShowFooter =  footerRoutes.includes(location.pathname) || location.pathname.startsWith("/job/")  || location.pathname.startsWith("/courses/");
  return (
    <div className="antianlised overflow-hidden">
      <Routes>
        <Route exact path="/dashboard" element={
          <Protectedroute allow={['1']}>
 <Layout />
          </Protectedroute>
         }>
          <Route path="saved" element={<Saved />} />
          <Route path="jobposting" element={<Jobposting />} />
          <Route path="jobposting/view/:id" element={<ViewJob />} />
          <Route path="candidate" element={<Findcandiate />} />
          <Route path="profile" element={<Account />} />
          <Route path="school" element={<School />} />
          <Route path="ComingSoon" element={<ComingSoon />} />

          <Route path="main" element={<Main />} />
          <Route path="charts" element={<ChartsPage />} />
          <Route path="contact" element={<Help />} />
          <Route path="trialover" element={<FreeTrialOverPage />} />
          <Route path="subover" element={<SubscriptionOverPage />} />
   
          <Route path="candidate/profile/:id" element={<Candiprofile />} />
        </Route>
      </Routes>
      <Routes>
        <Route exact path="/candidatedashboard" element={
          <Protectedroute allow={['0']}>
<LayoutC />
          </Protectedroute>
          }>
          <Route path="main" element={<Applied />} />
          <Route path="saved" element={<SavedC />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="jobs" element={<Candidatejobs />} />
          <Route path="course/:id" element={<Coursewatch />} />
          <Route path="ComingSoon" element={<ComingSoon />} />

          <Route path="viewall" element={<ViewallCourses />} />
          <Route path="course" element={<Coursescandi />} />
          <Route path="demovideo" element={<Demovideo />} />
          <Route path="Resumebuilder" element={<Resumebuilder />} />
          <Route path="contact" element={<Help />} />
          <Route path="jobs/apply/:id" element={<Applypage />} />
          <Route path="jobs/search" element={<SearchResultsPage />} />
        </Route>
      </Routes>

      <Routes>
        <Route exact path="/admin" element={
          <Protectedroute allow={['2']}>
            <LayoutAdmin />
          </Protectedroute>
          }>
          <Route path="main" element={<Dash />} />
          <Route path="notification" element={<Notifi />} />
          <Route path="master" element={<Master />} />
          <Route path="manage" element={<Manage />} />
          <Route path="totalpayment" element={<Tpayment />} />
          <Route path="cancelation" element={<Cancelation />} />
          <Route path="appusers" element={<Appusers />} />
          <Route path="postedjob" element={<PostedjobApp />} />
          <Route path="acc_del" element={<Accdel />} />
          <Route path="ComingSoon" element={<ComingSoon />} />
          <Route path="Subscription" element={<Subcription />} />
          <Route path="Job_enquiry" element={<Jobenquiry />} />
          <Route path="user_import" element={<Userimport />} />
          <Route path="G_singup" element={<Gsignup />} />
          <Route path="Candidate" element={<Candidatea />} />
          <Route path="add_emp" element={<Addemployer />} />
          <Route path="employer" element={<Employer />} />
          <Route path="posted_job" element={<Postedjob />} />
          <Route path="Jobapply_report" element={<Jobapply />} />
          <Route path="demo" element={<Demo />} />
        </Route>
      </Routes>

      
        <div className="mb-20">
          <Navbar />
        </div>
    

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/Courses" element={<Courses />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="job/:id" element={
        
          <JobDetailsPage />
                   
          } />
        <Route path="/all-jobs" element={<AllJobsPage />} />
        {/* <Route path="/course-dashboard" element={<Coursewatch />} /> */}
        <Route path="/search" element={<SearchResultsPage />} />
        <Route path="/JobSearchPage" element={<JobSearchPage />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/courses/:id" element={<Coursesdetail />} />

        {/* Footer Pages */}
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms&conditions" element={<Terms />} />
        <Route path="/fraud" element={<Fraud />} />
        <Route path="/pricing" element={<Payment />} />

        {/* Navbar Pages */}
        <Route path="/about" element={<About />} />
        <Route path="ComingSoon" element={<ComingSoon />} />


        <Route path="/Signup" element={<MyForm />} />
        <Route path="/Candidate_onboarding1" element={<SingupTeacher />} />
        <Route path="/Candidate_onboarding2" element={<SingupTeacher />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/otplogin" element={<OtpLogin />} />
        <Route path="/confirmotp" element={<ConfirmOtp />} />
        <Route path="/info/admin" element={<Adminlogin />} />

        {/* Password Reset Routes */}
        <Route path="/forgetpass" element={<Forgotpass />} />
        <Route path="/Confirmpass" element={<Confirmpass />} />

        {/* Email Verification */}
        <Route path="/onboarding_thanku" element={<Emailverify />} />
        <Route path="/verify" element={<Thankyouverify />} />

         {/* privacypolicy and delete acc */}
         <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/delete_account" element={<DeleteAccount />} />

        {/* Account Deletion Routes */}
        <Route path="/delete_account" element={<Deleteaccount />} />
        <Route path="/delete_account/delete" element={<Deleteaccount />} />
        
     

      </Routes>

      {shouldShowFooter && <Footer />}
    </div>
  );
}

export default App;

