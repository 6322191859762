import * as Yup from 'yup';

export  const ValidationSchemathree = Yup.object({
    educationtype: Yup.string().required('Please select an option'),
    degree: Yup.object().nullable().required('degree is required'),
    fieldOfStudy: Yup.object().nullable().required('field of study  is required'),
    startyear: Yup.number().required('this feild  is required'),
    endyear: Yup.number().required('this feild  is required'),
  
  });

  export const ValidationSchemaTwo =  Yup.object({
    employmentStatus: Yup.string().required('Please select an option'),
    jobProfileTitle: Yup.object().nullable().required('Job profile title is required'),
    schoolName: Yup.string().required('School name is required'),
    locations: Yup.object().nullable().required('Job locations are required'),
    noticePeriod: Yup.number()
      .required('Notice period is required')
      .min(0, 'Notice period cannot be negative')
      .max(12, 'Enter vaild notice period month'),
    totalTeachingExperience: Yup.number()
      .required('Total teaching experience is required')
      .min(0, 'Teaching experience cannot be negative'),
    totalAdministrativeExperience: Yup.number()
      .required('Total administrative experience is required')
      .min(0, 'Administrative experience cannot be negative'),
  });

  export const validationSchemaone = Yup.object({
    firstName: Yup.string().required('First name is required'),
    contactNumber: Yup.string().length(10, 'Contact number must be exactly 10 digits')
      .required('Contact number is required')
      .matches(/^[0-9]+$/, 'Contact number is not valid'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    gender: Yup.string().required('Gender is required'),
    jobLocation: Yup.object().nullable().required('Job location is required'),
    resume: Yup.mixed()
    .required('Resume is required')
    .test('fileFormat', 'Only PDF or Excel files are allowed', (value) => {
      return value && ((value.type === 'application/pdf' || 
        value.type.includes('word') ||
        value.type === 'image/jpeg' || 
        value.type === 'image/jpg'));
    }), 
    password: Yup.string().min(8, "Must be at least 8 characters").required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm password is required'),
  });
// Regex for validating YouTube and Google Drive URLs
// const ytRegex = /(http:|https:)?(\/\/)?(www\.)?(youtube.com|youtu.be)\/(watch|embed)?(\?v=|\/)?(\S+)?/;
// const driveRegex = /^(https?:\/\/)?(drive\.google\.com)\/.+$/;


 export const validationSchemafour = Yup.object().shape({
  file: Yup.mixed()
    .test(
      'file-or-url',
      'You must provide either a file or a URL, not both.',
      function (value) {
        const { url } = this.parent;
        // Check if either file or url is provided, but not both
        return  (!value)  || (value && !url) || (!value && url);
      }
    )
    .nullable(),
  
  url: Yup.string()
    .url('Enter a valid URL')
    .test(
      'url-or-file',
      'You must provide either a file or a URL, not both.',
      function (value) {
        const { file } = this.parent;
        // Check if either file or url is provided, but not both
        return (!value) || (value && !file) || (!value && file);
      }
    )
    .nullable(),
});
  // file: Yup.mixed().when('url', {
  //   is: (url) => !url || url.length === 0, // If URL is not provided
  //   then: ()=> Yup.mixed().required('You must provide either a file or a URL'), // File is required
  //   otherwise: Yup.mixed().nullable(), // If URL is provided, file can be null
  // }),
  // url: Yup.string().url('Enter a valid URL').when('file', {
  //   is: (file) => !file || file.length === 0, // If file is not provided
  //   then: ()=> Yup.string().required('You must provide either a file or a URL'), // URL is required
  //   otherwise: Yup.string().nullable(), // If file is provided, URL can be null
  // }),
// });

 export const validationexp = Yup.object().shape({
    employmentStatus: Yup.string().required('Employment Status is required'),
    jobProfileTitle: Yup.object()
      .nullable()
      .when('employmentStatus', {
        is: 'experienced',
        then:   ()=> Yup.object().required('Job Profile Title is required'),
      }),
    schoolName: Yup.string().when('employmentStatus', {
      is: 'experienced',
      then:  ()=> Yup.string().required('School Name is required'),
    }),
    locations: Yup.object().nullable().when('employmentStatus', {
      is: 'experienced',
      then: ()=> Yup.object().required('Location is required'),
    }),
    noticePeriod: Yup.number()
      .nullable()
      .when('employmentStatus', {
        is: 'experienced',
        then: ()=> Yup.number().required('Notice Period is required').min(0, 'Notice Period cannot be negative'),
      }),
    totalTeachingExperience: Yup.number()
      .nullable()
      .when('employmentStatus', {
        is: 'experienced',
        then: ()=> Yup.number()
          .required('Total Teaching Experience is required')
          .min(0, 'Experience cannot be negative'),
      }),
    totalAdministrativeExperience: Yup.number()
      .nullable()
      .when('employmentStatus', {
        is: 'experienced',
        then: ()=> Yup.number()
          .required('Total Administrative Experience is required')
          .min(0, 'Experience cannot be negative'),
      }),
  });