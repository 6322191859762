import React, { useState } from "react";
import Select from "react-select";
import VirtualizedMenuList from "../../Utils/VirtualizedMenuList";
import { Designation, Education, Location, Specilization ,customClassNames,customStyles} from "../../dashboard/components/Options";

function Savedfilter({ onApplyFilters }) {
    const { options } = Location();
  const { options3 } = Designation();
  const {options2} = Specilization();
  const {options4} = Education();
  const [designation, setDesignation] = useState(null);
  const [specilization, setspecilization] = useState(null);
  const [location, setLocation] = useState(null);
  const [education, seteducation] = useState(null);
  const [experience, setExperience] = useState(0);
  const [workMode, setWorkMode] = useState(null);

  const workModeOptions = [
    { value: "fulltime", label: "Full-time" },
    { value: "parttime", label: "Part-time" },
    { value: "workfromhome", label: "Work from Home" }
  ];
  const handleApply = () => {
    const filters = {
        designation: designation ? designation.value : null,
        location: location ? location.value : null,
        experience:experience? experience:null,
        workMode:workMode?workMode:null,
        education:education?education.value:null,
        specilization:specilization?specilization.value:null
    };
    // Call the parent handler with selected filters
    onApplyFilters(filters);
  };
  return (
    <div className="space-y-1">
    {/* Designation Filter */}
    <Select
      className=" text-gray-800"
      options={options3}
      styles={customStyles}
      
      classNamePrefix="react-select"
      classNames={customClassNames}
      placeholder={"Designation"}
      // value={options3.find(option => option.value === designation)} // Ensure correct display
      // onChange={(option) => setDesignation(option?.value || null)}
      value={designation} // Ensure the entire object is passed here
      onChange={(option) => setDesignation(option)} // Store the entire option object // Handle clearable case
      isClearable
      components={{ MenuList: VirtualizedMenuList }}
    />

    {/* Location Filter */}
    <Select
      id="search-select"
      styles={customStyles}
      
      classNamePrefix="react-select"
      classNames={customClassNames}
      options={options4} // Use dynamic location options
      // value={options.find(option => option.value === location)} // Ensure correct display
      // onChange={(option) => setLocation(option?.value || null)} // Handle clearable case
      value={education} // Ensure the entire object is passed here
      onChange={(option) => seteducation(option)} // Store the entire option object
      placeholder="Search and select education"
      components={{ MenuList: VirtualizedMenuList }}
      isClearable
    />
    <Select
      id="search-select"
      styles={customStyles}
      
      classNamePrefix="react-select"
      classNames={customClassNames}
      options={options} // Use dynamic location options
      // value={options.find(option => option.value === location)} // Ensure correct display
      // onChange={(option) => setLocation(option?.value || null)} // Handle clearable case
      value={location} // Ensure the entire object is passed here
      onChange={(option) => setLocation(option)} // Store the entire option object
      placeholder="Search and select location"
      components={{ MenuList: VirtualizedMenuList }}
      isClearable
    />
    <Select
      id="search-select"
      styles={customStyles}
      
      classNamePrefix="react-select"
      classNames={customClassNames}
      options={options2} // Use dynamic location options
      // value={options.find(option => option.value === location)} // Ensure correct display
      // onChange={(option) => setLocation(option?.value || null)} // Handle clearable case
      value={specilization} // Ensure the entire object is passed here
      onChange={(option) => setspecilization(option)} // Store the entire option object
      placeholder="Search and select specilization"
      components={{ MenuList: VirtualizedMenuList }}
      isClearable
    />
    
    <Select
      className=" text-gray-800"
      styles={customStyles}
      
         classNamePrefix="react-select"
         classNames={customClassNames}
      options={workModeOptions}
      placeholder="Work Mode"
      value={workMode}
      onChange={(option) => setWorkMode(option)}
      isClearable
    />
    <div className="space-y-2 mt-2 border-2 p-2">
      <div className="flex-col justify-between">
        <label className="font-semibold text-gray-700">Experience (Min): {experience} years</label>
        <input
        type="range"
        min="0"
        max="20"
        value={experience}
        onChange={(e) => setExperience(e.target.value)}
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
      />
      </div>
      </div>

    {/* Apply Filter Button */}
    <button
      onClick={handleApply} // Apply filters and send data to the parent
      className="w-full mt-4 py-2 px-4 bg-[#11a9b5] text-white font-semibold rounded-md hover:bg-[#0f3057]"
    >
      Apply Filter
    </button>
   
  </div>
  )
}

export default Savedfilter