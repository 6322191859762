import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/last.png'
import { useAuth } from '../Utils/Context';
function Sidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isactive, setactive] = useState("job");

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);


const {logout} = useAuth()

const loginingout = ()=>{
logout();
  
}


  return (
    <>
      
      <div className="flex pl-[300px] md:pl-6 fixed p-2 mt-3 ms-3  text-sm z-50  lg:hidden">
            <button
               onClick={toggleSidebar}
              className="inline-flex items-center justify-center p-2 rounded-md text-white   "
            >
              <span className="sr-only">Open main menu</span>
              {isSidebarOpen ? (
                <svg className="block h-6 md:ml-[170px]  w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="blockh-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </button>
          </div>

      <aside
        id="sidebar-multi-level-sidebar"
        className={`fixed top-0 left-0  z-40 w-64 h-screen transition-transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0`}
        aria-label="Sidebar"
      >
        
        <div className="h-full px-0 py-[0px] shadow-2xl overflow-y-auto bg-[#0f3057]  ">
        <img
            className="    " 
            src={logo}
            alt="Logo"
            loading="lazy"
          />
          <ul className="space-y-2 px-4 border-t-2 -mt-4 font-medium">
            <li>
              <Link
              onClick={()=>{setactive("dash");setIsSidebarOpen(!isSidebarOpen)}}
                to="main"
                className={`${isactive === "dash"?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}
              >
                <svg className="w-5 h-5 text-[#11a9b5] transition duration-75  group-hover:text-gray-100 :group-hover:text-white" fill='currentcolor' xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 576 512"><path d="M249.6 471.5c10.8 3.8 22.4-4.1 22.4-15.5l0-377.4c0-4.2-1.6-8.4-5-11C247.4 52 202.4 32 144 32C93.5 32 46.3 45.3 18.1 56.1C6.8 60.5 0 71.7 0 83.8L0 454.1c0 11.9 12.8 20.2 24.1 16.5C55.6 460.1 105.5 448 144 448c33.9 0 79 14 105.6 23.5zm76.8 0C353 462 398.1 448 432 448c38.5 0 88.4 12.1 119.9 22.6c11.3 3.8 24.1-4.6 24.1-16.5l0-370.3c0-12.1-6.8-23.3-18.1-27.6C529.7 45.3 482.5 32 432 32c-58.4 0-103.4 20-123 35.6c-3.3 2.6-5 6.8-5 11L304 456c0 11.4 11.7 19.3 22.4 15.5z"/></svg>
                <span className="ms-3">Applied Jobs</span>
                {/* <span className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full :bg-gray-700 :text-gray-300">4</span> */}
              </Link>
            </li>
            
             <li>
                <Link  onClick={()=>{setactive("job");setIsSidebarOpen(!isSidebarOpen)}}  to="jobs"  className={`${isactive === "job" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                <svg  className="w-5 h-5 text-[#11a9b5] transition duration-75  group-hover:text-gray-100 :group-hover:text-white" fill='currentcolor' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Search Jobs</span>
                 
                </Link>
              </li>
              <li>
                <Link onClick={()=>{setactive("save");setIsSidebarOpen(!isSidebarOpen)}}  to="saved"  className={`${isactive === "save"?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                <svg className="w-5 h-5 text-[#11a9b5] transition duration-75  group-hover:text-gray-100 :group-hover:text-white" fill='currentcolor' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"/></svg>
                  <span   className="flex-1 ml-3 whitespace-nowrap">Saved Jobs</span>
                  {/* <span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full :bg-blue-900 :text-blue-300">3</span> */}
                </Link>
              </li>
              <li>
                <Link onClick={()=>{setactive("charts");setIsSidebarOpen(!isSidebarOpen)}}  to="ComingSoon" className={`${isactive === "charts" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                <svg className="w-5 h-5 text-[#11a9b5] transition duration-75  group-hover:text-gray-100 :group-hover:text-white" fill='currentcolor' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 0C28.7 0 0 28.7 0 64L0 352c0 35.3 28.7 64 64 64l96 0 0 80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416 448 416c35.3 0 64-28.7 64-64l0-288c0-35.3-28.7-64-64-64L64 0z"/></svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Courses</span>
                  </Link>
              </li>
             
             
           
              <li>
              <Link onClick={()=>{setactive("profile");setIsSidebarOpen(!isSidebarOpen)}} to="profile"  className={`${isactive === "profile" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
              <svg className="w-5 h-5 text-[#11a9b5] transition duration-75  group-hover:text-gray-100 :group-hover:text-white" fill='currentcolor' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z"/></svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Profile</span>
                 </Link>
              </li>
             
              <li>
                <Link onClick={()=>{setactive("help");setIsSidebarOpen(!isSidebarOpen)}} to="contact"  className={`${isactive === "help" ?'bg-[#11a9b5]  hidden sm:flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'sm:flex hidden items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                  <svg className="flex-shrink-0 w-5 h-5 text-[#11a9b5] transition duration-75 :text-gray-400 group-hover:text-white :group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z"/>
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z"/>
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z"/>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Help Center</span>
                 </Link>
              </li>
              <li onClick={  loginingout}>
                <Link  href="#"  className={`${isactive === "logout" ?'bg-[#11a9b5]  flex items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group':'flex  items-center p-2 mt-3 text-white rounded-lg  hover:bg-[#11a9b5]  group'}`}>
                <svg className="w-5 h-5 text-[#11a9b5] transition duration-75  group-hover:text-gray-100 :group-hover:text-white" fill='currentcolor' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"/></svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Log Out</span>
                 </Link>
              </li>
          </ul>
        </div>
      </aside>
    </>
  );
}

export default Sidebar;
