import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS
import { useAuth } from "../../Utils/Context";
import { Link } from "react-router-dom";
function Applymodal({ onClose, JobId,isapplied }) {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expdata, setexpdata] = useState({});
  const [personaldata, setpersonaldata] = useState({});
  const [resume, setresume] = useState('');

  const { api } = useAuth();
  // console.log(JobId);
  const handleapply = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem("token");
    try {
      const response = await api.post(
        "/api/candidate/apply_job",
        {
          JobId: JobId, // Sending jobId in the request body
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the Bearer token
          },
        }
      );

      if (response.data.success) {
        // Show success message
        toast.success(response.data.message || "Job applied successfully!");
        isapplied(true)
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error("Error saving job:", err);
      setError("Failed to save the job.");

      // Show error message using Toastify
      toast.error("Error: Unable to save the job.");
    } finally {
      setLoading(false);
      onClose();
    }
  };
  useEffect(() => {
    const fetchAllData = async () => {
      const token = localStorage.getItem("token");
      try {
        const [personalResponse, Experience] = await Promise.all([
          api.get("/api/candidate/get_teacher_personal_details", {
            headers: { Authorization: `Bearer ${token}` },
          }),
          api.get("/api/candidate/get_experience_details", {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        setexpdata(Experience?.data[0]);
        setpersonaldata(personalResponse?.data[0]);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  const uploadFileOrVideo = async ({ file_type, uploaded_file, video_url }) => {
    const formData = new FormData();

    // Set the file type: 1 for resume, 0 for video
    formData.append("file_type", file_type);

    // Conditionally append the file or video URL
    if (file_type === 1 && uploaded_file) {
      // Resume upload
      formData.append("uploaded_file", uploaded_file);
    } else if (file_type === 0 && video_url) {
      // Video upload
      formData.append("video_url", video_url);
    } else {
      toast.error("Invalid. Please provide the correct file or video URL.");
      return;
    }

    try {
      setLoading(true);
      const response = await api.post(
        "/api/candidate/save_resume_video",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        toast.success("Upload successful!");
      } else {
        throw new Error(response.data?.msg || "Upload failed.");
      }

      console.log("Upload response:", response.data);
    } catch (error) {
      console.error("Error uploading file/video:", error);
      toast.error("Failed to upload. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  // Function to move to the next step
  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  // Function to move to the previous step
  const prevStep = () => {
    setStep((prev) => prev - 1);
  };
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Job Application</h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            ✖️
          </button>
        </div>
        <div className="flex justify-between items-center space-x-4 mb-6">
          <span
            className={`border-2 px-2 py-0 ${
              step >= 1 ? "bg-[#10aab4] text-white" : "bg-gray-300"
            } rounded-full`}
          >
            1
          </span>
          <div
            className={`w-1/3 h-2 ${
              step >= 1 ? "bg-[#0f3057]" : "bg-gray-300"
            } rounded-lg`}
          ></div>
          <span
            className={`border-2 px-2 py-0 ${
              step >= 2 ? "bg-[#10aab4] text-white" : "bg-gray-300"
            } rounded-full`}
          >
            2
          </span>
          <div
            className={`w-1/3 h-2 ${
              step >= 2 ? "bg-[#0f3057]" : "bg-gray-300"
            } rounded-lg`}
          ></div>
          <span
            className={`border-2 px-2 py-0 ${
              step >= 3 ? "bg-[#10aab4] text-white" : "bg-gray-300"
            } rounded-full`}
          >
            3
          </span>
        </div>
        {/* Step 1: Designation Details */}
        {step === 1 && (
          <div>
            <h3 className="text-xl font-bold  text-[#10aab4] mb-4">
              {" "}
              Designation Details
            </h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Current Designation
                </label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  placeholder={expdata ? "Loading..." : "Current Designation"}
                  value={expdata?.name}
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Last Designation
                </label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  placeholder={"Last Designation"}
                  value={expdata?.previous_designation}
                />
              </div>

              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Current Organization
                </label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  placeholder={expdata ? "Loading..." : "Last Organization"}
                  value={expdata?.current_org}
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Last Organization
                </label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  placeholder={
                    expdata?.previous_org_name
                      ? "Loading..."
                      : "last Organization"
                  }
                  value={expdata?.previous_org_name}
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Notice Period
                </label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  placeholder={expdata ? "Loading..." : "Notice Period"}
                  value={expdata?.notice_period}
                />
              </div>
            </div>
            <div className="flex justify-between">
              <Link to="/candidatedashboard/profile">
                <button className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600">
                  Edit
                </button>
              </Link>
              <button
                className="bg-[#10aab4] text-white px-4 py-2 rounded-md hover:bg-blue-900"
                onClick={nextStep}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {/* Step 2: Resume Review */}
        {step === 2 && (
          <div>
            <h3 className="text-xl font-bold text-[#10aab4] mb-4">
              {" "}
              Resume Review
            </h3>
            <p className="mb-4">Please review your resume below:</p>
            <div className="border p-4 mb-6">
              <p>Resume Name: {resume?resume:personaldata.resume?.slice(7)}</p>
            </div>

            <label
              for="image"
              class="mt-2 mb-4 text-[#0f3057] font-semibold hover:underline"
            >
              {" "}
              Upload updated resume
            </label>

            <input
              type="file"
              class="block w-full px-3 mb-4 py-2 mt-2 text-sm text-gray-600 bg-white border border-gray-200 rounded-lg file:bg-gray-200 file:text-gray-700 file:text-sm file:px-4 file:py-1 file:border-none file:rounded-full  placeholder-gray-400/70  "
              onChange={(e) =>
                {
                  const file = e.target.files[0];
                  if (file) {
                    // Call the upload function
                    uploadFileOrVideo({
                      file_type: 1,
                      uploaded_file: file,
                      video_url: null,
                    });
                    // Optional: Display the file name
                    setresume(file.name);
                  }
                }
              }
            />

            <div className="flex justify-between">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                onClick={prevStep}
              >
                Back
              </button>
              <button
                className="bg-[#10aab4] text-white px-4 py-2 rounded-md hover:bg-blue-900"
                onClick={nextStep}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {/* Step 3: Demo Video Review */}
        {step === 3 && (
          <div>
            <h3 className="text-xl font-bold text-[#10aab4] mb-4">
              {" "}
              Apply for this job role here!
            </h3>
            <p className="mb-4">Please review your demo video below:</p>
            <div className="border p-4 mb-4">
              <p>Video Name: {personaldata.youtube_link}</p>
              <Link to="/candidatedashboard/profile">
                <button
                  className="mt-2 text-blue-500 hover:underline"
                  onClick={() => console.log("Upload Video")}
                >
                  change video
                </button>
              </Link>
            </div>
            <div className="flex justify-end gap-4">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                onClick={prevStep}
              >
                Back
              </button>

              <button
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                onClick={handleapply}
              >
                {loading ? (
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="w-8 h-8 text-gray-200 animate-spin  fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Apply"
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Applymodal;
