import React from 'react'

function Privacypolicy() {
  return (
    <>
    <div id="titlebar" className="bg-gradient-to-r from-blue-500 to-teal-500 py-8">
  <div className="container mx-auto px-4">
    <div className="text-center">
      <h2 className="text-2xl font-semibold text-white">Privacy Policy</h2>
      <nav id="breadcrumbs" className="mt-4">
        <ul className="flex justify-center space-x-2 text-gray-200">
          <li><a href="/" className="hover:underline">Home</a></li>
          <li className="text-white">Privacy Policy</li>
        </ul>
      </nav>
    </div>
  </div>
</div>

<div className="page-container">
  <div className="container mx-auto px-4 py-8">
    <div className="max-w-4xl mx-auto">
      {/* General Section */}
      <section className="mb-8">
        <h3 className="text-xl font-bold text-blue-900">1. GENERAL</h3>
        <p className="text-blue-900 mt-4">a. We, at ERJ Technologies (“we”, “our”, “us”), are committed to protecting your privacy and managing your personal information responsibly. <br />
          b. This Privacy Policy outlines how we collect, use, share, and protect personal information from users (“you”, “your”) of our platform, MySchoolNaukri, including our website and mobile application.<br />
          c. By using our platform, you agree to the collection and use of your personal information as described in this policy.
        </p>
      </section>

      {/* Information Collected Section */}
      <section className="mb-8">
        <h3 className="text-xl font-bold text-blue-900">2. Information Collected</h3>
        <p className="text-blue-900 mt-4">
          a. Personal Information: This includes your name, email address, password, city, contact number, and organization.<br />
          b. Candidate Information: Details such as your name, photograph, birth date, gender, contact information, work experience, qualifications, and salary details.<br />
          {/* Other points */}
        </p>
      </section>

      {/* Additional Sections */}
      <section className="mb-8">
        <h3 className="text-xl font-bold text-blue-900">3. INFORMATION WE COLLECT</h3>
        <p className="text-blue-900 mt-4">a. When you sign up and create an account.<br />
          b. When candidates create profiles or institutions interact with candidates.<br />
          {/* Other points */}
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-xl font-bold text-blue-900">4. Use of Personal Information</h3>
        <p className="text-blue-900 mt-4">1. Service Delivery: To provide and improve our services, including job notifications, search results, and customer support.<br />
          2. Institution Services: To help institutions screen candidates and perform background checks.<br />
          {/* Other points */}
        </p>
      </section>

      {/* More sections can be added similarly with title and content */}

      {/* Contact Section */}
      <section className="mb-8">
        <h3 className="text-xl font-bold text-blue-900">Contact Details:</h3>
        <p className="text-blue-900 mt-4">
          Mr. Gurdit Singh Chhabda <br />
          +91 - 8948940687 <br />
          <a href="mailto:gurdit@myschoolnaukri.com" className="text-blue-600 hover:underline">gurdit@myschoolnaukri.com</a>
        </p>
      </section>
    </div>
  </div>
</div>

    </>
  )
}

export default Privacypolicy