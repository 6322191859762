import React, { useState ,useEffect } from 'react';
import logo from '../assets/flogo1.jpeg'
import {  Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../Utils/Context';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function ConfirmOtp() {
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const initialTime = 120; // 2-minute timer (120 seconds)
    const { loginotp } = useAuth();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [loading, setloading] = useState(false);
    const navigate = useNavigate()
    const [timer, setTimer] = useState(() => {
      const savedTime = localStorage.getItem("timer");
      const savedTimestamp = localStorage.getItem("timerTimestamp");
  
      if (savedTime && savedTimestamp) {
        const elapsedTime = Math.floor((Date.now() - savedTimestamp) / 1000);
        const remainingTime = parseInt(savedTime, 10) - elapsedTime;
        return remainingTime > 0 ? remainingTime : 0;
      }
      return initialTime;
    });
    
 
  
   
  
    const [isResendAvailable, setIsResendAvailable] = useState(false);
  
    useEffect(() => {
      localStorage.setItem("timer", timer);
      localStorage.setItem("timerTimestamp", Date.now());
  
      if (timer > 0) {
        const countdown = setInterval(() => {
          setTimer((prev) => prev - 1);
        }, 1000);
  
        return () => clearInterval(countdown);
      } else {
        setIsResendAvailable(true);
      }
    }, [timer]);
  
  
  
    const handleOtpChange = (e, index) => {
      const value = e.target.value;
      if (value.length === 1 && /^[0-9]$/.test(value)) {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
  
        if (index < 5) {
          document.getElementById(`otp-${index + 1}`).focus();
        }
      }
    };
  
    const handleOtpSubmit = async (e) => {
      e.preventDefault();
      const enteredOtp = otp.join('');
      const mobileNumber = localStorage.getItem('mobileNumber');
      console.log(mobileNumber,enteredOtp)
      const result = await loginotp(mobileNumber, enteredOtp);
      console.log(result)
    const user = localStorage.getItem("userType");
  
    if (result.success && user === '0') {
      setloading(false)
      setSuccessMessage(result.message);
      localStorage.removeItem('timer');
      localStorage.removeItem('timerTimestamp');
      navigate('/candidatedashboard/jobs')
     
    }
    if (result.success && user === '1') {
      setloading(false)
      setSuccessMessage(result.message);
      localStorage.removeItem('timer');
      localStorage.removeItem('timerTimestamp');
      navigate('/dashboard/main')
    }
    else{
      setloading(false)
      toast.error('Invaild Otp')
      localStorage.removeItem('timer');
      localStorage.removeItem('timerTimestamp');
      setErrorMessage("Invalid otp")
    }
      
      // Add logic to verify the OTP with your backend
    };
  
    const handleOtpKeyPress = (e, index) => {
        if (e.key === 'Backspace') {
          const newOtp = [...otp];
          newOtp[index] = ''; // Clear the current field
          setOtp(newOtp);
          
          // Move to the previous input field if not the first input field
          if (index > 0) {
            document.getElementById(`otp-${index - 1}`).focus();
          }
        }
      };
  
    const handleResendOtp = () => {
      setTimer(120); // Reset the timer
      setIsResendAvailable(false); // Disable resend button
      setOtp(new Array(6).fill('')); // Clear the OTP fields
      
      
    };
  
    // Format the timer display (mm:ss)
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
  return (
    <>
    
        <div className="min-h-screen flex flex-col items-center justify-center p-4 bg-gray-100">
        <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-lg ">
        <div className="flex flex-col items-center mb-8">
  
  
  <img
  className=" h-10 mb-4 sm:h-[50px]"
  src={logo}
  alt="Logo"
  />
  <h1 class="text-lg sm:text-2xl font-bold tracking-wider text-gray-800 capitalize ">
  Welcome to <span className='text-[#11a9b5]' >MySchoolNaukri </span> 
  </h1>
  </div>
  {errorMessage && (
           <div class="flex w-full px-4 py-1 max-w-sm border-red-400 overflow-hidden bg-gray-200 mb-2 rounded-lg  ">
           <div class="flex items-center justify-center w-12 bg-gray-300 rounded-full ">
           <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-red-400 fill-current" viewBox="0 0 128 512"><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>
           </div>
       
           <div class="px-4 -mx-3">
               <div class="mx-3">
                   <span class="font-semibold text-red-400">Something went wrong</span>
                   <p class="text-sm text-gray-600 da">{errorMessage}</p>
               </div>
           </div>
       </div>
        )}

        {successMessage && (
          <div class="flex w-full px-4 py-1 max-w-sm overflow-hidden bg-gray-200 mb-2 rounded-lg shadow-md ">
          <div class="flex items-center justify-center w-12 bg-emerald-500">
              <svg class="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z" />
              </svg>
          </div>
      
          <div class="px-4 -mx-3">
              <div class="mx-3">
                  <span class="font-semibold text-emerald-500">Success</span>
                  <p class="text-sm text-gray-600 da">{successMessage}</p>
              </div>
          </div>
      </div>
      
        )}
  <form onSubmit={handleOtpSubmit}>
              <h2 className="text-xl font-bold text-center mb-6">Enter OTP</h2>
              <div className="flex justify-center space-x-2 mb-6">
                {otp.map((value, index) => (
                  <input
                    key={index}
                    id={`otp-${index}`}
                    type="text"
                    maxLength="1"
                    value={value}
                    onChange={(e) => handleOtpChange(e, index)}
                    onKeyDown={(e) => handleOtpKeyPress(e, index)}
                    className="w-12 h-12 border border-gray-300 text-center text-2xl font-semibold rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                ))}
              </div>
              <div className="text-center text-gray-600 mb-4">
                {timer > 0 ? (
                  <p className='text-[#11a9b5]'>Resend OTP in {formatTime(timer)}</p>
                ) : (
                  <p>You can now resend the OTP.</p>
                )}
              </div>
              <div className="flex justify-around">
  
              
              <button
                type="submit"
                className=" py-3 px-3 bg-[#11a9b5] text-white font-semibold rounded-md hover:bg-[#0f3057] transition"
              >
                Verify OTP
              </button>
              <button
                type="button"
                onClick={handleResendOtp}
                disabled={!isResendAvailable}
                className={` px-3 py-3 ${
                  isResendAvailable ? 'bg-green-500 hover:bg-green-600' : 'bg-gray-400'
                } text-white font-semibold rounded-md transition`}
              >
                Resend OTP
              </button>
              </div>
            </form>
          
        </div>
      </div>
 
    </>

  
  )
}

export default ConfirmOtp